import { Component } from "react";
import NavBar from "../NavBar/arbys/NavBar";
import { Col, Label, Row } from "reactstrap";
import colors from "../../config/colors.config";

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import imgArbys_1 from "../../assets/image/variedad/arbys/imgArbys_1.jpg"
import imgArbys_2 from "../../assets/image/variedad/arbys/imgArbys_2.jpg"
import imgArbys_3 from "../../assets/image/variedad/arbys/imgArbys_3.jpg"
import imgArbys_4 from "../../assets/image/variedad/arbys/imgArbys_4.jpg"
import imgArbys_5 from "../../assets/image/variedad/arbys/imgArbys_5.jpg"
import imgArbys_6 from "../../assets/image/variedad/arbys/imgArbys_6.jpg"
import imgArbys_7 from "../../assets/image/variedad/arbys/imgArbys_7.jpg"
import imgArbys_8 from "../../assets/image/variedad/arbys/imgArbys_8.jpg"
import imgArbys_9 from "../../assets/image/variedad/arbys/imgArbys_9.jpg"
import imgArbys_10 from "../../assets/image/variedad/arbys/imgArbys_10.jpg"
import imgArbys_11 from "../../assets/image/variedad/arbys/imgArbys_11.jpg"
import imgArbys_12 from "../../assets/image/variedad/arbys/imgArbys_12.jpg"
import imgArbys_13 from "../../assets/image/variedad/arbys/imgArbys_13.jpg"
import imgArbys_14 from "../../assets/image/variedad/arbys/imgArbys_14.jpg"
import imgArbys_15 from "../../assets/image/variedad/arbys/imgArbys_15.jpg"
import imgArbys_16 from "../../assets/image/variedad/arbys/imgArbys_16.jpg"


import videiArbysPrecentacion from "../../assets/video/videoArbysPrecentacion.mp4";

// import Carrusel from "../carrusel/Carrusel";
import { IconButton } from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";


class Arbys extends Component {

    constructor(props) {
        super(props);
        this.state = {
            device: {
                isDesktopDevice: false,
                isMobileDevice: true,
                screenHeight: 844,
                screenWidth: 390,
            },
            listaImagenes: {
                0: imgArbys_1,
                1: imgArbys_2,
                2: imgArbys_3,
                3: imgArbys_4,
                4: imgArbys_5,
                5: imgArbys_6,
                6: imgArbys_7,
            },
            indexImagen: 0,
            itemData: [
                {
                    img: imgArbys_1,
                    title: '',
                },
                {
                    img: imgArbys_2,
                    title: '',
                },
                {
                    img: imgArbys_3,
                    title: '',
                },
                {
                    img: imgArbys_4,
                    title: '',
                },
                {
                    img: imgArbys_5,
                    title: '',
                },
                {
                    img: imgArbys_6,
                    title: '',
                },
                {
                    img: imgArbys_7,
                    title: '',
                },
                {
                    img: imgArbys_8,
                    title: '',
                },
                {
                    img: imgArbys_9,
                    title: '',
                },
                {
                    img: imgArbys_10,
                    title: '',
                },
                {
                    img: imgArbys_11,
                    title: '',
                },
                {
                    img: imgArbys_12,
                    title: '',
                },
                {
                    img: imgArbys_13,
                    title: '',
                },
                {
                    img: imgArbys_14,
                    title: '',
                },
                {
                    img: imgArbys_15,
                    title: '',
                },
                {
                    img: imgArbys_16,
                    title: '',
                },
            ],
        }
    }

    componentDidMount() {
        this.cargarDatosPantalla();
    }

    cargarDatosPantalla = () => {
        console.log(this.props);
        let { device } = this.props;
        this.setState({
            device
        });
    }



    render() {
        return (
            <>
                <NavBar device={this.props.device} />
                <div>
                    <Row
                        style={{
                            marginRight: this.state.device.isDesktopDevice ? "50px" : "10px",
                            marginLeft: this.state.device.isDesktopDevice ? "50px" : "10px",
                            marginTop: this.state.device.isDesktopDevice ? "80px" : "60px"
                        }}
                    >
                        <Col
                            style={{
                                // maxWidth: "75%"
                            }}
                        >
                            <center>
                                <video
                                    // loop
                                    autoPlay
                                    height={ this.state.device.isDesktopDevice ? "500px" : "200px"}
                                    controls
                                    muted
                                >
                                    <source
                                        src={videiArbysPrecentacion}
                                        type="video/mp4"
                                    />
                                </video>
                            </center>
                        </Col>

                    </Row>


                    <Row
                        style={{
                            marginRight: this.state.device.isDesktopDevice ? "50px" : "10px",
                            marginLeft: this.state.device.isDesktopDevice ? "50px" : "10px",
                            marginTop: "20px"
                        }}
                    >
                        <Col>
                            <Label
                                style={{
                                    color: "#000000",
                                    fontWeight: "bold",
                                    fontSize: "36px"
                                }}
                            >
                                Ven y conoce nuestras unidades de Arby's GPM
                            </Label>
                            <br />
                            <a
                                style={{
                                    color: colors.arbys.rojo,
                                    fontWeight: "bold",
                                    fontSize: "28px",
                                    cursor: "pointer",
                                    textDecorationLine: "underline",
                                }}
                                href="/gpm/arbys/unidades"
                            >
                                Click aquí para ver nuestras unidades
                            </a>
                        </Col>
                    </Row>

                    <Row
                        style={{
                            marginTop: "50px",
                            marginRight: this.state.device.isDesktopDevice ? "50px" : "10px",
                            marginLeft: this.state.device.isDesktopDevice ? "50px" : "10px",
                        }}
                    >
                        <Label
                            style={{
                                color: "#000000",
                                fontWeight: "bold",
                                fontSize: "36px"
                            }}
                        >
                            Nuestra Galería de Fotos
                        </Label>
                        <br />
                        <Label
                            style={{
                                color: colors.arbys.rojo,
                                fontWeight: "bold",
                                fontSize: "28px",
                                cursor: "pointer",
                            }}
                        >
                            Cada momento cuenta
                        </Label>
                    </Row>
                    <Row
                        style={{
                            marginTop: "20px",
                            marginRight: this.state.device.isDesktopDevice ? "50px" : "10px",
                            marginLeft: this.state.device.isDesktopDevice ? "50px" : "10px",
                        }}
                    >
                        <Col>
                            <center>
                                <ImageList
                                    sx={{
                                        width: "100%",
                                        height: 1450
                                    }}
                                    cols={3}
                                    rowHeight={164}
                                    style={{
                                        overscrollBehaviorY: "none"
                                    }}
                                >
                                    {this.state.itemData.map((item) => (
                                        <ImageListItem key={item.img}>
                                            <img
                                                src={item.img}
                                                srcSet={item.img}
                                                alt={item.title}
                                                loading="lazy"
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </center>
                        </Col>
                    </Row>

                    <div style={{ height: "100px" }} ></div>
                </div>
            </>
        );
    }

}

export default Arbys;