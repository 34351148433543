import { Component } from "react";

import { Col, Label, Row } from "reactstrap";
import { AssignmentTurnedInRounded } from "@material-ui/icons";
import colors from "../config/colors.config";
import SNavBarComputer from "./NavBar/GPM/SNavBarComputer";
import Golden_05 from "../assets/video/video.mp4";
import img from "../assets/image/trofeos/LOGRO BIG GOLD FRANNY.jpg";
import goldnuevo from "../assets/image/trofeos/goldnuevo.png";
import removebg from "../assets/image/trofeos/01-removebg-preview.png";
import { Fade, Zoom } from "react-reveal";
import Block from "../tools/Block";
import components from "../tools/components.tool";
import SNavBarMovil from "./NavBar/GPM/SNavBarMovil";


class NuestrosLogros extends Component {

    constructor(props) {
        super(props);
        this.state = {
            device: {
                isDesktopDevice: null,
                isMobileDevice: null,
                screenHeight: null,
                screenWidth: null,
            },
            index: 0,
            abiertoModalMisionVision: true,
            mostrarLeyenda: false,
        }
    }

    componentDidMount() {
        this.cargarDatosPantalla();
    }

    cargarDatosPantalla = () => {
        let { device } = this.props;
        // console.log(device);
        this.setState({
            device
        });
    }

    render() {
        return (
            <>
                {this.state.device.isDesktopDevice ? <SNavBarComputer clickOnHome={null} isMain={false} /> : <SNavBarMovil />}

                {
                    this.state.device.isDesktopDevice ?

                        <>

                            <section
                                style={{
                                    width: "100%",
                                    height: "100vh",
                                }}
                            >
                                <video
                                    style={{
                                        width: "100%",
                                        height: "100vh",
                                        position: "fixed",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                        zIndex: -1,
                                    }}
                                    autoPlay
                                    loop
                                    muted
                                >
                                    <source src={Golden_05} type="video/mp4" />
                                </video>
                                <Block height={"60px"} />
                                <center>
                                    <Zoom>
                                        <img
                                            src={goldnuevo}
                                            alt="No se pudo cargar la imagen"
                                            style={{
                                                width: this.state.device.isDesktopDevice ? "50vh" : "100px",
                                                height: ""
                                            }}
                                            onLoad={() => {
                                                components.sleep(2).then(() => {
                                                    this.setState({
                                                        mostrarLeyenda: true,
                                                    });
                                                });;
                                            }}
                                        />
                                    </Zoom>
                                    {
                                        this.state.mostrarLeyenda ?
                                            <Fade>
                                                <p
                                                    style={{
                                                        fontWeight: "bold",
                                                        fontSize: "34px",
                                                        color: "#EFEDD9",
                                                        fontFamily: "'Roboto Slab', serif",
                                                    }}
                                                >
                                                    GOLD FRANNY
                                                </p>
                                            </Fade>
                                            :
                                            null
                                    }
                                </center>
                            </section>

                            <section
                                style={{
                                    width: "100%",
                                    height: "100vh",
                                }}
                            >
                                <Block height={"15vh"} />
                                <Row
                                    style={{
                                        marginRight: "1px",
                                        marginLeft: "1px",
                                    }}
                                >
                                    <Col
                                        style={{
                                            maxWidth: "40%"
                                        }}
                                    >
                                        <center>
                                            <Fade>
                                                <img
                                                    src={img}
                                                    alt="No se pudo cargar la imagen"
                                                    style={{
                                                        height: "80vh",
                                                        borderRadius: "10px"
                                                    }}
                                                />
                                            </Fade>
                                        </center>
                                    </Col>
                                    <Col
                                        style={{
                                            maxWidth: "60%",
                                            // color: colors.gpm.blanco,
                                        }}
                                    >
                                        <Fade>
                                            <div
                                                style={{
                                                    fontWeight: "bold",
                                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                                    borderRadius: '10px',
                                                    backdropFilter: 'blur(5px)',
                                                    marginRight: "7%",
                                                    fontSize: "20px",
                                                    textAlign: "justify",
                                                    color: "#FFFFFF"
                                                }}
                                            >
                                                <Block height={"20px"} />
                                                <p
                                                    style={{
                                                        marginRight: "20px",
                                                        marginLeft: "20px",
                                                        fontSize: "25px"
                                                    }}
                                                >
                                                    "¡Con gran alegría anunciamos que hemos logrado el 9 Gold Franny!
                                                </p>
                                                <p
                                                    style={{
                                                        marginRight: "20px",
                                                        marginLeft: "20px",
                                                    }}
                                                >
                                                    Ser galardonados con el Gold Franny es un testimonio de nuestro compromiso con el excelente servicio,
                                                    el crecimiento de ventas y unidades, la contribución a nuestros clientes wow y el apoyo mutuo entre los
                                                    franquiciados. Este prestigioso premio se otorga globalmente solo al 2% más destacado de los franquiciados.
                                                </p>
                                                <p
                                                    style={{
                                                        marginRight: "20px",
                                                        marginLeft: "20px",
                                                    }}
                                                >
                                                    Queremos expresar nuestra profunda gratitud a todos ustedes: nuestros seguidores, socios comunitarios,
                                                    nuestros teams y clientes wow. ¡Este logro es gracias a su apoyo continuo y a la dedicación de cada persona
                                                    involucrada en nuestro éxito!.
                                                </p>
                                                <p
                                                    style={{
                                                        marginRight: "20px",
                                                        marginLeft: "20px",
                                                    }}
                                                >
                                                    Seguimos comprometidos en brindar lo mejor y en continuar trabajando juntos para alcanzar nuevos niveles de excelencia.
                                                    ¡Gracias por ser parte de nuestro viaje!", somos GPM
                                                </p>
                                                <Block height={"20px"} />
                                            </div>
                                        </Fade>
                                    </Col>

                                </Row>
                            </section>

                            <section
                                style={{
                                    width: "100%",
                                    height: "100vh",
                                }}
                            >
                                <Block height={"15vh"} />
                                <Row
                                    style={{
                                        marginRight: "1px",
                                        marginLeft: "1px",
                                    }}
                                >
                                    <Col
                                        style={{
                                            maxWidth: "65%"
                                        }}
                                    >

                                        <div
                                            style={{
                                                fontWeight: "bold",
                                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                                borderRadius: '10px',
                                                backdropFilter: 'blur(5px)',
                                                marginLeft: "7%",
                                                fontSize: "20px",
                                                textAlign: "justify",
                                                color: "#FFFFFF"
                                            }}
                                        >
                                            <Block height={"20px"} />
                                            <Fade>
                                                <p
                                                    style={{
                                                        marginRight: "20px",
                                                        marginLeft: "20px",
                                                        fontSize: "20px"
                                                    }}
                                                >
                                                    Víctor Penagos tiene una única motivación: Posicionarse como la franquicia WOW número uno a nivel mundial.
                                                    Grupo Penagos Meneses (GPM) cuenta con 40 unidades distribuidas en México, con ubicaciones en Chiapas,
                                                    Tabasco, Oaxaca, Veracruz, Campeche y Puebla. GPM mantiene un sólido compromiso, respaldado por su dedicado team,
                                                    en mantener los Estándares WOW para brindar una experiencia excepcional a nuestros Clientes WOW.
                                                </p>
                                            </Fade>
                                            <Block height={"20px"} />
                                        </div>
                                    </Col>
                                    <Col
                                        style={{
                                            maxWidth: "30%"
                                        }}
                                    >
                                        <Fade >
                                            <center>
                                                <img
                                                    src={removebg}
                                                    alt="No se pudo cargar la imagen"
                                                    style={{
                                                        height: "85vh",
                                                        borderRadius: "10px"
                                                    }}
                                                />
                                            </center>
                                        </Fade>
                                    </Col>
                                </Row>
                            </section>

                            <div
                                style={{
                                    marginTop: "100px"
                                }}
                            >
                                <Row
                                    style={{
                                        marginRight: this.state.device.isDesktopDevice ? "50px" : "10px",
                                        marginLeft: this.state.device.isDesktopDevice ? "50px" : "10px",
                                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                        borderRadius: "8px"
                                    }}
                                >
                                    <div style={{ marginTop: "50px" }} ></div>
                                    <Col
                                        style={{
                                            maxWidth: "50%",
                                            textAlign: "center",
                                            color: colors.gpm.blanco
                                        }}
                                    >
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "18px",
                                                marginTop: "20px"
                                            }}
                                        >
                                            PREMIO AL LOGRO DISTINGUIDO DE LA ASOCIACIÓN <br /> INTERNACIONAL DE FRANQUICIAS
                                        </Label>
                                        <br />
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "18px"
                                            }}
                                        >
                                            Otorgado a Coalsu S.A. de C.V
                                        </Label>
                                        <br />
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "14px"
                                            }}
                                        >
                                            “Tomar riesgos es la piedra angular de los imperios”
                                        </Label>
                                    </Col>
                                    <Col
                                        style={{
                                            maxWidth: "50%",
                                        }}
                                    >
                                        <center>
                                            <div
                                                style={{
                                                    marginTop: this.state.device.isDesktopDevice ? "0" : "80px",
                                                    backgroundColor: "#F59E2D",
                                                    color: colors.gpm.blanco,
                                                    height: "130px",
                                                    width: "130px",
                                                    borderRadius: "100px"
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        marginTop: "40px",
                                                        fontWeight: "bold",
                                                        fontSize: "24px",
                                                    }}
                                                >
                                                    Año 2000
                                                </label>
                                            </div>
                                        </center>
                                    </Col>
                                    <div style={{ marginTop: "50px" }} ></div>
                                </Row>

                                <Row
                                    style={{
                                        marginRight: this.state.device.isDesktopDevice ? "50px" : "10px",
                                        marginLeft: this.state.device.isDesktopDevice ? "50px" : "10px",
                                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                        borderRadius: "8px",
                                        marginTop: "30px"
                                    }}
                                >
                                    <div style={{ marginTop: "50px" }} ></div>
                                    <Col
                                        style={{
                                            maxWidth: "50%",
                                        }}
                                    >
                                        <center>
                                            <div
                                                style={{
                                                    marginTop: this.state.device.isDesktopDevice ? "0" : "80px",
                                                    backgroundColor: "#F59E2D",
                                                    color: colors.gpm.blanco,
                                                    height: "130px",
                                                    width: "130px",
                                                    borderRadius: "100px"
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        marginTop: "40px",
                                                        fontWeight: "bold",
                                                        fontSize: "24px",
                                                    }}
                                                >
                                                    Año 2004
                                                </label>
                                            </div>
                                        </center>
                                    </Col>
                                    <Col
                                        style={{
                                            maxWidth: "50%",
                                            textAlign: "center",
                                            color: colors.gpm.blanco
                                        }}
                                    >
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "18px",
                                                marginTop: "20px"
                                            }}
                                        >
                                            PREMIO AL LOGRO DISTINGUIDO DE LA ASOCIACIÓN <br /> INTERNACIONAL DE FRANQUICIAS
                                        </Label>
                                        <br />
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "18px"
                                            }}
                                        >
                                            Otorgado a Victor Penagos
                                        </Label>
                                        <br />
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "14px"
                                            }}
                                        >
                                            “Una marca para una empresa es como la reputación para una persona. <br />
                                            Ganas tu reputación tratando de hacer bien las cosas difíciles”
                                        </Label>
                                    </Col>
                                    <div style={{ marginTop: "50px" }} ></div>
                                </Row>

                                <Row
                                    style={{
                                        marginRight: this.state.device.isDesktopDevice ? "50px" : "10px",
                                        marginLeft: this.state.device.isDesktopDevice ? "50px" : "10px",
                                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                        borderRadius: "8px",
                                        marginTop: "30px"
                                    }}
                                >
                                    <div style={{ marginTop: "50px" }} ></div>
                                    <Col
                                        style={{
                                            maxWidth: "50%",
                                            textAlign: "center",
                                            color: colors.gpm.blanco
                                        }}
                                    >
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "18px",
                                                marginTop: "20px"
                                            }}
                                        >
                                            PREMIO AL LOGRO DISTINGUIDO DE LA ASOCIACIÓN <br /> INTERNACIONAL DE FRANQUICIAS
                                        </Label>
                                        <br />
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "18px"
                                            }}
                                        >
                                            Otorgado a Victor Penagos
                                        </Label>
                                        <br />
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "14px"
                                            }}
                                        >
                                            “Una marca para una empresa es como la reputación para una persona. <br />
                                            Ganas tu reputación tratando de hacer bien las cosas difíciles”
                                        </Label>
                                    </Col>
                                    <Col
                                        style={{
                                            maxWidth: "50%",
                                        }}
                                    >
                                        <center>
                                            <div
                                                style={{
                                                    marginTop: this.state.device.isDesktopDevice ? "0" : "80px",
                                                    backgroundColor: "#F59E2D",
                                                    color: colors.gpm.blanco,
                                                    height: "130px",
                                                    width: "130px",
                                                    borderRadius: "100px"
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        marginTop: "40px",
                                                        fontWeight: "bold",
                                                        fontSize: "24px",
                                                    }}
                                                >
                                                    Año 2005
                                                </label>
                                            </div>
                                        </center>
                                    </Col>
                                    <div style={{ marginTop: "50px" }} ></div>
                                </Row>

                                <Row
                                    style={{
                                        marginRight: this.state.device.isDesktopDevice ? "50px" : "10px",
                                        marginLeft: this.state.device.isDesktopDevice ? "50px" : "10px",
                                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                        borderRadius: "8px",
                                        marginTop: "30px"
                                    }}
                                >
                                    <div style={{ marginTop: "50px" }} ></div>
                                    <Col
                                        style={{
                                            maxWidth: "50%",
                                        }}
                                    >
                                        <center>
                                            <div
                                                style={{
                                                    marginTop: this.state.device.isDesktopDevice ? "0" : "80px",
                                                    backgroundColor: "#F59E2D",
                                                    color: colors.gpm.blanco,
                                                    height: "130px",
                                                    width: "130px",
                                                    borderRadius: "100px"
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        marginTop: "40px",
                                                        fontWeight: "bold",
                                                        fontSize: "24px",
                                                    }}
                                                >
                                                    Año 2008
                                                </label>
                                            </div>
                                        </center>
                                    </Col>
                                    <Col
                                        style={{
                                            maxWidth: "50%",
                                            textAlign: "center",
                                            color: colors.gpm.blanco
                                        }}
                                    >
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "18px",
                                                marginTop: "20px"
                                            }}
                                        >
                                            PREMIO AL LOGRO DISTINGUIDO DE LA ASOCIACIÓN <br /> INTERNACIONAL DE FRANQUICIAS
                                        </Label>
                                        <br />
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "18px"
                                            }}
                                        >
                                            Otorgado a Victor Manuel Penagos
                                        </Label>
                                        <br />
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "14px"
                                            }}
                                        >
                                            “Efectivamente, el cambio es casi imposible sin la colaboración, <br />
                                            la cooperación y el consenso de todo el sector”
                                        </Label>
                                    </Col>
                                    <div style={{ marginTop: "50px" }} ></div>
                                </Row>

                                <Row
                                    style={{
                                        marginRight: this.state.device.isDesktopDevice ? "50px" : "10px",
                                        marginLeft: this.state.device.isDesktopDevice ? "50px" : "10px",
                                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                        borderRadius: "8px",
                                        marginTop: "30px"
                                    }}
                                >
                                    <div style={{ marginTop: "50px" }} ></div>
                                    <Col
                                        style={{
                                            maxWidth: "50%",
                                            textAlign: "center",
                                            color: colors.gpm.blanco
                                        }}
                                    >
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "18px",
                                                marginTop: "20px"
                                            }}
                                        >
                                            PREMIO AL LOGRO DISTINGUIDO DE LA ASOCIACIÓN <br /> INTERNACIONAL DE FRANQUICIAS
                                        </Label>
                                        <br />
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "18px"
                                            }}
                                        >
                                            Otorgado a Victor Manuel Penagos
                                        </Label>
                                        <br />
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "14px"
                                            }}
                                        >
                                            “Cada vez que veas un negocio exitoso,<br />
                                            fue porque alguna vez alguien tomó una decisión valiente”
                                        </Label>
                                    </Col>
                                    <Col
                                        style={{
                                            maxWidth: "50%",
                                        }}
                                    >
                                        <center>
                                            <div
                                                style={{
                                                    marginTop: this.state.device.isDesktopDevice ? "0" : "80px",
                                                    backgroundColor: "#F59E2D",
                                                    color: colors.gpm.blanco,
                                                    height: "130px",
                                                    width: "130px",
                                                    borderRadius: "100px"
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        marginTop: "40px",
                                                        fontWeight: "bold",
                                                        fontSize: "24px",
                                                    }}
                                                >
                                                    Año 2011
                                                </label>
                                            </div>
                                        </center>
                                    </Col>
                                    <div style={{ marginTop: "50px" }} ></div>
                                </Row>

                                <Row
                                    style={{
                                        marginRight: this.state.device.isDesktopDevice ? "50px" : "10px",
                                        marginLeft: this.state.device.isDesktopDevice ? "50px" : "10px",
                                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                        borderRadius: "8px",
                                        marginTop: "30px"
                                    }}
                                >
                                    <div style={{ marginTop: "50px" }} ></div>
                                    <Col
                                        style={{
                                            maxWidth: "50%",
                                        }}
                                    >
                                        <center>
                                            <div
                                                style={{
                                                    marginTop: this.state.device.isDesktopDevice ? "0" : "80px",
                                                    backgroundColor: "#F59E2D",
                                                    color: colors.gpm.blanco,
                                                    height: "130px",
                                                    width: "130px",
                                                    borderRadius: "100px"
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        marginTop: "40px",
                                                        fontWeight: "bold",
                                                        fontSize: "24px",
                                                    }}
                                                >
                                                    Año 2013
                                                </label>
                                            </div>
                                        </center>
                                    </Col>
                                    <Col
                                        style={{
                                            maxWidth: "50%",
                                            textAlign: "center",
                                            color: colors.gpm.blanco
                                        }}
                                    >
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "18px",
                                                marginTop: "20px"
                                            }}
                                        >
                                            PREMIO AL LOGRO DISTINGUIDO DE LA ASOCIACIÓN <br /> INTERNACIONAL DE FRANQUICIAS
                                        </Label>
                                        <br />
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "18px"
                                            }}
                                        >
                                            Otorgado a Victor Penagos
                                        </Label>
                                        <br />
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "14px"
                                            }}
                                        >
                                            “Las experiencias nos ayudan a crecer si enfrentamos <br />
                                            la vida con honestidad y valentía”
                                        </Label>
                                    </Col>
                                    <div style={{ marginTop: "50px" }} ></div>
                                </Row>

                                <Row
                                    style={{
                                        marginRight: this.state.device.isDesktopDevice ? "50px" : "10px",
                                        marginLeft: this.state.device.isDesktopDevice ? "50px" : "10px",
                                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                        borderRadius: "8px",
                                        marginTop: "30px"
                                    }}
                                >
                                    <div style={{ marginTop: "50px" }} ></div>
                                    <Col
                                        style={{
                                            maxWidth: "50%",
                                            textAlign: "center",
                                            color: colors.gpm.blanco
                                        }}
                                    >
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "18px",
                                                marginTop: "20px"
                                            }}
                                        >
                                            PREMIO AL LOGRO DISTINGUIDO DE LA ASOCIACIÓN <br /> INTERNACIONAL DE FRANQUICIAS
                                        </Label>
                                        <br />
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "18px"
                                            }}
                                        >
                                            Otorgado a Victor Penagos
                                        </Label>
                                        <br />
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "14px"
                                            }}
                                        >
                                            “La clave no es priorizar lo que está en tu agenda, sino agendar tus prioridades”
                                        </Label>
                                    </Col>
                                    <Col
                                        style={{
                                            maxWidth: "50%",
                                        }}
                                    >
                                        <center>
                                            <div
                                                style={{
                                                    marginTop: this.state.device.isDesktopDevice ? "0" : "80px",
                                                    backgroundColor: "#F59E2D",
                                                    color: colors.gpm.blanco,
                                                    height: "130px",
                                                    width: "130px",
                                                    borderRadius: "100px"
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        marginTop: "40px",
                                                        fontWeight: "bold",
                                                        fontSize: "24px",
                                                    }}
                                                >
                                                    Año 2014
                                                </label>
                                            </div>
                                        </center>
                                    </Col>
                                    <div style={{ marginTop: "50px" }} ></div>
                                </Row>
                                <Row
                                    style={{
                                        marginRight: this.state.device.isDesktopDevice ? "50px" : "10px",
                                        marginLeft: this.state.device.isDesktopDevice ? "50px" : "10px",
                                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                        borderRadius: "8px",
                                        marginTop: "30px"
                                    }}
                                >
                                    <div style={{ marginTop: "50px" }} ></div>
                                    <Col
                                        style={{
                                            maxWidth: "50%",
                                        }}
                                    >
                                        <center>
                                            <div
                                                style={{
                                                    marginTop: this.state.device.isDesktopDevice ? "0" : "80px",
                                                    backgroundColor: "#F59E2D",
                                                    color: colors.gpm.blanco,
                                                    height: "130px",
                                                    width: "130px",
                                                    borderRadius: "100px"
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        marginTop: "40px",
                                                        fontWeight: "bold",
                                                        fontSize: "24px",
                                                    }}
                                                >
                                                    Año 2015
                                                </label>
                                            </div>
                                        </center>
                                    </Col>
                                    <Col
                                        style={{
                                            maxWidth: "50%",
                                            textAlign: "center",
                                            color: colors.gpm.blanco
                                        }}
                                    >
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "18px",
                                                marginTop: "20px"
                                            }}
                                        >
                                            PREMIO AL LOGRO DISTINGUIDO DE LA ASOCIACIÓN <br /> INTERNACIONAL DE FRANQUICIAS
                                        </Label>
                                        <br />
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "18px"
                                            }}
                                        >
                                            Otorgado a Victor Penagos
                                        </Label>
                                        <br />
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "14px"
                                            }}
                                        >
                                            “Las experiencias nos ayudan a crecer si enfrentamos <br />
                                            la vida con honestidad y valentía”
                                        </Label>
                                    </Col>
                                    <div style={{ marginTop: "50px" }} ></div>
                                </Row>
                                <Row
                                    style={{
                                        marginRight: this.state.device.isDesktopDevice ? "50px" : "10px",
                                        marginLeft: this.state.device.isDesktopDevice ? "50px" : "10px",
                                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                        borderRadius: "8px",
                                        marginTop: "30px"
                                    }}
                                >
                                    <div style={{ marginTop: "50px" }} ></div>
                                    <Col
                                        style={{
                                            maxWidth: "50%",
                                            textAlign: "center",
                                            color: colors.gpm.blanco
                                        }}
                                    >
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "18px",
                                                marginTop: "20px"
                                            }}
                                        >
                                            PREMIO AL LOGRO DISTINGUIDO DE LA ASOCIACIÓN <br /> INTERNACIONAL DE FRANQUICIAS
                                        </Label>
                                        <br />
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "18px"
                                            }}
                                        >
                                            Otorgado a Victor Penagos
                                        </Label>
                                        <br />
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "14px"
                                            }}
                                        >
                                            “La clave no es priorizar lo que está en tu agenda, sino agendar tus prioridades”
                                        </Label>
                                    </Col>
                                    <Col
                                        style={{
                                            maxWidth: "50%",
                                        }}
                                    >
                                        <center>
                                            <div
                                                style={{
                                                    marginTop: this.state.device.isDesktopDevice ? "0" : "80px",
                                                    backgroundColor: "#F59E2D",
                                                    color: colors.gpm.blanco,
                                                    height: "130px",
                                                    width: "130px",
                                                    borderRadius: "100px"
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        marginTop: "40px",
                                                        fontWeight: "bold",
                                                        fontSize: "24px",
                                                    }}
                                                >
                                                    Año 2018
                                                </label>
                                            </div>
                                        </center>
                                    </Col>
                                    <div style={{ marginTop: "50px" }} ></div>
                                </Row>
                                <div style={{ marginTop: "100px" }} ></div>
                            </div>

                            <section
                                style={{
                                    width: "100%",
                                    height: "50vh"
                                }}
                            >

                            </section>

                        </>

                        :
                        <>
                            <video
                                style={{
                                    width: "100%",
                                    height: "100vh",
                                    position: "fixed",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    zIndex: -1,
                                }}
                                autoPlay
                                loop
                                muted
                            >
                                <source src={Golden_05} type="video/mp4" />
                            </video>
                            <section
                                style={{
                                    width: "100%",
                                    height: "100vh"
                                }}
                            >
                                <Block height={"50px"} />
                                <center>
                                    <Zoom>
                                        <img
                                            src={goldnuevo}
                                            alt="No se pudo cargar la imagen"
                                            style={{
                                                height: "80vh",
                                                width: "90%"
                                            }}
                                            onLoad={() => {
                                                components.sleep(2).then(() => {
                                                    this.setState({
                                                        mostrarLeyenda: true,
                                                    });
                                                });;
                                            }}
                                        />
                                    </Zoom>
                                    {
                                        this.state.mostrarLeyenda ?
                                            <Fade>
                                                <p
                                                    style={{
                                                        fontWeight: "bold",
                                                        fontSize: "34px",
                                                        color: "#EFEDD9",
                                                        fontFamily: "'Roboto Slab', serif",
                                                    }}
                                                >
                                                    GOLD FRANNY
                                                </p>
                                            </Fade>
                                            :
                                            null
                                    }
                                </center>
                            </section>
                            <section
                                style={{
                                    width: "100%",
                                    height: "100vh"
                                }}
                            >
                                <img
                                    src={img}
                                    alt="No se pudo cargar la imagen"
                                    style={{
                                        width: "100%",
                                        height: "100vh"
                                    }}
                                />
                            </section>
                            <section
                                style={{
                                    width: "100%",
                                    height: "auto"
                                }}
                            >
                                <div
                                    style={{
                                        fontWeight: "bold",
                                        backgroundColor: 'rgba(255, 255, 255, 0)',
                                        borderRadius: '10px',
                                        //backdropFilter: 'blur(5px)',
                                        marginRight: "7%",
                                        fontSize: "20px",
                                        textAlign: "justify",
                                        color: "#FFFFFF",
                                        width: "100%"
                                    }}
                                >
                                    <Block height={"20px"} />
                                    <Fade top >
                                        <p
                                            style={{
                                                marginRight: "20px",
                                                marginLeft: "20px",
                                                fontSize: "25px"
                                            }}
                                        >
                                            "¡Con gran alegría anunciamos que hemos logrado el 9 Gold Franny!
                                        </p>
                                    </Fade>
                                    <Fade top >
                                        <p
                                            style={{
                                                marginRight: "20px",
                                                marginLeft: "20px",
                                            }}
                                        >
                                            Ser galardonados con el Gold Franny es un testimonio de nuestro compromiso con el excelente servicio,
                                            el crecimiento de ventas y unidades, la contribución a nuestros clientes wow y el apoyo mutuo entre los
                                            franquiciados. Este prestigioso premio se otorga globalmente solo al 2% más destacado de los franquiciados.
                                        </p>
                                    </Fade>
                                    <Fade top >
                                        <p
                                            style={{
                                                marginRight: "20px",
                                                marginLeft: "20px",
                                            }}
                                        >
                                            Queremos expresar nuestra profunda gratitud a todos ustedes: nuestros seguidores, socios comunitarios,
                                            nuestros teams y clientes wow. ¡Este logro es gracias a su apoyo continuo y a la dedicación de cada persona
                                            involucrada en nuestro éxito!.
                                        </p>
                                    </Fade>
                                    <Fade top >
                                        <p
                                            style={{
                                                marginRight: "20px",
                                                marginLeft: "20px",
                                            }}
                                        >
                                            Seguimos comprometidos en brindar lo mejor y en continuar trabajando juntos para alcanzar nuevos niveles de excelencia.
                                            ¡Gracias por ser parte de nuestro viaje!", somos GPM
                                        </p>
                                    </Fade>
                                    <Block height={"20px"} />
                                </div>
                            </section>

                            <section
                                style={{
                                    width: "100%",
                                    height: "100vh"
                                }}
                            >
                                <img
                                    src={removebg}
                                    alt="No se pudo cargar la imagen"
                                    style={{
                                        width: "100%",
                                        height: "100vh"
                                    }}
                                />
                            </section>

                            <section
                                style={{
                                    width: "100%",
                                    height: "auto",
                                }}
                            >
                                <div
                                    style={{
                                        width: "100%",
                                        fontWeight: "bold",
                                        borderRadius: '10px',
                                        fontSize: "20px",
                                        textAlign: "justify",
                                        color: "#FFFFFF"
                                    }}
                                >
                                    <Block height={"20px"} />
                                    <Fade>
                                        <p
                                            style={{
                                                marginRight: "20px",
                                                marginLeft: "20px",
                                                fontSize: "20px"
                                            }}
                                        >
                                            Víctor Penagos tiene una única motivación: Posicionarse como la franquicia WOW número uno a nivel mundial.
                                            Grupo Penagos Meneses (GPM) cuenta con 40 unidades distribuidas en México, con ubicaciones en Chiapas,
                                            Tabasco, Oaxaca, Veracruz, Campeche y Puebla. GPM mantiene un sólido compromiso, respaldado por su dedicado team,
                                            en mantener los Estándares WOW para brindar una experiencia excepcional a nuestros Clientes WOW.
                                        </p>
                                    </Fade>
                                    <Block height={"20px"} />
                                </div>
                            </section>

                            <section
                                style={{
                                    width: "100%",
                                    height: "auto",
                                }}
                            >
                                <div
                                    style={{
                                        marginTop: "100px"
                                    }}
                                >
                                    <Row
                                        style={{
                                            marginRight: this.state.device.isDesktopDevice ? "50px" : "10px",
                                            marginLeft: this.state.device.isDesktopDevice ? "50px" : "10px",
                                            backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                            borderRadius: "8px"
                                        }}
                                    >
                                        <div style={{ marginTop: "50px" }} ></div>
                                        <Col
                                            style={{
                                                maxWidth: "50%",
                                                textAlign: "center",
                                                color: colors.gpm.blanco
                                            }}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "18px",
                                                    marginTop: "20px"
                                                }}
                                            >
                                                PREMIO AL LOGRO DISTINGUIDO DE LA ASOCIACIÓN <br /> INTERNACIONAL DE FRANQUICIAS
                                            </Label>
                                            <br />
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "18px"
                                                }}
                                            >
                                                Otorgado a Coalsu S.A. de C.V
                                            </Label>
                                            <br />
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "14px"
                                                }}
                                            >
                                                “Tomar riesgos es la piedra angular de los imperios”
                                            </Label>
                                        </Col>
                                        <Col
                                            style={{
                                                maxWidth: "50%",
                                            }}
                                        >
                                            <center>
                                                <div
                                                    style={{
                                                        marginTop: this.state.device.isDesktopDevice ? "0" : "80px",
                                                        backgroundColor: "#F59E2D",
                                                        color: colors.gpm.blanco,
                                                        height: "130px",
                                                        width: "130px",
                                                        borderRadius: "100px"
                                                    }}
                                                >
                                                    <label
                                                        style={{
                                                            marginTop: "40px",
                                                            fontWeight: "bold",
                                                            fontSize: "24px",
                                                        }}
                                                    >
                                                        Año 2000
                                                    </label>
                                                </div>
                                            </center>
                                        </Col>
                                        <div style={{ marginTop: "50px" }} ></div>
                                    </Row>

                                    <Row
                                        style={{
                                            marginRight: this.state.device.isDesktopDevice ? "50px" : "10px",
                                            marginLeft: this.state.device.isDesktopDevice ? "50px" : "10px",
                                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                            borderRadius: "8px",
                                            marginTop: "30px"
                                        }}
                                    >
                                        <div style={{ marginTop: "50px" }} ></div>
                                        <Col
                                            style={{
                                                maxWidth: "50%",
                                            }}
                                        >
                                            <center>
                                                <div
                                                    style={{
                                                        marginTop: this.state.device.isDesktopDevice ? "0" : "80px",
                                                        backgroundColor: "#F59E2D",
                                                        color: colors.gpm.blanco,
                                                        height: "130px",
                                                        width: "130px",
                                                        borderRadius: "100px"
                                                    }}
                                                >
                                                    <label
                                                        style={{
                                                            marginTop: "40px",
                                                            fontWeight: "bold",
                                                            fontSize: "24px",
                                                        }}
                                                    >
                                                        Año 2004
                                                    </label>
                                                </div>
                                            </center>
                                        </Col>
                                        <Col
                                            style={{
                                                maxWidth: "50%",
                                                textAlign: "center",
                                                color: colors.gpm.blanco
                                            }}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "18px",
                                                    marginTop: "20px"
                                                }}
                                            >
                                                PREMIO AL LOGRO DISTINGUIDO DE LA ASOCIACIÓN <br /> INTERNACIONAL DE FRANQUICIAS
                                            </Label>
                                            <br />
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "18px"
                                                }}
                                            >
                                                Otorgado a Victor Penagos
                                            </Label>
                                            <br />
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "14px"
                                                }}
                                            >
                                                “Una marca para una empresa es como la reputación para una persona. <br />
                                                Ganas tu reputación tratando de hacer bien las cosas difíciles”
                                            </Label>
                                        </Col>
                                        <div style={{ marginTop: "50px" }} ></div>
                                    </Row>

                                    <Row
                                        style={{
                                            marginRight: this.state.device.isDesktopDevice ? "50px" : "10px",
                                            marginLeft: this.state.device.isDesktopDevice ? "50px" : "10px",
                                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                            borderRadius: "8px",
                                            marginTop: "30px"
                                        }}
                                    >
                                        <div style={{ marginTop: "50px" }} ></div>
                                        <Col
                                            style={{
                                                maxWidth: "50%",
                                                textAlign: "center",
                                                color: colors.gpm.blanco
                                            }}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "18px",
                                                    marginTop: "20px"
                                                }}
                                            >
                                                PREMIO AL LOGRO DISTINGUIDO DE LA ASOCIACIÓN <br /> INTERNACIONAL DE FRANQUICIAS
                                            </Label>
                                            <br />
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "18px"
                                                }}
                                            >
                                                Otorgado a Victor Penagos
                                            </Label>
                                            <br />
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "14px"
                                                }}
                                            >
                                                “Una marca para una empresa es como la reputación para una persona. <br />
                                                Ganas tu reputación tratando de hacer bien las cosas difíciles”
                                            </Label>
                                        </Col>
                                        <Col
                                            style={{
                                                maxWidth: "50%",
                                            }}
                                        >
                                            <center>
                                                <div
                                                    style={{
                                                        marginTop: this.state.device.isDesktopDevice ? "0" : "80px",
                                                        backgroundColor: "#F59E2D",
                                                        color: colors.gpm.blanco,
                                                        height: "130px",
                                                        width: "130px",
                                                        borderRadius: "100px"
                                                    }}
                                                >
                                                    <label
                                                        style={{
                                                            marginTop: "40px",
                                                            fontWeight: "bold",
                                                            fontSize: "24px",
                                                        }}
                                                    >
                                                        Año 2005
                                                    </label>
                                                </div>
                                            </center>
                                        </Col>
                                        <div style={{ marginTop: "50px" }} ></div>
                                    </Row>

                                    <Row
                                        style={{
                                            marginRight: this.state.device.isDesktopDevice ? "50px" : "10px",
                                            marginLeft: this.state.device.isDesktopDevice ? "50px" : "10px",
                                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                            borderRadius: "8px",
                                            marginTop: "30px"
                                        }}
                                    >
                                        <div style={{ marginTop: "50px" }} ></div>
                                        <Col
                                            style={{
                                                maxWidth: "50%",
                                            }}
                                        >
                                            <center>
                                                <div
                                                    style={{
                                                        marginTop: this.state.device.isDesktopDevice ? "0" : "80px",
                                                        backgroundColor: "#F59E2D",
                                                        color: colors.gpm.blanco,
                                                        height: "130px",
                                                        width: "130px",
                                                        borderRadius: "100px"
                                                    }}
                                                >
                                                    <label
                                                        style={{
                                                            marginTop: "40px",
                                                            fontWeight: "bold",
                                                            fontSize: "24px",
                                                        }}
                                                    >
                                                        Año 2008
                                                    </label>
                                                </div>
                                            </center>
                                        </Col>
                                        <Col
                                            style={{
                                                maxWidth: "50%",
                                                textAlign: "center",
                                                color: colors.gpm.blanco
                                            }}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "18px",
                                                    marginTop: "20px"
                                                }}
                                            >
                                                PREMIO AL LOGRO DISTINGUIDO DE LA ASOCIACIÓN <br /> INTERNACIONAL DE FRANQUICIAS
                                            </Label>
                                            <br />
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "18px"
                                                }}
                                            >
                                                Otorgado a Victor Manuel Penagos
                                            </Label>
                                            <br />
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "14px"
                                                }}
                                            >
                                                “Efectivamente, el cambio es casi imposible sin la colaboración, <br />
                                                la cooperación y el consenso de todo el sector”
                                            </Label>
                                        </Col>
                                        <div style={{ marginTop: "50px" }} ></div>
                                    </Row>

                                    <Row
                                        style={{
                                            marginRight: this.state.device.isDesktopDevice ? "50px" : "10px",
                                            marginLeft: this.state.device.isDesktopDevice ? "50px" : "10px",
                                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                            borderRadius: "8px",
                                            marginTop: "30px"
                                        }}
                                    >
                                        <div style={{ marginTop: "50px" }} ></div>
                                        <Col
                                            style={{
                                                maxWidth: "50%",
                                                textAlign: "center",
                                                color: colors.gpm.blanco
                                            }}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "18px",
                                                    marginTop: "20px"
                                                }}
                                            >
                                                PREMIO AL LOGRO DISTINGUIDO DE LA ASOCIACIÓN <br /> INTERNACIONAL DE FRANQUICIAS
                                            </Label>
                                            <br />
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "18px"
                                                }}
                                            >
                                                Otorgado a Victor Manuel Penagos
                                            </Label>
                                            <br />
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "14px"
                                                }}
                                            >
                                                “Cada vez que veas un negocio exitoso,<br />
                                                fue porque alguna vez alguien tomó una decisión valiente”
                                            </Label>
                                        </Col>
                                        <Col
                                            style={{
                                                maxWidth: "50%",
                                            }}
                                        >
                                            <center>
                                                <div
                                                    style={{
                                                        marginTop: this.state.device.isDesktopDevice ? "0" : "80px",
                                                        backgroundColor: "#F59E2D",
                                                        color: colors.gpm.blanco,
                                                        height: "130px",
                                                        width: "130px",
                                                        borderRadius: "100px"
                                                    }}
                                                >
                                                    <label
                                                        style={{
                                                            marginTop: "40px",
                                                            fontWeight: "bold",
                                                            fontSize: "24px",
                                                        }}
                                                    >
                                                        Año 2011
                                                    </label>
                                                </div>
                                            </center>
                                        </Col>
                                        <div style={{ marginTop: "50px" }} ></div>
                                    </Row>

                                    <Row
                                        style={{
                                            marginRight: this.state.device.isDesktopDevice ? "50px" : "10px",
                                            marginLeft: this.state.device.isDesktopDevice ? "50px" : "10px",
                                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                            borderRadius: "8px",
                                            marginTop: "30px"
                                        }}
                                    >
                                        <div style={{ marginTop: "50px" }} ></div>
                                        <Col
                                            style={{
                                                maxWidth: "50%",
                                            }}
                                        >
                                            <center>
                                                <div
                                                    style={{
                                                        marginTop: this.state.device.isDesktopDevice ? "0" : "80px",
                                                        backgroundColor: "#F59E2D",
                                                        color: colors.gpm.blanco,
                                                        height: "130px",
                                                        width: "130px",
                                                        borderRadius: "100px"
                                                    }}
                                                >
                                                    <label
                                                        style={{
                                                            marginTop: "40px",
                                                            fontWeight: "bold",
                                                            fontSize: "24px",
                                                        }}
                                                    >
                                                        Año 2013
                                                    </label>
                                                </div>
                                            </center>
                                        </Col>
                                        <Col
                                            style={{
                                                maxWidth: "50%",
                                                textAlign: "center",
                                                color: colors.gpm.blanco
                                            }}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "18px",
                                                    marginTop: "20px"
                                                }}
                                            >
                                                PREMIO AL LOGRO DISTINGUIDO DE LA ASOCIACIÓN <br /> INTERNACIONAL DE FRANQUICIAS
                                            </Label>
                                            <br />
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "18px"
                                                }}
                                            >
                                                Otorgado a Victor Penagos
                                            </Label>
                                            <br />
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "14px"
                                                }}
                                            >
                                                “Las experiencias nos ayudan a crecer si enfrentamos <br />
                                                la vida con honestidad y valentía”
                                            </Label>
                                        </Col>
                                        <div style={{ marginTop: "50px" }} ></div>
                                    </Row>

                                    <Row
                                        style={{
                                            marginRight: this.state.device.isDesktopDevice ? "50px" : "10px",
                                            marginLeft: this.state.device.isDesktopDevice ? "50px" : "10px",
                                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                            borderRadius: "8px",
                                            marginTop: "30px"
                                        }}
                                    >
                                        <div style={{ marginTop: "50px" }} ></div>
                                        <Col
                                            style={{
                                                maxWidth: "50%",
                                                textAlign: "center",
                                                color: colors.gpm.blanco
                                            }}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "18px",
                                                    marginTop: "20px"
                                                }}
                                            >
                                                PREMIO AL LOGRO DISTINGUIDO DE LA ASOCIACIÓN <br /> INTERNACIONAL DE FRANQUICIAS
                                            </Label>
                                            <br />
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "18px"
                                                }}
                                            >
                                                Otorgado a Victor Penagos
                                            </Label>
                                            <br />
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "14px"
                                                }}
                                            >
                                                “La clave no es priorizar lo que está en tu agenda, sino agendar tus prioridades”
                                            </Label>
                                        </Col>
                                        <Col
                                            style={{
                                                maxWidth: "50%",
                                            }}
                                        >
                                            <center>
                                                <div
                                                    style={{
                                                        marginTop: this.state.device.isDesktopDevice ? "0" : "80px",
                                                        backgroundColor: "#F59E2D",
                                                        color: colors.gpm.blanco,
                                                        height: "130px",
                                                        width: "130px",
                                                        borderRadius: "100px"
                                                    }}
                                                >
                                                    <label
                                                        style={{
                                                            marginTop: "40px",
                                                            fontWeight: "bold",
                                                            fontSize: "24px",
                                                        }}
                                                    >
                                                        Año 2014
                                                    </label>
                                                </div>
                                            </center>
                                        </Col>
                                        <div style={{ marginTop: "50px" }} ></div>
                                    </Row>
                                    <Row
                                        style={{
                                            marginRight: this.state.device.isDesktopDevice ? "50px" : "10px",
                                            marginLeft: this.state.device.isDesktopDevice ? "50px" : "10px",
                                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                            borderRadius: "8px",
                                            marginTop: "30px"
                                        }}
                                    >
                                        <div style={{ marginTop: "50px" }} ></div>
                                        <Col
                                            style={{
                                                maxWidth: "50%",
                                            }}
                                        >
                                            <center>
                                                <div
                                                    style={{
                                                        marginTop: this.state.device.isDesktopDevice ? "0" : "80px",
                                                        backgroundColor: "#F59E2D",
                                                        color: colors.gpm.blanco,
                                                        height: "130px",
                                                        width: "130px",
                                                        borderRadius: "100px"
                                                    }}
                                                >
                                                    <label
                                                        style={{
                                                            marginTop: "40px",
                                                            fontWeight: "bold",
                                                            fontSize: "24px",
                                                        }}
                                                    >
                                                        Año 2015
                                                    </label>
                                                </div>
                                            </center>
                                        </Col>
                                        <Col
                                            style={{
                                                maxWidth: "50%",
                                                textAlign: "center",
                                                color: colors.gpm.blanco
                                            }}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "18px",
                                                    marginTop: "20px"
                                                }}
                                            >
                                                PREMIO AL LOGRO DISTINGUIDO DE LA ASOCIACIÓN <br /> INTERNACIONAL DE FRANQUICIAS
                                            </Label>
                                            <br />
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "18px"
                                                }}
                                            >
                                                Otorgado a Victor Penagos
                                            </Label>
                                            <br />
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "14px"
                                                }}
                                            >
                                                “Las experiencias nos ayudan a crecer si enfrentamos <br />
                                                la vida con honestidad y valentía”
                                            </Label>
                                        </Col>
                                        <div style={{ marginTop: "50px" }} ></div>
                                    </Row>
                                    <Row
                                        style={{
                                            marginRight: this.state.device.isDesktopDevice ? "50px" : "10px",
                                            marginLeft: this.state.device.isDesktopDevice ? "50px" : "10px",
                                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                            borderRadius: "8px",
                                            marginTop: "30px"
                                        }}
                                    >
                                        <div style={{ marginTop: "50px" }} ></div>
                                        <Col
                                            style={{
                                                maxWidth: "50%",
                                                textAlign: "center",
                                                color: colors.gpm.blanco
                                            }}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "18px",
                                                    marginTop: "20px"
                                                }}
                                            >
                                                PREMIO AL LOGRO DISTINGUIDO DE LA ASOCIACIÓN <br /> INTERNACIONAL DE FRANQUICIAS
                                            </Label>
                                            <br />
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "18px"
                                                }}
                                            >
                                                Otorgado a Victor Penagos
                                            </Label>
                                            <br />
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "14px"
                                                }}
                                            >
                                                “La clave no es priorizar lo que está en tu agenda, sino agendar tus prioridades”
                                            </Label>
                                        </Col>
                                        <Col
                                            style={{
                                                maxWidth: "50%",
                                            }}
                                        >
                                            <center>
                                                <div
                                                    style={{
                                                        marginTop: this.state.device.isDesktopDevice ? "0" : "80px",
                                                        backgroundColor: "#F59E2D",
                                                        color: colors.gpm.blanco,
                                                        height: "130px",
                                                        width: "130px",
                                                        borderRadius: "100px"
                                                    }}
                                                >
                                                    <label
                                                        style={{
                                                            marginTop: "40px",
                                                            fontWeight: "bold",
                                                            fontSize: "24px",
                                                        }}
                                                    >
                                                        Año 2018
                                                    </label>
                                                </div>
                                            </center>
                                        </Col>
                                        <div style={{ marginTop: "50px" }} ></div>
                                    </Row>
                                    <div style={{ marginTop: "100px" }} ></div>
                                </div>
                            </section>
                        </>
                }
            </>
        );
    }


}

export default NuestrosLogros;