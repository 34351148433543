import { Component } from "react";
import NavBar from "../NavBar/sushiitto/NavBar";
import { Button, Card, CardBody, CardSubtitle, CardText, CardTitle, Col, DropdownItem, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import arbysLogo from "../../assets/image/logo/sushiItto/sushiittoLogo.jpg";

/** Importación de imágenes */
import imgPantalla from "../../assets/image/variedad/sushiitto/imgPantalla.jpg";
import colors from "../../config/colors.config";
import { IconButton } from "@material-ui/core";
import { Close, Map, QueryBuilder, Room, Streetview } from "@material-ui/icons";

class SushiittoUnidades extends Component {

    constructor(props) {

        super(props);
        this.state = {
            orientation: 0,
            device: {
                isDesktopDevice: false,
                isMobileDevice: true,
                screenHeight: 844,
                screenWidth: 390,
            },
            estaAbiertoModalUnidadArbys: false,
            unidadArbys: {
                nombreUnidad: "",
                direccion: "",
                img: "",
                IdStatus: 0,
                status: "",
                estado: "",
                urlGoogleStreetView: "",
                urlGoogleMaps: "---",
            },
            listaUnidadesArbys: [
                {
                    nombreUnidad: "Sushi Itto Tuxtla Gtz",
                    direccion: "Blvd. Belisario Domínguez 1972, Arboledas, 29030 Tuxtla Gutiérrez, Chis.",
                    img: imgPantalla,
                    IdStatus: 2,
                    status: "Abierto al publico",
                    estado: "Puebla",
                    urlGoogleStreetView: "https://www.google.com/local/place/fid/0x85ecd854c67fb653:0xa16b8752856a3c69/photosphere?iu=https://lh5.googleusercontent.com/p/AF1QipMDuld6qCvJ8OXtV4cJPaHxhECujU8D6LSAjcZL%3Dw160-h106-k-no-pi-0-ya78.40668-ro-0-fo100&ik=CAoSLEFGMVFpcE1EdWxkNnFDdko4T1h0VjRjSlBhSHhoRUN1alU4RDZMU0FqY1pM",
                    urlGoogleMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3820.4672935491135!2d-93.13876888513242!3d16.753411188461474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85ecd854c67fb653%3A0xa16b8752856a3c69!2sSushi%20Itto!5e0!3m2!1ses!2smx!4v1687805233565!5m2!1ses!2smx",
                },
                {
                    nombreUnidad: "Sushi Itto Tapachula",
                    direccion: "",
                    img: "",
                    IdStatus: 1,
                    status: "En construcción",
                    estado: "Tapachula",
                    urlGoogleStreetView: "",
                    urlGoogleMaps: "",
                },
            ]
        }
    }

    componentDidMount() {
        this.cargarDatosPantalla();
    }

    cargarDatosPantalla = () => {
        let { device } = this.props;
        this.setState({
            device
        });
        window.addEventListener("orientationchange", async  () => {
            console.log(window.screen.orientation);
            await this.setState({
                orientation: window.screen.orientation.angle
            });
        });
    }

    abrirCerrarModalArbysUnidad = () => {
        this.setState({
            estaAbiertoModalUnidadArbys: !this.state.estaAbiertoModalUnidadArbys
        });
    }

    render() {
        return (
            <>
                <NavBar device={this.props.device} />
                <div>
                    <Row
                        style={{
                            marginTop: "100px",
                            marginRight: this.state.device.isDesktopDevice ? "50px" : "10px",
                            marginLeft: this.state.device.isDesktopDevice ? "50px" : "10px",
                        }}
                    >
                        <Col>
                            <Label
                                style={{
                                    color: colors.sushiitto.morado,
                                    fontWeight: "bold",
                                    fontSize: "24px"
                                }}
                            >
                                Lista de Unidades de Arby's de
                                <Label
                                    style={{
                                        color: colors.arbys.negro,
                                        marginLeft: "7px"
                                    }}
                                >
                                    Grupo Penagos Meneses (GPM)
                                </Label>
                            </Label>
                        </Col>
                        <DropdownItem divider />
                    </Row>

                    <Row
                        style={{
                            marginTop: "30px",
                            marginRight: this.state.device.isDesktopDevice ? "50px" : "10px",
                            marginLeft: this.state.device.isDesktopDevice ? "50px" : "10px",
                            backgroundColor: "#E9E9E9",
                            borderRadius: "8px"
                        }}                        
                         xs={this.state.device.isDesktopDevice ? 4 : 1}
                    >
                        {
                            this.state.listaUnidadesArbys.map((unidad) => {
                                return (
                                    <Col>
                                        <Card
                                            style={{
                                                width: '18rem',
                                                height: "400px",
                                                cursor: "pointer",
                                                marginTop: "20px",
                                                marginBottom: "20px"
                                            }}
                                            onClick={async () => {

                                                await this.setState({
                                                    unidadArbys: unidad
                                                });
                                                await this.abrirCerrarModalArbysUnidad();
                                            }}
                                        >
                                            <img
                                                alt="Sample"
                                                src={unidad.img.length === 0 ? arbysLogo : unidad.img}
                                                height="200px"
                                            />
                                            <CardBody>
                                                <CardTitle tag="h5">
                                                    {unidad.nombreUnidad}
                                                </CardTitle>
                                                <CardSubtitle
                                                    className="mb-2"
                                                    tag="h6"
                                                    style={{
                                                        color: unidad.IdStatus == 1 ? "grey" : unidad.IdStatus == 2 ? "green" : "#000000"
                                                    }}
                                                >
                                                    {unidad.status}
                                                </CardSubtitle>
                                                <CardSubtitle
                                                    className="mb-2"
                                                    tag="h6"
                                                    style={{
                                                    }}
                                                >
                                                    {unidad.estado}
                                                </CardSubtitle>
                                                <CardText>
                                                    {unidad.direccion}
                                                </CardText>
                                            </CardBody>
                                        </Card>


                                    </Col>
                                );
                            })
                        }

                    </Row>
                </div>
                <Modal
                    isOpen={this.state.estaAbiertoModalUnidadArbys}
                    size={this.state.device.isDesktopDevice ? "lg" : "lg"}
                    style={{
                        maxWidth: this.state.device.isDesktopDevice ? "80%" : "80%",
                    }}
                >
                    <ModalHeader
                        close={
                            <IconButton
                                style={{
                                    color: colors.sushiitto.morado
                                }}
                                onClick={() => {
                                    this.abrirCerrarModalArbysUnidad();
                                }}
                            >
                                <Close />
                            </IconButton>
                        }
                        style={{
                            backgroundColor: colors.arbys.blanco,
                            color: colors.sushiitto.morado
                        }}
                    >
                        <Label
                            style={{
                                fontWeight: "bold"
                            }}
                        >
                            {this.state.unidadArbys.nombreUnidad}
                        </Label>
                    </ModalHeader>
                    <ModalBody>
                        {
                            this.state.device.isMobileDevice && (this.state.orientation == 0) ?
                                <center>
                                    <Label>
                                        Pon tu teléfono horizontal para poder ver bien el contenido.
                                    </Label>
                                </center>
                                :
                                <Row
                                    style={{
                                        marginRight: this.state.device.isDesktopDevice ? "50px" : "10px",
                                        marginLeft: this.state.device.isDesktopDevice ? "50px" : "10px",
                                    }}
                                >
                                    <Col
                                        style={{
                                            maxWidth: "50%"
                                        }}
                                    >
                                        <Row>
                                            <Col
                                                style={{
                                                    maxWidth: "10%",
                                                    color: colors.arbys.rojo
                                                }}
                                            >
                                                <Room />
                                            </Col>
                                            <Col
                                                style={{
                                                    maxWidth: "90%",
                                                    color: colors.arbys.negro
                                                }}
                                            >
                                                <Row>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold"
                                                        }}
                                                    >
                                                        Dirección
                                                    </Label>
                                                </Row>
                                                <Row>
                                                    <Label>
                                                        {this.state.unidadArbys.direccion}
                                                    </Label>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col
                                                style={{
                                                    maxWidth: "10%",
                                                    color: colors.arbys.rojo
                                                }}
                                            >
                                                <QueryBuilder />
                                            </Col>
                                            <Col
                                                style={{
                                                    maxWidth: "90%",
                                                    color: colors.arbys.negro
                                                }}
                                            >
                                                <Row>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold"
                                                        }}
                                                    >
                                                        Horario de Servicio
                                                    </Label>
                                                </Row>
                                                <Row>
                                                    <Label>
                                                        Lunes
                                                        <label
                                                            style={{
                                                                marginLeft: "35px"
                                                            }}
                                                        >
                                                            11:00 AM - 10:00 PM
                                                        </label>
                                                    </Label>
                                                    <br />
                                                    <Label>
                                                        Martes
                                                        <label
                                                            style={{
                                                                marginLeft: "28px"
                                                            }}
                                                        >
                                                            11:00 AM - 10:00 PM
                                                        </label>
                                                    </Label>
                                                    <br />
                                                    <Label>
                                                        Miércoles
                                                        <label
                                                            style={{
                                                                marginLeft: "8px"
                                                            }}
                                                        >
                                                            11:00 AM - 10:00 PM
                                                        </label>
                                                    </Label>
                                                    <br />
                                                    <Label>
                                                        Jueves
                                                        <label
                                                            style={{
                                                                marginLeft: "30px"
                                                            }}
                                                        >
                                                            11:00 AM - 10:00 PM
                                                        </label>
                                                    </Label>
                                                    <br />
                                                    <Label>
                                                        Viernes
                                                        <label
                                                            style={{
                                                                marginLeft: "24px"
                                                            }}
                                                        >
                                                            11:00 AM - 10:00 PM
                                                        </label>
                                                    </Label>
                                                    <br />
                                                    <Label>
                                                        Sábado
                                                        <label
                                                            style={{
                                                                marginLeft: "23px"
                                                            }}
                                                        >
                                                            11:00 AM - 11:00 PM
                                                        </label>
                                                    </Label>
                                                    <br />
                                                    <Label>
                                                        Domingo
                                                        <label
                                                            style={{
                                                                marginLeft: "10px"
                                                            }}
                                                        >
                                                            11:00 AM - 11:00 PM
                                                        </label>
                                                    </Label>
                                                    <br />
                                                </Row>
                                            </Col>
                                        </Row>
                                        {
                                            this.state.unidadArbys.urlGoogleStreetView.length === 0 ?
                                                null
                                                :
                                                <Row
                                                    style={{
                                                        marginTop: "10px"
                                                    }}
                                                >
                                                    <Col
                                                        style={{
                                                            maxWidth: "10%",
                                                            color: colors.arbys.rojo
                                                        }}
                                                    >
                                                        <Streetview />
                                                    </Col>
                                                    <Col
                                                        style={{
                                                            maxWidth: "90%",
                                                            color: colors.arbys.negro
                                                        }}
                                                    >
                                                        <Row>
                                                            <Label
                                                                style={{
                                                                    fontWeight: "bold",
                                                                    cursor: "pointer"
                                                                }}
                                                                onClick={() => {
                                                                    window.open(this.state.unidadArbys.urlGoogleStreetView);
                                                                }}
                                                            >
                                                                Has click aquí para hacer recorrido con Google Street View
                                                            </Label>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                        }

                                    </Col>
                                    <Col
                                        style={{
                                            maxWidth: "50%"
                                        }}
                                    >
                                        <center>
                                            {
                                                this.state.unidadArbys.urlGoogleMaps.length === 0 ?
                                                    <>
                                                        <lottie-player
                                                            autoplay
                                                            // loop
                                                            mode="normal"
                                                            src="https://assets1.lottiefiles.com/private_files/lf30_D4yZiV.json"
                                                            speed={1}
                                                            style={{
                                                                width: "200px",
                                                                height: "200px",
                                                                // marginTop: "40px"
                                                            }}
                                                        />
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                color: colors.negro,
                                                                fontSize: "20px"
                                                            }}
                                                        >
                                                            No se encontro un mapa de esta unidad
                                                        </Label>
                                                    </>
                                                    :
                                                    <iframe
                                                        src={this.state.unidadArbys.urlGoogleMaps}
                                                        width={this.state.device.isDesktopDevice ? 500 : 250}
                                                        height={this.state.device.isDesktopDevice ? 350 : 175}
                                                        style={{ border: 0 }}
                                                        allowFullScreen
                                                        loading="lazy"
                                                        referrerPolicy="no-referrer-when-downgrade"
                                                    />
                                            }

                                        </center>
                                    </Col>
                                </Row>
                        }


                    </ModalBody>
                    <ModalFooter>
                        {
                            this.state.unidadArbys.urlGoogleStreetView.length === 0 ?
                                null
                                :
                                <Button
                                    onClick={() => {
                                        window.open(this.state.unidadArbys.urlGoogleStreetView);
                                    }}
                                    style={{
                                        backgroundColor: colors.sushiitto.morado,
                                        color: colors.arbys.blanco,
                                        fontWeight: "bold",
                                        boxShadow: "none",
                                        borderColor: colors.sushiitto.morado,   
                                        width: "170px"
                                    }}
                                    title="Has click aqui para ver la unidad con Google Street View"
                                >
                                    Conocer Unidad
                                </Button>
                        }

                        <Button
                            onClick={() => {
                                this.abrirCerrarModalArbysUnidad();
                            }}
                            style={{
                                backgroundColor: colors.sushiitto.morado,
                                color: colors.arbys.blanco,
                                fontWeight: "bold",
                                boxShadow: "none",
                                borderColor: colors.sushiitto.morado,
                                width: "170px"
                            }}
                        >
                            Cerrar
                        </Button>
                    </ModalFooter>
                </Modal>


            </>
        );
    }

}

export default SushiittoUnidades;