import React, { useEffect, useState } from 'react';
import {
    Navbar,
    Nav,
    Button,
    Row,
    Col,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    CardHeader,
    Collapse,
    CardBody,
} from 'reactstrap';
import imgLogoGPMBlanco from "../../../assets/image/logo/gpmLogoBlanco.png";
import colors from "../../../config/colors.config.js";
import {
    Business,
    Close,
    EmojiEvents,
    Favorite,
    HomeWork,
    Menu,
    Person,
    Store,
} from '@material-ui/icons';
import { Card, IconButton } from '@material-ui/core';
import { Fade } from 'react-reveal';
import { Handshake, } from '@mui/icons-material';
import components from '../../../tools/components.tool';

const SNavBarMovil = (props) => {

    const [isOpenMenu, setIsOpenMenu] = useState(false);

    const openMenu = () => {
        console.log(isOpenMenu);
        setIsOpenMenu(!isOpenMenu);
        if (!isOpenMenu) {
            cargarAnimacion();
        } else {
            console.log("Borrando botones");
            setArrayButtos({
                0: false,
                1: false,
                2: false,
                3: false,
                4: false,
                5: false,
                6: false,
            });
        }

    };

    const [arrayButtons, setArrayButtos] = useState({
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
    });

    const toggle = (id) => {

        if (toggleQuestion === id) {
            setToggequestion(0);
        } else {
            setToggequestion(id)
        }

    };

    const [toggleQuestion, setToggequestion] = useState(0);//1 is the default id to be opened by default

    const cargarAnimacion = async () => {
        console.log("Iniciando animacion");
        console.log(arrayButtons);
        await components.sleep(0.2).then(() => {
            setArrayButtos({
                0: true,
                1: false,
                2: false,
                3: false,
                4: false,
                5: false,
                6: false,
            });
        });
        await components.sleep(0.2).then(() => {
            setArrayButtos({
                0: true,
                1: true,
                2: false,
                3: false,
                4: false,
                5: false,
                6: false,
            });
        });
        await components.sleep(0.2).then(() => {
            setArrayButtos({
                0: true,
                1: true,
                2: true,
                3: false,
                4: false,
                5: false,
                6: false,
            });
        });
        await components.sleep(0.2).then(() => {
            setArrayButtos({
                0: true,
                1: true,
                2: true,
                3: true,
                4: false,
                5: false,
                6: false,
            });
        });
        await components.sleep(0.2).then(() => {
            setArrayButtos({
                0: true,
                1: true,
                2: true,
                3: true,
                4: true,
                5: false,
                6: false,
            });
        });
        await components.sleep(0.2).then(() => {
            setArrayButtos({
                0: true,
                1: true,
                2: true,
                3: true,
                4: true,
                5: true,
                6: false,
            });
        });
        await components.sleep(0.2).then(() => {
            setArrayButtos({
                0: true,
                1: true,
                2: true,
                3: true,
                4: true,
                5: true,
                6: true,
            });
        });

    }

    useEffect(() => {
    }, []);

    return (

        <div

        >
            <Navbar
                expand="md"
                style={{
                    backgroundColor: colors.gpm.blanco,
                    height: "62px",
                    width: "100%",
                    position: "fixed",
                    zIndex: 1
                }}
            >
                <Nav className="ml-auto" expand="md" navbar style={{ marginTop: "-5px" }}>
                    <Row>
                        <Col
                            style={{
                                maxWidth: "70px"
                            }}
                        >
                            <img
                                src={imgLogoGPMBlanco}
                                alt="No imagen"
                                height={50}
                                style={{
                                    marginLeft: "10px",
                                    cursor: "pointer",
                                    marginTop: "10px"
                                }}
                                onClick={() => {
                                    window.location.href = "/"
                                }}
                            />
                        </Col>
                        <Col>
                            <Label
                                style={{
                                    color: colors.gpm.azul,
                                    //color: colors.halloween.morado,
                                    fontWeight: "bold",
                                    fontSize: "40px",
                                    marginLeft: "1px",
                                    marginTop: "10px",
                                    fontFamily: "'Oswald', sans-serif, 'Roboto Slab', serif",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    window.location.href = "/"
                                }}
                            >
                                G
                            </Label>
                            <Label
                                style={{
                                    color: colors.gpm.azul,
                                    //color: "#FFF",
                                    //textShadow: "-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black",
                                    fontWeight: "bold",
                                    fontSize: "40px",
                                    marginLeft: "1px",
                                    marginTop: "10px",
                                    fontFamily: "'Oswald', sans-serif, 'Roboto Slab', serif",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    window.location.href = "/"
                                }}
                            >
                                P
                            </Label>
                            <Label
                                style={{
                                    color: colors.gpm.azul,
                                    //color: colors.halloween.naranja,
                                    fontWeight: "bold",
                                    fontSize: "40px",
                                    marginLeft: "1px",
                                    marginTop: "10px",
                                    fontFamily: "'Oswald', sans-serif, 'Roboto Slab', serif",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    window.location.href = "/"
                                }}
                            >
                                M
                            </Label>
                        </Col>
                    </Row>
                </Nav>
                <Button
                    //color="primary"
                    style={{ backgroundColor: "#fff", borderColor: "#fff", boxShadow: "none", color: "#016390", fontWeight: "bold", marginTop: "-20px" }}
                    onClick={openMenu}
                >
                    <Menu style={{ color: "#016390", height: "30px", width: "30px", }} />
                </Button>
            </Navbar>
            <Modal
                isOpen={isOpenMenu}
                style={{
                    //marginTop:"65px"
                }}
                fullscreen
            >

                <ModalHeader
                    close={
                        <IconButton
                            onClick={openMenu}
                        >
                            <Close style={{ color: "#FFF", height: "30px", width: "30px", }} />
                        </IconButton>
                    }
                    style={{
                        backgroundColor: "#274157"
                    }}
                >
                    <Row>
                        <Col
                            style={{
                                maxWidth: "70px"
                            }}
                        >
                            <img
                                src={imgLogoGPMBlanco}
                                alt="No imagen"
                                height={50}
                                style={{
                                    marginLeft: "10px",
                                    cursor: "pointer",
                                    marginTop: "10px"
                                }}
                                onClick={() => {
                                    window.location.href = "/"
                                }}
                            />
                        </Col>
                        <Col>
                            <Label
                                style={{
                                    color: colors.gpm.blanco,
                                    fontWeight: "bold",
                                    fontSize: "30px",
                                    marginLeft: "0px",
                                    marginTop: "15px",
                                    fontFamily: "'Oswald', sans-serif, 'Roboto Slab', serif",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    window.location.href = "/"
                                }}
                            >
                                GPM
                            </Label>
                        </Col>
                    </Row>
                </ModalHeader>
                <ModalBody
                    style={{
                        backgroundColor: "#334F5A",
                        padding: 0,
                        margin: 0
                    }}
                >
                    {
                        arrayButtons[0] ?
                            <Fade>
                                <Card
                                    style={{
                                        marginRight: "0px",
                                        marginLeft: "0px",

                                    }}
                                    id="1"
                                >
                                    <CardHeader
                                        style={{ backgroundColor: "#0D6A8E", }}
                                        onClick={() => { toggle(1) }}
                                    >
                                        <Label
                                            style={{
                                                width: "100%",
                                                marginTop: "3%",
                                                marginBottom: "3%",
                                                fontSize: "24px",
                                                fontWeight: "bold",
                                                boxShadow: "none",
                                                borderColor: "#0D6A8E",
                                                color: colors.gpm.blanco,
                                            }}
                                        >
                                            <Business style={{ height: "36px", width: "36px", marginTop: "-10px" }} /> Somos GPM
                                        </Label>
                                    </CardHeader>
                                    <Collapse isOpen={toggleQuestion === 1 ? true : false}>
                                        <CardBody
                                            style={{
                                                marginRight: "20px",
                                                marginLeft: "20px",
                                                fontWeight: "bold",
                                                color: colors.gpm.azul,
                                                fontSize: "22px"
                                            }}
                                        >
                                            <Row>
                                                <Label
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        window.location.href = "/filosofia"
                                                    }}
                                                >
                                                    Nuestra Filosofía
                                                </Label>
                                            </Row>
                                            <Row>
                                                <Label
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        window.location.href = "/nuestras-marcas"
                                                    }}
                                                >
                                                    Nuestras Marcas
                                                </Label>
                                            </Row>
                                            <Row>
                                                <Label
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        window.location.href = "/nuestra-historia"
                                                    }}
                                                >
                                                    Nuestra Historia
                                                </Label>
                                            </Row>
                                            <Row>
                                                <Label
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        window.location.href = "/enfoque-al-cliente"
                                                    }}
                                                >
                                                    Nuestro Enfoque al Cliente
                                                </Label>
                                            </Row>
                                        </CardBody>
                                    </Collapse>
                                </Card>
                            </Fade>
                            :
                            null
                    }

                    {
                        arrayButtons[1] ?
                            <Fade>
                                <Card>
                                    <CardHeader
                                        style={{ backgroundColor: "#EAEAEA", }}
                                        onClick={() => { toggle(2) }}
                                    >
                                        <Label
                                            style={{
                                                width: "100%",
                                                marginTop: "3%",
                                                marginBottom: "3%",
                                                fontSize: "24px",
                                                fontWeight: "bold",
                                                boxShadow: "none",
                                                borderColor: "#EAEAEA",
                                                color: colors.gpm.azul,
                                            }}
                                        >
                                            <Handshake style={{ height: "36px", width: "36px", marginTop: "-10px" }} /> Nuestro Compromiso
                                        </Label>
                                    </CardHeader>
                                    <Collapse isOpen={toggleQuestion === 2 ? true : false}>
                                        <CardBody
                                            style={{
                                                marginRight: "20px",
                                                marginLeft: "20px",
                                                fontWeight: "bold",
                                                color: colors.gpm.azul,
                                                fontSize: "22px",
                                            }}
                                        >
                                            <Row>
                                                <Label
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        window.location.href = "/nuestra-etica";
                                                    }}
                                                >
                                                    Nuestra Ética
                                                </Label>
                                            </Row>
                                            <Row>
                                                <Label
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        window.location.href = "/nuestros-logros";
                                                    }}
                                                >
                                                    Nuestros Logros
                                                </Label>
                                            </Row>
                                        </CardBody>
                                    </Collapse>
                                </Card>
                            </Fade>
                            :
                            null
                    }

                    {
                        arrayButtons[2] ?
                            <Fade>
                                <Card>
                                    <CardHeader
                                        style={{ backgroundColor: "#B8D1DB", }}
                                        onClick={() => { toggle(3) }}
                                    >
                                        <Label
                                            style={{
                                                width: "100%",
                                                marginTop: "3%",
                                                marginBottom: "3%",
                                                fontSize: "24px",
                                                fontWeight: "bold",
                                                boxShadow: "none",
                                                borderColor: "#B8D1DB",
                                                color: colors.gpm.blanco,
                                            }}
                                        >
                                            <Store style={{ height: "36px", width: "36px", marginTop: "-10px" }} /> Nuestras Marcas
                                        </Label>
                                    </CardHeader>
                                    <Collapse isOpen={toggleQuestion === 3 ? true : false}>
                                        <CardBody
                                            style={{
                                                marginRight: "20px",
                                                marginLeft: "20px",
                                                fontWeight: "bold",
                                                color: colors.gpm.azul,
                                                fontSize: "22px",
                                            }}
                                        >
                                            <Row>
                                                <Label
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        window.location.href = "/gpm/dominos";
                                                    }}
                                                >
                                                    Domino's GPM
                                                </Label>
                                            </Row>
                                            <Row>
                                                <Label
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        window.location.href = "/gpm/arbys";
                                                    }}
                                                >
                                                    Arby's GPM
                                                </Label>
                                            </Row>
                                            <Row>
                                                <Label
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        window.location.href = "/gpm/sushiitto";
                                                    }}
                                                >
                                                    SushiItto GPM
                                                </Label>
                                            </Row>
                                        </CardBody>
                                    </Collapse>
                                </Card>
                            </Fade>
                            :
                            null
                    }

                    {
                        arrayButtons[3] ?
                            <Fade>
                                <Card>
                                    <CardHeader
                                        style={{ backgroundColor: "#4BB87A", }}
                                        onClick={() => {
                                            window.location.href = "/nuestros-logros";
                                        }}
                                    >
                                        <Label
                                            style={{
                                                width: "100%",
                                                marginTop: "3%",
                                                marginBottom: "3%",
                                                fontSize: "24px",
                                                fontWeight: "bold",
                                                boxShadow: "none",
                                                borderColor: "#4BB87A",
                                                color: colors.gpm.blanco,
                                            }}
                                        >
                                            <EmojiEvents style={{ height: "36px", width: "36px", marginTop: "-10px" }} /> Nuestros Logros
                                        </Label>
                                    </CardHeader>
                                    <Collapse isOpen={toggleQuestion === 4 ? true : false}>
                                        <CardBody>
                                        </CardBody>
                                    </Collapse>
                                </Card>
                            </Fade>
                            :
                            null
                    }

                    {
                        arrayButtons[4] ?
                            <Fade>
                                <Card>
                                    <CardHeader
                                        style={{ backgroundColor: "#4AB5E2", }}
                                        onClick={() => { toggle(5) }}
                                    >
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                width: "100%",
                                                marginTop: "3%",
                                                marginBottom: "3%",
                                                fontSize: "24px",
                                                boxShadow: "none",
                                                borderColor: "#4AB5E2",
                                                color: colors.gpm.blanco,
                                            }}
                                        >
                                            <Person style={{ height: "36px", width: "36px", marginTop: "-10px" }} />  Empleos y Cultura
                                        </Label>
                                    </CardHeader>
                                    <Collapse isOpen={toggleQuestion === 5 ? true : false}>
                                        <CardBody
                                            style={{
                                                marginRight: "20px",
                                                marginLeft: "20px",
                                                fontWeight: "bold",
                                                color: colors.gpm.azul,
                                                fontSize: "22px",
                                            }}
                                        >
                                            <Row>
                                                <Label
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        window.location.href = "/culturawow";
                                                    }}
                                                >
                                                    Cultura WOW
                                                </Label>
                                            </Row>
                                            <Row>
                                                <Label
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        window.location.href = "https://postulate.dominosgpm.com.mx";
                                                    }}
                                                >
                                                    Postulate en Domino's GPM
                                                </Label>
                                            </Row>
                                            <Row>
                                                <Label
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        // window.location.href = "#";
                                                    }}
                                                >
                                                    Postulate en Arby's GPM
                                                </Label>
                                            </Row>
                                            <Row>
                                                <Label
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        // window.location.href = "#";
                                                    }}
                                                >
                                                    Postulate SushiItto GPM
                                                </Label>
                                            </Row>
                                        </CardBody>
                                    </Collapse>
                                </Card>
                            </Fade>
                            :
                            null
                    }

                    {
                        arrayButtons[5] ?
                            <Fade>
                                <Card>
                                    <CardHeader
                                        style={{ backgroundColor: "#043B53", }}
                                        onClick={() => { window.location.href = "/nuestro-aporte"; }}
                                    >
                                        <Label
                                            style={{
                                                width: "100%",
                                                marginTop: "3%",
                                                marginBottom: "3%",
                                                fontSize: "24px",
                                                fontWeight: "bold",
                                                boxShadow: "none",
                                                borderColor: "#043B53",
                                                color: colors.gpm.blanco,
                                            }}
                                        >
                                            <Favorite style={{ height: "36px", width: "36px", marginTop: "-10px" }} /> Nuestro Aporte
                                        </Label>
                                    </CardHeader>
                                    <Collapse isOpen={toggleQuestion === 6 ? true : false}>
                                        <CardBody>
                                        </CardBody>
                                    </Collapse>
                                </Card>
                            </Fade>
                            :
                            null
                    }

                    {
                        arrayButtons[6] ?
                            <Fade>
                                <Card>
                                    <CardHeader
                                        style={{ backgroundColor: "#1179AC", }}
                                        onClick={() => { window.location.href = "/bienes-raices"; }}
                                    >
                                        <Label
                                            style={{
                                                width: "100%",
                                                marginTop: "3%",
                                                marginBottom: "3%",
                                                fontSize: "24px",
                                                fontWeight: "bold",
                                                boxShadow: "none",
                                                borderColor: "#1179AC",
                                                color: colors.gpm.blanco,
                                            }}
                                        >
                                            <HomeWork style={{ height: "36px", width: "36px", marginTop: "-10px" }} /> Bienes Raíces
                                        </Label>
                                    </CardHeader>
                                    <Collapse isOpen={toggleQuestion === 7 ? true : false}>
                                        <CardBody>
                                        </CardBody>
                                    </Collapse>
                                </Card>
                            </Fade>
                            :
                            null
                    }

                </ModalBody>

            </Modal>

        </div >


    );
}

export default SNavBarMovil;