import { Component } from "react";
import SNavBarComputer from "./NavBar/GPM/SNavBarComputer";
import ScreenTools from "../config/screen.config";
import Zoom from 'react-reveal/Zoom';
import apoyoFuncaciones from "../assets/image/apoyoFuncaciones.jpg";
import img_2 from "../assets/image/fundacionLazos/img_2.png";
import img_3 from "../assets/image/fundacionLazos/img_3.png";
import img_4 from "../assets/image/fundacionLazos/img_4.png";
import demo_modulo1 from "../assets/image/teleton/demo_modulo1.png";
import demo_modulo2 from "../assets/image/teleton/demo_modulo2.png";
import demo_modulo3 from "../assets/image/teleton/demo_modulo3.png";
import demo_modulo4 from "../assets/image/teleton/demo_modulo4.png";
import descargar from "../assets/image/teleton/descargar.jpeg";
import Simg_1 from "../assets/image/seliciano/img_1.jpg";
import mapa from "../assets/image/fundacionLazos/mapa.png";
import logoC from "../assets/image/logo/logoC.png";
import colors from "../config/colors.config";
import { Col, Label, List, Modal, ModalBody, ModalHeader, Row, UncontrolledCarousel } from "reactstrap";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Fade from 'react-reveal/Fade';
import SNavBarMovil from "./NavBar/GPM/SNavBarMovil";
import Block from "../tools/Block";

class NuestroAporte extends Component {

    constructor(props) {
        super(props);
        this.state = {
            estaAbiertoModalImgMapa: false,
            isHoverlogoC: false,
            device: {
                isDesktopDevice: null,
                isMobileDevice: null,
                screenHeight: null,
                screenWidth: null,
            },
        }
    }
    componentDidMount() {
        this.cargarDatosPantalla();
    }

    cargarDatosPantalla = () => {

        let device = ScreenTools.getDeviceSettings()

        // console.log(device);
        this.setState({
            device
        });

    }

    render() {
        return (
            <>
                {this.state.device.isDesktopDevice ? <SNavBarComputer clickOnHome={null} isMain={false} /> : <SNavBarMovil />}
                {/* <div style={{height:"100px"}} ></div> */}

                {
                    this.state.device.isDesktopDevice ?

                        <>
                            <section
                                style={{
                                    backgroundImage: `url(${apoyoFuncaciones})`,
                                    backgroundSize: "100% 100%",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center center",
                                    width: "100%",
                                    height: "100vh",
                                    // background: `url(${apoyoFuncaciones}) no-repeat center center/cover`,
                                }}
                            >
                                <div style={{ height: "100px" }} ></div>
                                <Zoom top>
                                    <Row
                                        style={{
                                            marginRight: "5%",
                                            marginLeft: "5%",
                                            backgroundColor: 'rgba(0, 0, 0, 0.10)',
                                            borderRadius: '10px',
                                            backdropFilter: 'blur(5px)',
                                            // padding: '20px',
                                        }}
                                    >
                                        <div style={{ height: "10px" }} ></div>
                                        <center>
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "48px",
                                                    color: colors.gpm.blanco,
                                                }}
                                            >
                                                Nuestro Aporte a la Sociedad
                                            </Label>
                                            <br />
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "20px",
                                                    color: colors.gpm.blanco,
                                                }}
                                            >
                                                No hay barreras imposibles cuando existe la voluntad de ayudar
                                            </Label>
                                        </center>
                                        <div style={{ height: "30px" }} ></div>

                                        <Row>
                                            <Col
                                                style={{
                                                    maxWidth: "50%",
                                                    fontWeight: "bold",
                                                    fontSize: "22px",
                                                    color: colors.gpm.blanco,
                                                    textAlign: "justify",
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        marginRight: "50px",
                                                        marginLeft: "50px"
                                                    }}
                                                >
                                                    Como miembros comprometidos del Grupo Penagos Meneses (GPM), durante  varios años, hemos extendido nuestro apoyo
                                                    y colaboración a diversas fundaciones significativas, entre las que se incluyen
                                                </p>
                                                <p
                                                    style={{
                                                        marginRight: "50px",
                                                        marginLeft: "50px"
                                                    }}
                                                >
                                                    <List
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <li
                                                            onClick={() => {
                                                                window.location.href = "#fundacionLazos"
                                                            }}
                                                        >
                                                            Fundación Lazos.
                                                        </li>
                                                        <li
                                                            onClick={() => {
                                                                window.location.href = "#teleton"
                                                            }}
                                                        >
                                                            Teletón.
                                                        </li>
                                                        <li
                                                            onClick={() => {
                                                                window.location.href = "#"
                                                            }}
                                                        >
                                                            Casa Hogar Mano Amiga.
                                                        </li>
                                                        <li
                                                            onClick={() => {
                                                                window.location.href = "#salesiano"
                                                            }}
                                                        >
                                                            Albergue Infantil Salesiano.
                                                        </li>
                                                        <li
                                                            onClick={() => {
                                                                window.location.href = "#tecmonte"
                                                            }}
                                                        >
                                                            Tecnológico de Monterrey.
                                                        </li>
                                                        <li>
                                                            Entre otros...
                                                        </li>
                                                    </List>
                                                </p>
                                            </Col>
                                            <Col
                                                style={{
                                                    maxWidth: "50%",
                                                }}
                                            >
                                                <center>
                                                    <img
                                                        src={logoC}
                                                        width={300}
                                                        alt="logoC"
                                                        style={{
                                                            transform: this.state.isHoverlogoC ? 'scale(1.2)' : 'scale(1)',
                                                            transition: 'transform 0.3s ease',
                                                        }}
                                                        onMouseEnter={() => {
                                                            this.setState({ isHoverlogoC: true });
                                                        }}
                                                        onMouseLeave={() => {
                                                            this.setState({ isHoverlogoC: false });
                                                        }}
                                                    />
                                                </center>
                                            </Col>
                                        </Row>
                                        <div style={{ height: "30px" }} ></div>
                                    </Row>
                                </Zoom>
                            </section>

                            <section
                                id="fundacionLazos"
                                style={{
                                    width: "100%",
                                    height: "100vh",
                                    backgroundColor: "#29A9E0",
                                }}
                            >
                                <Row
                                    style={{
                                        width: "100%",
                                        height: "100vh",
                                        fontFamily: "'Roboto Slab', serif",
                                    }}
                                >

                                    <Col
                                        style={{
                                            maxWidth: "50%",
                                            // backgroundImage: `url(${img_1})`,
                                            // backgroundSize: "90% 90%",
                                            // backgroundRepeat: "no-repeat",
                                            // backgroundPosition: "center center",
                                        }}
                                    >
                                        <center>
                                            <Fade left>

                                                {/* <img
                                        src={img_1}
                                        width={"90%"}
                                        height={"100%"}
                                        alt="logoC"
                                        style={{
                                            borderRadius:"10px"
                                        }}                                    

                                    /> */}
                                                <div style={{ height: "15vh" }} ></div>
                                                <UncontrolledCarousel
                                                    style={{
                                                        marginRight: "10px",
                                                        marginLeft: "15px",
                                                        borderRadius: "10px"
                                                    }}
                                                    items={[
                                                        // {
                                                        //     key: 1,
                                                        //     src: img_1
                                                        // },
                                                        {
                                                            key: 2,
                                                            src: img_2
                                                        },
                                                        {
                                                            key: 3,
                                                            src: img_3
                                                        },
                                                        {
                                                            key: 4,
                                                            src: img_4
                                                        }
                                                    ]}
                                                />
                                            </Fade>
                                        </center>
                                    </Col>

                                    <Col
                                        style={{
                                            maxWidth: "50%",
                                        }}
                                    >
                                        <Fade right>
                                            <div style={{ height: "15vh" }} ></div>
                                            <Row
                                                style={{
                                                    backgroundColor: "rgb(0,0,0,0.10)",
                                                    borderRadius: "10px 10px 0px 0px"
                                                }}
                                            >
                                                <Label
                                                    style={{
                                                        fontSize: "48px",
                                                        fontWeight: "bold",
                                                        color: colors.gpm.blanco
                                                    }}
                                                >
                                                    Fundación Lazos
                                                </Label>
                                            </Row>
                                            <Row
                                                style={{
                                                    backgroundColor: "rgb(0,0,0,0.10)"
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        fontSize: "18px",
                                                        fontWeight: "bold",
                                                        color: colors.gpm.blanco,
                                                        textAlign: "justify",
                                                    }}
                                                >
                                                    Una entidad mexicana que lleva más de 25 años fomentando la formación en valores y el
                                                    rendimiento académico en escuelas primarias y secundarias públicas en entornos vulnerables,
                                                    con el fin de elevar la calidad educativa de nuestro país.
                                                </p>
                                            </Row>
                                            <Row
                                                style={{
                                                    backgroundColor: "rgb(0,0,0,0.10)",
                                                    borderRadius: "0px 0px 10px 10px"
                                                }}
                                            >

                                                {/* <Col
                                        style={{
                                            maxWidth: "40%"
                                        }}
                                    >
                                        <center>
                                            <img
                                                src={mapa}
                                                alt="No se pudo cargar la imagen"
                                                style={{
                                                    borderRadius: "10px",
                                                    width: "100%",
                                                    height: "350px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                    this.setState({
                                                        estaAbiertoModalImgMapa: true,
                                                    });
                                                }}
                                            />
                                        </center>
                                    </Col> */}

                                                <Col
                                                    style={{
                                                        // maxWidth: "60%"
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            fontSize: "18px",
                                                            fontWeight: "bold",
                                                            color: colors.gpm.blanco,
                                                            textAlign: "justify",
                                                        }}
                                                    >
                                                        Implementan el Modelo Educativo Lazos, a través del cual involucran a cada uno de los actores sociales protagonistas de la comunidad escolar; alumnos, directivos, docentes y padres de familia.
                                                    </p>
                                                    <p
                                                        style={{
                                                            fontSize: "18px",
                                                            fontWeight: "bold",
                                                            color: colors.gpm.blanco,
                                                            textAlign: "justify",
                                                        }}
                                                    >
                                                        Mediante la red de asesores y facilitadores, en cada nivel educativo, primaria y secundaria, implementan los programas que componen el Modelo.
                                                    </p>
                                                    <div style={{ height: "5px" }} ></div>
                                                    <Row>
                                                        <Label
                                                            style={{
                                                                fontSize: "18px",
                                                                fontWeight: "bold",
                                                                color: colors.gpm.blanco,
                                                                textAlign: "justify",
                                                                // cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                // window.open("https://www.lazos.org.mx", "_blank");

                                                            }}
                                                        >
                                                            Conoce mas acerca de esta organización
                                                        </Label>
                                                    </Row>
                                                    <Row>
                                                        <Label
                                                            style={{
                                                                fontSize: "18px",
                                                                fontWeight: "bold",
                                                                color: colors.gpm.blanco,
                                                                textAlign: "justify",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                window.open("https://www.lazos.org.mx", "_blank");

                                                            }}
                                                        >
                                                            Pagina Web : https://www.lazos.org.mx
                                                        </Label>
                                                    </Row>
                                                    <Row>
                                                        <Label
                                                            style={{
                                                                fontSize: "18px",
                                                                fontWeight: "bold",
                                                                color: colors.gpm.blanco,
                                                                textAlign: "justify",
                                                                // cursor: "pointer",
                                                            }}
                                                        >
                                                            Dirección: Juan Pablo II 444, Zona Sin Asignación de Nombre de Col 24, 29040 Tuxtla Gutiérrez, Chis.
                                                        </Label>
                                                    </Row>
                                                    <Row>
                                                        <Label
                                                            style={{
                                                                fontSize: "18px",
                                                                fontWeight: "bold",
                                                                color: colors.gpm.blanco,
                                                                textAlign: "justify",
                                                                // cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                window.location.href = "tel:+52961 617 2400";

                                                            }}
                                                        >
                                                            Teléfono: 961 617 2400
                                                        </Label>
                                                    </Row>
                                                </Col>
                                                {/* <div style={{height:"90px"}} ></div> */}
                                            </Row>
                                        </Fade>
                                    </Col>

                                </Row>

                            </section>

                            <section
                                id="teleton"
                                style={{
                                    width: "100%",
                                    height: "100vh",
                                    backgroundColor: "#6A1F74",
                                }}
                            >
                                <Row
                                    style={{
                                        width: "100%",
                                        height: "50vh",
                                        fontFamily: "'Roboto Slab', serif",
                                    }}
                                >
                                    <Col
                                        style={{
                                            maxWidth: "50%",

                                        }}
                                    >
                                        <Fade left>
                                            <div
                                                style={{
                                                    marginTop: "20vh",
                                                    backgroundColor: "#FCD901",
                                                    marginRight: "20px",
                                                    marginLeft: "20px",
                                                    borderRadius: "10px",
                                                    height: "512px"
                                                }}
                                            >
                                                <div>
                                                    <Label
                                                        style={{
                                                            fontSize: "48px",
                                                            fontWeight: "bold",
                                                            color: "#6A1F74",
                                                            marginLeft: "15px",
                                                        }}
                                                    >
                                                        Fundación Teletón
                                                    </Label>
                                                </div>
                                                <div>
                                                    <p
                                                        style={{
                                                            fontSize: "18px",
                                                            fontWeight: "bold",
                                                            color: "#6A1F74",
                                                            textAlign: "justify",
                                                            marginRight: "15px",
                                                            marginLeft: "15px",
                                                        }}
                                                    >
                                                        Fundación Teletón es una organización sin ﬁnes de lucro que busca servir a personas
                                                        con discapacidad, cáncer y autismo, ofreciéndoles una atención integral y de calidad
                                                        que promueva desarrollar su máximo potencial, así como su inclusión en la sociedad.
                                                    </p>
                                                </div>
                                                <div>
                                                    <center>
                                                        <img
                                                            src={descargar}
                                                            alt="descargar"
                                                            style={{
                                                                borderRadius: "10px"
                                                            }}
                                                        />
                                                    </center>
                                                </div>
                                                <div
                                                    style={{
                                                        fontSize: "18px",
                                                        fontWeight: "bold",
                                                        color: "#6A1F74",
                                                        textAlign: "justify",
                                                        marginRight: "15px",
                                                        marginLeft: "15px",
                                                    }}
                                                >
                                                    <label>
                                                        Conoce mas a cerca de esta fundación
                                                    </label>
                                                    <br />
                                                    <label
                                                        onClick={() => {
                                                            window.open("https://teleton.org");
                                                        }}
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        Pagina Web : https://teleton.org
                                                    </label>
                                                    <br />
                                                    <label>
                                                        Dirección: Boulevard, Juan Pablo II 444, El Retiro, 29040 Tuxtla Gutiérrez, Chis.
                                                    </label>
                                                    <br />
                                                    <label
                                                        onClick={() => {
                                                            window.location.href = "tel:+529616172400";
                                                        }}
                                                    >
                                                        Teléfono: 961 617 2400
                                                    </label>
                                                </div>
                                            </div>
                                        </Fade>
                                    </Col>
                                    <Col
                                        style={{
                                            maxWidth: "50%",

                                        }}
                                    >
                                        <div style={{ height: "20vh" }} ></div>
                                        <Fade right>
                                            <UncontrolledCarousel
                                                items={[
                                                    {
                                                        key: 1,
                                                        src: demo_modulo1
                                                    },
                                                    {
                                                        key: 2,
                                                        src: demo_modulo2
                                                    },
                                                    {
                                                        key: 3,
                                                        src: demo_modulo3
                                                    },
                                                    {
                                                        key: 4,
                                                        src: demo_modulo4
                                                    }
                                                ]}
                                            />
                                        </Fade>
                                    </Col>
                                </Row>
                            </section>

                            <section
                                id=""
                                style={{
                                    width: "100%",
                                    height: "100vh",
                                    backgroundColor: "#FFA500",
                                }}
                            >
                                <Row
                                    style={{
                                        width: "100%",
                                        height: "100vh",
                                    }}
                                >


                                    <div style={{ height: "80px" }} ></div>
                                    <Col
                                        style={{
                                            maxWidth: "50%"
                                        }}
                                    >

                                        <center>
                                            <Fade left  >
                                                <iframe
                                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15281.405039904954!2d-93.1388226!3d16.7591909!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85ecd91d492315c7%3A0xfa787de072999371!2sCasa%20Hogar%20Manos%20Amigas!5e0!3m2!1ses!2smx!4v1692062186456!5m2!1ses!2smx"
                                                    width={"650"}
                                                    height={"450"}
                                                    style={{
                                                        border: 0,
                                                        borderRadius: "10px"

                                                    }}
                                                    allowFullScreen
                                                    loading="lazy"
                                                    referrerPolicy="no-referrer-when-downgrade"
                                                    title="Map"
                                                />
                                            </Fade>
                                        </center>
                                    </Col>


                                    <Col
                                        style={{
                                            maxWidth: "50%"
                                        }}
                                    >
                                        <Fade right>
                                            <Row
                                                style={{
                                                    backgroundColor: 'rgba(255, 255, 255, 0.20)',
                                                    marginRight: "15px",
                                                    borderRadius: "10px"
                                                }}
                                            >

                                                <Label
                                                    style={{
                                                        fontSize: "48px",
                                                        fontWeight: "bold",
                                                        color: colors.gpm.azul,
                                                        // marginLeft: "15px",
                                                    }}
                                                >
                                                    Casa Manos Amigas
                                                </Label>
                                                <br />
                                                <p
                                                    style={{
                                                        fontSize: "18px",
                                                        fontWeight: "bold",
                                                        color: colors.gpm.azul,
                                                        textAlign: "justify",
                                                    }}
                                                >
                                                    Se encuentran en la categoría de las Unidades económicas del sector privado dedicadas principalmente a
                                                    proporcionar servicios de vivienda, manutención y otros cuidados en orfanatos y otras residencias de
                                                    asistencia social, como casas cuna, hogares para madres solteras, hogares para discapacitados y casas para
                                                    jóvenes con padres delincuentes.
                                                </p>
                                                <br />
                                                <Label
                                                    style={{
                                                        fontSize: "18px",
                                                        fontWeight: "bold",
                                                        color: colors.gpm.azul,
                                                        textAlign: "justify",
                                                    }}
                                                >
                                                    Conoce mas de esta fundacion
                                                </Label>
                                                <br />
                                                <Label
                                                    onClick={() => {
                                                        window.open("https://www.webscomercio.com/pyme-62369807/casa-hogar-manos-amigas");
                                                    }}
                                                    style={{
                                                        fontSize: "18px",
                                                        fontWeight: "bold",
                                                        color: colors.gpm.azul,
                                                        textAlign: "justify",
                                                    }}

                                                >
                                                    CLick aquí para ver su pagina web
                                                </Label>
                                                <Label

                                                    style={{
                                                        fontSize: "18px",
                                                        fontWeight: "bold",
                                                        color: colors.gpm.azul,
                                                        textAlign: "justify",
                                                    }}

                                                >
                                                    Dirección: El Sabinal 100, Residencial Campestre, 29030 Tuxtla Gutiérrez, Chis.
                                                </Label>
                                                <Label
                                                    style={{
                                                        fontSize: "18px",
                                                        fontWeight: "bold",
                                                        color: colors.gpm.azul,
                                                        textAlign: "justify",
                                                    }}
                                                    onClick={() => {
                                                        window.location.href = "tel:+529611214554";
                                                    }}
                                                >
                                                    Teléfono: 961 121 4554
                                                </Label>
                                            </Row>
                                        </Fade>
                                    </Col>

                                </Row>
                            </section>

                            <section
                                id="salesiano"
                                style={{
                                    width: "100%",
                                    height: "100vh",
                                    backgroundColor: "#3360FF"
                                }}
                            >
                                <div style={{ height: "80px" }} ></div>
                                <Row
                                    style={{
                                        width: "100%",
                                        height: "100vh",
                                    }}
                                >
                                    <Col
                                        style={{
                                            maxWidth: "50%"
                                        }}
                                    >
                                        <Fade left>
                                            <Row
                                                style={{
                                                    backgroundColor: 'rgba(255, 255, 255, 0.20)',
                                                    marginLeft: "15px",
                                                    borderRadius: "10px"
                                                }}
                                            >

                                                <Label
                                                    style={{
                                                        fontSize: "48px",
                                                        fontWeight: "bold",
                                                        color: colors.gpm.blanco,
                                                        // marginLeft: "15px",
                                                    }}
                                                >
                                                    Albergue Infantil Salesiano
                                                </Label>
                                                <br />
                                                <p
                                                    style={{
                                                        fontSize: "18px",
                                                        fontWeight: "bold",
                                                        color: colors.gpm.blanco,
                                                        textAlign: "justify",
                                                    }}
                                                >
                                                    Es una asociación civil sin fines de lucro formadas por un grupo de personas de Tuxtla Gutierrez,
                                                    Chiapas que trabajan unidos desde hace mas de 20 años con el propósito de lograr cambios positivos
                                                    y duraderos en las condiciones de vida y en las posibilidades de desarrollo de niños y adolescentes
                                                    con características de abandono, maltrato, explotación, abuso sexual y otros problemas graves
                                                    brindándoles un habiente hogar orientándolos al estudio, capacitándolos en pequeños oficios
                                                    velando por su formación moral, intelectual y social
                                                </p>
                                                <br />
                                                <Label
                                                    style={{
                                                        fontSize: "18px",
                                                        fontWeight: "bold",
                                                        color: colors.gpm.blanco,
                                                        textAlign: "justify",
                                                    }}
                                                >
                                                    Conoce mas de esta fundacion
                                                </Label>
                                                <br />
                                                <Label
                                                    onClick={() => {
                                                        window.open("https://www.facebook.com/albergueinfantilsalesianoac/about?locale=es_LA");
                                                    }}
                                                    style={{
                                                        fontSize: "18px",
                                                        fontWeight: "bold",
                                                        color: colors.gpm.blanco,
                                                        textAlign: "justify",
                                                        cursor: "pointer"
                                                    }}

                                                >
                                                    CLick aquí para ver su pagina de FaceBook
                                                </Label>
                                                <Label

                                                    style={{
                                                        fontSize: "18px",
                                                        fontWeight: "bold",
                                                        color: colors.gpm.blanco,
                                                        textAlign: "justify",
                                                    }}

                                                >
                                                    Dirección: República de Uruguay 502, El Retiro, 29040 Tuxtla Gutiérrez, Chis.
                                                </Label>
                                                <Label
                                                    style={{
                                                        fontSize: "18px",
                                                        fontWeight: "bold",
                                                        color: colors.gpm.blanco,
                                                        textAlign: "justify",
                                                    }}
                                                    onClick={() => {
                                                        window.location.href = "tel:+529616141560";
                                                    }}
                                                >
                                                    Teléfono: 961 614 1560
                                                </Label>
                                            </Row>
                                        </Fade>
                                    </Col>
                                    <Col
                                        style={{
                                            maxWidth: "50%"
                                        }}
                                    >
                                        <center>
                                            <Fade right  >
                                                <img
                                                    src={Simg_1}
                                                    alt="Simg_1"
                                                    style={{
                                                        borderRadius: "10px",
                                                    }}
                                                />
                                            </Fade>
                                        </center>
                                    </Col>
                                </Row>
                            </section>
                        </>
                        :
                        <>
                            <section
                                style={{
                                    // backgroundImage: `url(${apoyoFuncaciones})`,
                                    backgroundSize: "100% 100%",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center center",
                                    //position: "fixed",
                                    width: "100%",
                                    height: "100vh",
                                }}
                            >
                                <img
                                    src={apoyoFuncaciones}
                                    alt=""
                                    style={{
                                        width: "100%",
                                        height: "100vh",
                                        position: "fixed",
                                        zIndex: "-1"
                                    }}
                                />
                                <Block height={"70px"} />
                                <Row
                                    style={{
                                        marginRight: "10px",
                                        marginLeft: "10px"
                                    }}
                                >
                                    <center>
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "48px",
                                                color: colors.gpm.blanco,
                                            }}
                                        >
                                            Nuestro Aporte a la Sociedad
                                        </Label>
                                        <br />
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "20px",
                                                color: colors.gpm.blanco,
                                            }}
                                        >
                                            No hay barreras imposibles cuando existe la voluntad de ayudar
                                        </Label>
                                    </center>
                                </Row>
                                <Row
                                    style={{
                                        marginRight: "10px",
                                        marginLeft: "10px"
                                    }}
                                >
                                    <center>
                                        <img
                                            src={logoC}
                                            width={200}
                                            alt="logoC"
                                            style={{
                                                transform: this.state.isHoverlogoC ? 'scale(1.2)' : 'scale(1)',
                                                transition: 'transform 0.3s ease',
                                                marginTop: "10px"
                                            }}
                                            onMouseEnter={() => {
                                                this.setState({ isHoverlogoC: true });
                                            }}
                                            onMouseLeave={() => {
                                                this.setState({ isHoverlogoC: false });
                                            }}
                                        />
                                    </center>
                                </Row>
                            </section>
                            <section
                                style={{
                                    width: "100%",
                                    height: "100vh",
                                }}
                            >
                                <Row
                                    style={{
                                        marginRight: "10px",
                                        marginLeft: "10px",
                                        fontWeight: "bold",
                                        fontSize: "22px",
                                        color: colors.gpm.blanco,
                                        textAlign: "justify",
                                    }}
                                >
                                    <p>
                                        Como miembros comprometidos del Grupo Penagos Meneses (GPM), durante  varios años, hemos extendido nuestro apoyo
                                        y colaboración a diversas fundaciones significativas, entre las que se incluyen
                                    </p>
                                    <p>
                                        <List
                                            style={{
                                                cursor: "pointer",
                                            }}
                                        >
                                            <li
                                                onClick={() => {
                                                    window.location.href = "#fundacionLazos"
                                                }}
                                            >
                                                Fundación Lazos.
                                            </li>
                                            <li
                                                onClick={() => {
                                                    window.location.href = "#teleton"
                                                }}
                                            >
                                                Teletón.
                                            </li>
                                            <li
                                                onClick={() => {
                                                    window.location.href = "#"
                                                }}
                                            >
                                                Casa Hogar Mano Amiga.
                                            </li>
                                            <li
                                                onClick={() => {
                                                    window.location.href = "#salesiano"
                                                }}
                                            >
                                                Albergue Infantil Salesiano.
                                            </li>
                                            <li
                                                onClick={() => {
                                                    window.location.href = "#tecmonte"
                                                }}
                                            >
                                                Tecnológico de Monterrey.
                                            </li>
                                            <li>
                                                Entre otros...
                                            </li>
                                        </List>
                                    </p>
                                </Row>
                            </section>
                        </>
                }

                <Modal
                    isOpen={this.state.estaAbiertoModalImgMapa}
                    size="lg"
                    style={{
                        maxWidth: "80%",
                    }}
                >
                    <ModalHeader
                        close={
                            <IconButton
                                onClick={() => {
                                    this.setState({
                                        estaAbiertoModalImgMapa: false,
                                    });
                                }}
                            >
                                <Close />
                            </IconButton>
                        }
                    >
                        <Label
                            style={{
                                fontFamily: "'Roboto Slab', serif",
                                fontWeight: "bold",
                                fontSize: "24px",
                            }}
                        >
                            Su Impacto y Valor Social
                        </Label>
                    </ModalHeader>
                    <ModalBody
                        style={{
                            height: "80vh",
                        }}
                    >
                        <center>
                            <img
                                src={mapa}
                                alt="No se pudo cargar la imagen"
                                style={{
                                    borderRadius: "10px",
                                    width: "100%",
                                    height: "75vh",
                                    // marginTop: "-25px",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    this.setState({
                                        estaAbiertoModalImgMapa: true,
                                    });
                                }}
                            />
                        </center>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}

export default NuestroAporte;