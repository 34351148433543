import React, { useEffect, useState } from 'react';
import {
    Navbar,
    Nav,
    Button,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody
} from 'reactstrap';
import imgLogoGPMBlanco from "../../../assets/image/logo/gpmLogoBlanco.png";
import colors from "../../../config/colors.config.js";
import {
    Close,
    Menu,
} from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import NuestrasMarcas from '../../NuestrasMarcas';



const NavBarMovil = () => {

    const [estaAbiertoModalNuestrasMarcas, setEstaAbiertoModalNuestrasMarcas] = useState(false);
    const [estaAbiertoModalMisionVision, setEstaAbiertoModalMisionVision] = useState(false);
    const [device,] = useState({})
    const [isOpen, setIsOpen] = useState(false);
    const open = () => setIsOpen(!isOpen);
    const [isButtonHover] = useState({
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
    });

    useEffect(() => {
                    
    }, []);

    return (

        <div

        >
            <Navbar
                expand="md"
                style={{
                    backgroundColor: colors.gpm.verde,
                    height: "62px",
                    width: "100%"
                }}
            >
                <Nav className="ml-auto" expand="md" navbar style={{ marginTop: "-5px" }}>
                </Nav>
                <Button
                    //color="primary"
                    style={{ backgroundColor: "#000", borderColor: "#fff", boxShadow: "none", color: "#016390", }}
                    onClick={open}
                >
                    <Menu style={{ color: "#016390", height:10 }} />
                </Button>
                <Offcanvas
                    toggle={open}
                    isOpen={isOpen}
                    direction="end"
                    style={{
                        backgroundColor: "#fff"
                    }}
                >
                    <OffcanvasHeader
                        toggle={open}
                    >
                        <label
                            style={{
                                color: colors.gpm.verde,
                                fontWeight: "bold",
                                fontSize: "36px"
                            }}
                        >
                            Menu
                        </label>
                    </OffcanvasHeader>
                    <OffcanvasBody>
                        <Nav vertical>

                            <Row>
                                <Col>
                                    <Button
                                        style={{
                                            marginLeft: "5px",
                                            backgroundColor: colors.gpm.blanco,
                                            color: colors.gpm.verde,
                                            boxShadow: "none",
                                            fontWeight: "bold",
                                            borderRadius: "0px 0px 0px 0px",
                                            borderColor: isButtonHover[0] ? `transparent transparent ${colors.gpm.blanco} transparent` : "transparent",
                                            fontSize: "24px"
                                        }}
                                        onClick={() => {
                                            window.location.href = "/";
                                        }}
                                    >

                                        Inicio
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    style={{
                                        textAlign: "left"
                                    }}
                                >
                                    <Button
                                        style={{
                                            marginLeft: "5px",
                                            backgroundColor: colors.gpm.blanco,
                                            color: colors.gpm.verde,
                                            boxShadow: "none",
                                            fontWeight: "bold",
                                            borderRadius: "0px 0px 0px 0px",
                                            borderColor: isButtonHover[1] ? `transparent transparent ${colors.gpm.blanco} transparent` : "transparent",
                                            fontSize: "24px"
                                        }}
                                        onClick={() => {
                                            setEstaAbiertoModalNuestrasMarcas(true);
                                        }}
                                    >
                                        Nuestras Marcas
                                    </Button>

                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    style={{
                                        textAlign: "left"
                                    }}
                                >
                                    <Button
                                        style={{
                                            marginLeft: "5px",
                                            backgroundColor: colors.gpm.blanco,
                                            color: colors.gpm.verde,
                                            boxShadow: "none",
                                            fontWeight: "bold",
                                            borderRadius: "0px 0px 0px 0px",
                                            borderColor: isButtonHover[2] ? `transparent transparent ${colors.gpm.blanco} transparent` : "transparent",
                                            fontSize: "24px"
                                        }}
                                        onClick={() => {
                                            setEstaAbiertoModalMisionVision(true);
                                        }}
                                    >
                                        Misión Vision
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    style={{
                                        textAlign: "left"
                                    }}
                                >
                                    <Button
                                        style={{
                                            marginLeft: "5px",
                                            backgroundColor: colors.gpm.blanco,
                                            color: colors.gpm.verde,
                                            boxShadow: "none",
                                            fontWeight: "bold",
                                            borderRadius: "0px 0px 0px 0px",
                                            borderColor: isButtonHover[2] ? `transparent transparent ${colors.gpm.blanco} transparent` : "transparent",
                                            fontSize: "24px"
                                        }}
                                        onClick={() => {
                                            window.location.href = "/nuestra-historia";
                                        }}
                                    >
                                        Nuestra Historia
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    style={{
                                        textAlign: "left"
                                    }}
                                >
                                    <Button
                                        style={{
                                            marginLeft: "5px",
                                            backgroundColor: colors.gpm.blanco,
                                            color: colors.gpm.verde,
                                            boxShadow: "none",
                                            fontWeight: "bold",
                                            borderRadius: "0px 0px 0px 0px",
                                            borderColor: isButtonHover[2] ? `transparent transparent ${colors.gpm.blanco} transparent` : "transparent",
                                            fontSize: "24px"
                                        }}
                                        onClick={() => {
                                            window.location.href = "/nuestros-logros";
                                        }}
                                    >
                                        Nuestros Logros
                                    </Button>
                                </Col>
                            </Row>
                        </Nav>

                    </OffcanvasBody>

                </Offcanvas>
            </Navbar>
            <Modal
                isOpen={estaAbiertoModalNuestrasMarcas}
                size={device.isDesktopDevice ? "lg" : ""}
                style={{
                    maxWidth: device.isDesktopDevice ? "80%" : ""
                }}
            >
                <ModalHeader
                    close={
                        <IconButton
                            onClick={() => {
                                setEstaAbiertoModalNuestrasMarcas(false);
                            }}
                        >
                            <Close
                                style={{
                                    color: colors.gpm.blanco,
                                    fontWeight: "bold"
                                }}
                            />
                        </IconButton>
                    }
                    style={{
                        backgroundColor: colors.gpm.verde,
                        color: colors.gpm.blanco,
                    }}
                >
                    <Row>
                        <Col
                            style={{
                                maxWidth: "20%"
                            }}
                        >
                            <img
                                src={imgLogoGPMBlanco}
                                alt=""
                                height="50px"
                            />
                        </Col>
                        <Col
                            style={{
                                maxWidth: "80%"
                            }}
                        >
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "28px",
                                    marginTop: "7px",

                                }}
                            >
                                Nuestras Marcas
                            </Label>
                        </Col>
                    </Row>

                </ModalHeader>
                <ModalBody>
                    <NuestrasMarcas device={device} />
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={() => {
                            setEstaAbiertoModalNuestrasMarcas(false)
                        }}
                        style={{
                            backgroundColor: colors.gpm.cafe,
                            fontWeight: "bold",
                            boxShadow: "none"
                        }}
                    >
                        Cerrar
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal
                isOpen={estaAbiertoModalMisionVision}
                size={device.isDesktopDevice ? "lg" : ""}
                style={{
                    maxWidth: device.isDesktopDevice ? "80%" : ""
                }}
            >
                <ModalHeader
                    close={
                        <IconButton
                            onClick={() => {
                                setEstaAbiertoModalMisionVision(false);
                            }}
                        >
                            <Close
                                style={{
                                    color: colors.gpm.blanco,
                                    fontWeight: "bold"
                                }}
                            />
                        </IconButton>
                    }
                    style={{
                        backgroundColor: colors.gpm.verde,
                        color: colors.gpm.blanco,
                    }}
                >
                    <Row>
                        <Col
                            style={{
                                maxWidth: "20%"
                            }}
                        >
                            <img
                                src={imgLogoGPMBlanco}
                                alt=""
                                height="50px"
                            />
                        </Col>
                        <Col
                            style={{
                                maxWidth: "80%"
                            }}
                        >
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "28px",
                                    marginTop: "7px",
                                    marginRight: "15px"
                                }}
                            >
                                Misión Vision
                            </Label>
                        </Col>
                    </Row>

                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col
                            style={{
                                maxWidth: "50%"
                            }}
                        >
                            <center>
                                <lottie-player
                                    autoplay
                                    loop
                                    mode="normal"
                                    src="https://assets8.lottiefiles.com/packages/lf20_AHZ1JluK8F.json"
                                    speed={0.5}
                                    style={{
                                        width: "200px",
                                        height: "200px",
                                        // marginTop: "40px"
                                    }}
                                />
                            </center>
                        </Col>
                        <Col
                            style={{
                                maxWidth: "50%",
                                textAlign: "center"
                            }}
                        >
                            <p
                                style={{
                                    color: colors.gpm.cafe,
                                    fontWeight: "bold",
                                    fontSize: "18px",
                                    marginTop: "100px"
                                }}
                            >
                                OPERACIONES WOW
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col
                            style={{
                                maxWidth: "50%",
                                textAlign: "center"
                            }}
                        >
                            <p
                                style={{
                                    color: colors.gpm.cafe,
                                    fontWeight: "bold",
                                    fontSize: "18px",
                                    marginTop: "100px"
                                }}
                            >
                                Respeto, Honestidad, Confianza, <br /> Actitud Positiva, Puntualidad,
                            </p>
                        </Col>
                        <Col
                            style={{
                                maxWidth: "50%"
                            }}
                        >
                            <center>
                                <lottie-player
                                    autoplay
                                    loop
                                    mode="normal"
                                    src="https://assets10.lottiefiles.com/packages/lf20_FAHP63f8vG.json"
                                    speed={0.5}
                                    style={{
                                        width: "200px",
                                        height: "200px",
                                        // marginTop: "40px"
                                    }}
                                />
                            </center>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={() => {
                            setEstaAbiertoModalMisionVision(false)
                        }}
                        style={{
                            backgroundColor: colors.gpm.cafe,
                            fontWeight: "bold",
                            boxShadow: "none"
                        }}
                    >
                        Cerrar
                    </Button>
                </ModalFooter>
            </Modal>
        </div >


    );
}

export default NavBarMovil;