import { useEffect } from "react";
import NavBarComputer from "./NavBarComputer";
import NavBarMovil from "./NavBarMovil";
// import userModel from "../models/user.model";

const NavBar = (props) => {
   
    useEffect(() => {
        
    }, []);
    
    return (
        
        <>
            {props.device.isDesktopDevice ?
                <NavBarComputer/>
                :
                <NavBarMovil/>
            }
        </>
    );
}

export default NavBar;