const Colors = {
    gpm: {
        verde: "#129A40",
        verdeSuave: "#20BE55",
        verdeSuave_1: "#33E06D",
        cafe: "#704D3C",
        blanco: "#FFFFFF",
        blanco_1: "#F4F4F4",
        celeste: "#4AB5E2",
        azul: "#0D6A8E",
        azul_1: "#1179AC",
        azul_2: "#00577B",
        naranja: "#F99326",
        negro: "#000000",
    },
    arbys: {
        rojo: "#D71920",
        blanco: "#FFFFFF",
        negro: "#000000"
    },
    sushiitto: {
        rojo: "#E61E45",
        morado: "#5C305E",
        blanco: "#FFFFFF",
        grisClaro: "#A1AEB7",
        grisOscuro: "#A09E9A",
    },
    Dominos: {
        azulClaro: "#006491",
        azulFuerte: "#005074",
        rojo: "#D31837",
        blanco01: "#F6F6F6",
        negro: "#000000"
    },
    halloween : {
        morado : "#48207C",
        naranja : "#FF7300",
        blanco : "#FFFFFF",
        gris1 : "#E9E6E1",
        gris : "#D3CEC8",
    }

}

export default Colors;