import { Component } from "react";
import { Nav, Navbar, Modal, Button, ModalBody, ModalHeader, Row, Col, Label, FormGroup, Input, ListGroup, ListGroupItem } from "reactstrap";
import colors from "../../../config/colors.config";
import imgLogoGPMBlanco from "../../../assets/image/logo/gpmLogoBlanco.png";
import imgCalabaza from "../../../assets/image/calabaza.png";
import { Business, Close, EmojiEvents, Favorite, Home, HomeWork, HowToReg, Menu, Person, Store } from "@material-ui/icons";
import { Handshake, } from '@mui/icons-material';

import Slide from 'react-reveal/Slide';
import components from "../../../tools/components.tool";
import { Zoom, Fade } from "react-reveal";

class SNavBarComputer extends Component {


    constructor(props) {
        super(props);
        this.state = {
            isFirtTouch: true,
            isOpenModal: false,
            indexMenu: 0,
            device: {
                isDesktopDevice: null,
                isMobileDevice: null,
                screenHeight: null,
                screenWidth: null,
            },
            arrayButtons: {
                0: true,
                1: true,
                2: true,
                3: true,
                4: true,
                5: true,
                6: true,
            }
        }
    }

    componentDidMount() {
        this.cargarDatosPantalla();
    }

    cargarDatosPantalla = () => {


        components.sleep(0.2).then(() => {
            this.setState({
                arrayButtons: {
                    ...this.state.arrayButtons,
                    0: false,
                }
            });
        });
        components.sleep(0.4).then(() => {
            this.setState({
                arrayButtons: {
                    ...this.state.arrayButtons,
                    1: false,
                }
            });
        });
        components.sleep(0.6).then(() => {
            this.setState({
                arrayButtons: {
                    ...this.state.arrayButtons,
                    2: false,
                }
            });
        });
        components.sleep(0.8).then(() => {
            this.setState({
                arrayButtons: {
                    ...this.state.arrayButtons,
                    3: false,
                }
            });
        });
        components.sleep(1).then(() => {
            this.setState({
                arrayButtons: {
                    ...this.state.arrayButtons,
                    4: false,
                }
            });
        });
        components.sleep(1.2).then(() => {
            this.setState({
                arrayButtons: {
                    ...this.state.arrayButtons,
                    5: false,
                }
            });
        });
        components.sleep(1.5).then(() => {
            this.setState({
                arrayButtons: {
                    ...this.state.arrayButtons,
                    6: false,
                }
            });
        });
    }

    openCloseModal = () => {
        this.setState({
            isOpenModal: !this.state.isOpenModal,
            arrayButtons: {
                0: true,
                1: true,
                2: true,
                3: true,
                4: true,
                5: true,
                6: true,
            }
        });
    }

    render() {
        const { clickOnHome } = this.props;
        const { isMain } = this.props;
        return (
            <>

                <Navbar
                    expand="md"
                    style={{
                        position: "fixed",
                        top: 0,
                        width: "100%",
                        zIndex: "100",
                        fontFamily: "monospace",
                        backgroundColor: colors.gpm.blanco,
                        height: "70px",
                    }}>
                    <Slide top >

                        <Nav className="ml-auto" expand="md" navbar>
                            {/* <img
                                src={imgCalabaza}
                                alt="No imagen"
                                height={50}
                                style={{
                                    marginLeft: "200px",
                                    cursor: "pointer",
                                    marginTop: "10px"
                                }}
                                onClick={() => {
                                    window.location.href = "/"
                                }}
                            /> */}
                            <img
                                src={imgLogoGPMBlanco}
                                alt="No imagen"
                                height={50}
                                style={{
                                    //marginLeft: "200px",
                                    cursor: "pointer",
                                    marginTop: "10px"
                                }}
                                onClick={() => {
                                    window.location.href = "/"
                                }}
                            />
                            <Label
                                style={{
                                    color: colors.gpm.azul,
                                    // color:"#086D4D",
                                    fontWeight: "bold",
                                    fontSize: "40px",
                                    marginLeft: "1px",
                                    marginTop: "10px",
                                    fontFamily: "'Oswald', sans-serif, 'Roboto Slab', serif",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    window.location.href = "/"
                                }}
                            >
                                GPM
                            </Label>
                            {/* <Label
                                style={{
                                    // color: colors.gpm.azul,
                                    color: colors.halloween.morado,
                                    fontWeight: "bold",
                                    fontSize: "40px",
                                    marginLeft: "1px",
                                    marginTop: "10px",
                                    fontFamily: "'Oswald', sans-serif, 'Roboto Slab', serif",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    window.location.href = "/"
                                }}
                            >
                                G
                            </Label>
                            <Label
                                style={{
                                    // color: colors.gpm.azul,
                                    color:"#FFF",
                                    textShadow: "-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black",
                                    fontWeight: "bold",
                                    fontSize: "40px",
                                    marginLeft: "1px",
                                    marginTop: "10px",
                                    fontFamily: "'Oswald', sans-serif, 'Roboto Slab', serif",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    window.location.href = "/"
                                }}
                            >
                                P
                            </Label>
                            <Label
                                style={{
                                    // color: colors.gpm.azul,
                                    color: colors.halloween.naranja,                                 
                                    fontWeight: "bold",
                                    fontSize: "40px",
                                    marginLeft: "1px",
                                    marginTop: "10px",
                                    fontFamily: "'Oswald', sans-serif, 'Roboto Slab', serif",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    window.location.href = "/"
                                }}
                            >
                                M
                            </Label> */}
                        </Nav>

                        <Row>
                            <Col
                                style={{
                                    textAlign: "right"
                                }}
                            >

                                <Button

                                    style={{
                                        boxShadow: "none",
                                        backgroundColor: "transparent",
                                        borderColor: "transparent",
                                        marginTop: "10px",
                                    }}

                                    onClick={() => {
                                        console.log(isMain);
                                        if (isMain === true) {
                                            clickOnHome();
                                        } else {
                                            window.location.href = "/"
                                        }


                                    }}
                                    title="Ir al Inicio"
                                >
                                    <Home
                                        style={{
                                            height: "50px",
                                            width: "50px",
                                            color: colors.gpm.azul
                                        }}
                                    />
                                </Button>

                                <Button
                                    style={{
                                        boxShadow: "none",
                                        backgroundColor: "transparent",
                                        borderColor: "transparent",
                                        marginTop: "10px",
                                    }}
                                    onClick={() => {
                                        this.openCloseModal();
                                        this.cargarDatosPantalla()

                                    }}
                                    title="Ver Menu"
                                >

                                    <Menu
                                        style={{
                                            height: "50px",
                                            width: "50px",
                                            color: colors.gpm.azul
                                        }}
                                    />


                                </Button>

                            </Col>
                        </Row>
                    </Slide>
                </Navbar >

                <Modal
                    isOpen={this.state.isOpenModal}
                    // size="lg"
                    // style={{
                    //     maxWidth: "100%",
                    //     height: "100vh",
                    //     marginTop:"70px"
                    // }}
                    fullscreen
                    style={{
                        backgroundColor: "transparent",
                        // opacity: 0.9
                    }}
                >
                    <ModalHeader
                        style={{
                            backgroundColor: "#274157"
                        }}
                        close={
                            <Slide right >
                                <Button
                                    style={{
                                        boxShadow: "none",
                                        backgroundColor: "transparent",
                                        borderColor: "transparent",
                                        marginRight: "10px"
                                    }}
                                    onClick={() => {
                                        this.openCloseModal();
                                    }}
                                >
                                    <Close
                                        style={{
                                            height: "50px",
                                            width: "50px",
                                            color: "#FFF"
                                        }}
                                    />
                                </Button>
                            </Slide>
                        }
                    >
                        <Slide left >
                            <Row>
                                <Col>
                                    <img
                                        src={imgLogoGPMBlanco}
                                        alt="No imagen"
                                        height={50}
                                        style={{
                                            marginLeft: "200px",
                                            marginTop: "-20px",
                                            cursor: "pointer"
                                        }}
                                    />
                                    {/* </Col>
                                <Col> */}
                                    <Label
                                        style={{
                                            color: colors.gpm.blanco,
                                            fontWeight: "bold",
                                            fontSize: "40px",
                                            marginLeft: "5px",
                                            marginTop: "-10px",
                                            fontFamily: "'Oswald', sans-serif, 'Roboto Slab', serif",
                                        }}
                                    >
                                        GPM
                                    </Label>
                                </Col>
                            </Row>
                        </Slide>
                    </ModalHeader>
                    <ModalBody
                        style={{
                            backgroundColor: "#334F5A",
                        }}
                    >
                        <Row
                            style={{
                                marginRight: "10px",
                                marginLeft: "10px"
                            }}
                        >
                            <Col
                                style={{
                                    maxWidth: "70%",
                                    // backgroundColor: "red",
                                }}
                            >
                                <Row>
                                    <Col
                                        style={{
                                            maxWidth: "33%",
                                            textAlign: "center"
                                        }}
                                    >
                                        <center>
                                            {
                                                this.state.arrayButtons[0] ?
                                                    null
                                                    :
                                                    <Fade>
                                                        <Button
                                                            style={{
                                                                width: "100%",
                                                                backgroundColor: "#0D6A8E",
                                                                marginTop: "3%",
                                                                marginBottom: "3%",
                                                                fontSize: "24px",
                                                                fontWeight: "bold",
                                                                boxShadow: "none",
                                                                borderColor: "#0D6A8E",
                                                                height: "70px",
                                                            }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    isFirtTouch: false,
                                                                    indexMenu: 1
                                                                });
                                                            }}
                                                        >
                                                            <Business style={{ height: "36px", width: "36px", marginTop: "-10px" }} /> Somos GPM
                                                        </Button>
                                                    </Fade>
                                            }
                                        </center>
                                    </Col>
                                    <Col
                                        style={{
                                            maxWidth: "34%"
                                        }}
                                    >
                                        <center>
                                            {
                                                this.state.arrayButtons[1] ?
                                                    null
                                                    :
                                                    <Fade>
                                                        <Button
                                                            style={{
                                                                width: "100%",
                                                                backgroundColor: "#EAEAEA",
                                                                marginTop: "3%",
                                                                marginBottom: "3%",
                                                                fontSize: "24px",
                                                                fontWeight: "bold",
                                                                boxShadow: "none",
                                                                borderColor: "#EAEAEA",
                                                                height: "70px",
                                                                color: colors.gpm.azul,

                                                            }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    isFirtTouch: false,
                                                                    indexMenu: 2
                                                                });
                                                            }}
                                                        >
                                                            <Handshake style={{ height: "36px", width: "36px", marginTop: "-10px" }} /> Nuestro Compromiso
                                                        </Button>
                                                    </Fade>
                                            }
                                        </center>
                                    </Col>
                                    <Col
                                        style={{
                                            maxWidth: "33%"
                                        }}
                                    >
                                        <center>
                                            {
                                                this.state.arrayButtons[2] ?
                                                    null
                                                    :
                                                    <Fade>
                                                        <Button
                                                            style={{
                                                                width: "100%",
                                                                backgroundColor: "#B8D1DB",
                                                                marginTop: "3%",
                                                                marginBottom: "3%",
                                                                fontSize: "24px",
                                                                fontWeight: "bold",
                                                                boxShadow: "none",
                                                                borderColor: "#B8D1DB",
                                                                height: "70px",
                                                                // marginRight:"150px",
                                                            }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    isFirtTouch: false,
                                                                    indexMenu: 3
                                                                });
                                                            }}
                                                        >
                                                            <Store style={{ height: "36px", width: "36px", marginTop: "-10px" }} /> Nuestras Marcas
                                                        </Button>
                                                    </Fade>
                                            }
                                        </center>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col
                                        style={{
                                            maxWidth: "33%",
                                            textAlign: "center"
                                        }}
                                    >
                                        <center>
                                            {
                                                this.state.arrayButtons[3] ?
                                                    null
                                                    :
                                                    <Fade>
                                                        <Button
                                                            style={{
                                                                width: "100%",
                                                                backgroundColor: "#4BB87A",
                                                                marginTop: "3%",
                                                                marginBottom: "3%",
                                                                fontSize: "24px",
                                                                fontWeight: "bold",
                                                                boxShadow: "none",
                                                                borderColor: "#4BB87A",
                                                                height: "70px",
                                                            }}
                                                            onClick={() => {
                                                                // this.setState({
                                                                //     isFirtTouch: false,
                                                                //     indexMenu: 4
                                                                // });
                                                                window.location.href = "/nuestros-logros";
                                                            }}
                                                        >
                                                            <EmojiEvents style={{ height: "36px", width: "36px", marginTop: "-10px" }} /> Nuestros Logros
                                                        </Button>
                                                    </Fade>
                                            }
                                        </center>
                                    </Col>
                                    <Col
                                        style={{
                                            maxWidth: "34%"
                                        }}
                                    >
                                        <center>
                                            {
                                                this.state.arrayButtons[4] ?
                                                    null
                                                    :
                                                    <Fade>
                                                        <Button
                                                            style={{
                                                                width: "100%",
                                                                backgroundColor: "#4AB5E2",
                                                                marginTop: "3%",
                                                                marginBottom: "3%",
                                                                fontSize: "24px",
                                                                fontWeight: "bold",
                                                                boxShadow: "none",
                                                                borderColor: "#4AB5E2",
                                                                height: "70px",
                                                            }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    isFirtTouch: false,
                                                                    indexMenu: 5
                                                                });
                                                            }}
                                                        >
                                                            <Person style={{ height: "36px", width: "36px", marginTop: "-10px" }} />  Empleos y Cultura
                                                        </Button>
                                                    </Fade>
                                            }
                                        </center>
                                    </Col>
                                    <Col
                                        style={{
                                            maxWidth: "33%"
                                        }}
                                    >
                                        <center>
                                            {
                                                this.state.arrayButtons[5] ?
                                                    null
                                                    :
                                                    <Fade>
                                                        <Button
                                                            style={{
                                                                width: "100%",
                                                                backgroundColor: "#043B53",
                                                                marginTop: "3%",
                                                                marginBottom: "3%",
                                                                fontSize: "24px",
                                                                fontWeight: "bold",
                                                                boxShadow: "none",
                                                                borderColor: "#043B53",
                                                                height: "70px",
                                                            }}
                                                            onClick={() => {
                                                                // this.setState({
                                                                //     isFirtTouch: false,
                                                                //     indexMenu: 6
                                                                // });
                                                                window.location.href = "/nuestro-aporte";
                                                            }}
                                                        >
                                                            <Favorite style={{ height: "36px", width: "36px", marginTop: "-10px" }} /> Nuestro Aporte
                                                        </Button>
                                                    </Fade>
                                            }
                                        </center>
                                    </Col>

                                </Row>
                                <Row>

                                    <Col
                                        style={{
                                            maxWidth: "33%"
                                        }}
                                    >
                                        <center>
                                            {
                                                this.state.arrayButtons[6] ?
                                                    null
                                                    :
                                                    <Fade>
                                                        <Button
                                                            style={{
                                                                width: "100%",
                                                                backgroundColor: "#1179AC",
                                                                marginTop: "3%",
                                                                marginBottom: "3%",
                                                                fontSize: "24px",
                                                                fontWeight: "bold",
                                                                boxShadow: "none",
                                                                borderColor: "#1179AC",
                                                                height: "70px",
                                                            }}
                                                            onClick={() => {
                                                                // this.setState({
                                                                //     isFirtTouch: false,
                                                                //     indexMenu: 6
                                                                // });
                                                                window.location.href = "/bienes-raices";
                                                            }}
                                                        >
                                                            <HomeWork style={{ height: "36px", width: "36px", marginTop: "-10px" }} /> Bienes Raíces
                                                        </Button>
                                                    </Fade>
                                            }
                                        </center>
                                    </Col>
                                </Row>
                            </Col>
                            <Col
                                style={{
                                    maxWidth: "30%",
                                    backgroundColor: this.state.isFirtTouch ? "#334F5A" : colors.gpm.blanco_1,
                                    borderRadius: "10px"
                                }}
                            >

                                {
                                    this.state.indexMenu === 1 ?
                                        <>
                                            <Fade right>
                                                <div
                                                    style={{
                                                        marginRight: "20px",
                                                        marginLeft: "20px",
                                                        marginTop: "20px",
                                                        fontWeight: "bold",
                                                        color: colors.gpm.azul,
                                                        fontSize: "22px"
                                                    }}
                                                >
                                                    <Row>
                                                        <Label
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                window.location.href = "/filosofia"
                                                            }}
                                                        >
                                                            Nuestra Filosofía
                                                        </Label>
                                                    </Row>
                                                    <Row>
                                                        <Label
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                window.location.href = "/nuestras-marcas"
                                                            }}
                                                        >
                                                            Nuestras Marcas
                                                        </Label>
                                                    </Row>
                                                    <Row>
                                                        <Label
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                window.location.href = "/nuestra-historia"
                                                            }}
                                                        >
                                                            Nuestra Historia
                                                        </Label>
                                                    </Row>
                                                    <Row>
                                                        <Label
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                window.location.href = "/enfoque-al-cliente"
                                                            }}
                                                        >
                                                            Nuestro Enfoque al Cliente
                                                        </Label>
                                                    </Row>
                                                </div>
                                            </Fade>
                                        </>
                                        :
                                        <></>
                                }
                                {
                                    this.state.indexMenu === 2 ?
                                        <Fade right>
                                            <div
                                                style={{
                                                    marginRight: "20px",
                                                    marginLeft: "20px",
                                                    marginTop: "20px",
                                                    fontWeight: "bold",
                                                    color: colors.gpm.azul,
                                                    fontSize: "22px",
                                                }}
                                            >
                                                <Row>
                                                    <Label
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                            window.location.href = "/nuestra-etica";
                                                        }}
                                                    >
                                                        Nuestra Ética
                                                    </Label>
                                                </Row>
                                                <Row>
                                                    <Label
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                            window.location.href = "/nuestros-logros";
                                                        }}
                                                    >
                                                        Nuestros Logros
                                                    </Label>
                                                </Row>
                                            </div>
                                        </Fade>
                                        :
                                        <></>
                                }
                                {
                                    this.state.indexMenu === 3 ?
                                        <Fade right>
                                            <div
                                                style={{
                                                    marginRight: "20px",
                                                    marginLeft: "20px",
                                                    marginTop: "20px",
                                                    fontWeight: "bold",
                                                    color: colors.gpm.azul,
                                                    fontSize: "22px",
                                                }}
                                            >
                                                <Row>
                                                    <Label
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                            window.location.href = "/gpm/dominos";
                                                        }}
                                                    >
                                                        Domino's GPM
                                                    </Label>
                                                </Row>
                                                <Row>
                                                    <Label
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                            window.location.href = "/gpm/arbys";
                                                        }}
                                                    >
                                                        Arby's GPM
                                                    </Label>
                                                </Row>
                                                <Row>
                                                    <Label
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                            window.location.href = "/gpm/sushiitto";
                                                        }}
                                                    >
                                                        SushiItto GPM
                                                    </Label>
                                                </Row>
                                            </div>
                                        </Fade>
                                        :
                                        <></>
                                }
                                {
                                    this.state.indexMenu === 5 ?
                                        <Fade right>
                                            <div
                                                style={{
                                                    marginRight: "20px",
                                                    marginLeft: "20px",
                                                    marginTop: "20px",
                                                    fontWeight: "bold",
                                                    color: colors.gpm.azul,
                                                    fontSize: "22px",
                                                }}
                                            >
                                                <Row>
                                                    <Label
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                            window.location.href = "/culturawow";
                                                        }}
                                                    >
                                                        Cultura WOW
                                                    </Label>
                                                </Row>
                                                <Row>
                                                    <Label
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                            window.location.href = "https://postulate.dominosgpm.com.mx";
                                                        }}
                                                    >
                                                        Postulate en Domino's GPM
                                                    </Label>
                                                </Row>
                                                <Row>
                                                    <Label
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                            // window.location.href = "#";
                                                        }}
                                                    >
                                                        Postulate en Arby's GPM
                                                    </Label>
                                                </Row>
                                                <Row>
                                                    <Label
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                            // window.location.href = "#";
                                                        }}
                                                    >
                                                        Postulate SushiItto GPM
                                                    </Label>
                                                </Row>
                                            </div>
                                        </Fade>
                                        :
                                        <></>
                                }

                            </Col>
                        </Row>


                    </ModalBody>
                </Modal>
            </>
        )
    }
}

export default SNavBarComputer;