import { Component } from "react";
import SNavBarComputer from "./NavBar/GPM/SNavBarComputer";
import colors from "../config/colors.config";
import { Button, Col, Label, Popover, PopoverBody, PopoverHeader, Row, Accordion, AccordionItem, AccordionHeader, AccordionBody, DropdownItem } from "reactstrap";
import { Zoom } from "react-reveal";

import imgUnach from "../assets/image/imgUnach.png";
import susshiItto from "../assets/image/susshiItto.jpg";
import arbys_1 from "../assets/image/arbys_1.jpg";
import arbys_2 from "../assets/image/arbys_2.jpg";
import empresario_2 from "../assets/image/empresario_2.jpg";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import SNavBarMovil from "./NavBar/GPM/SNavBarMovil";
import Block from "../tools/Block";

class NuestraHistoria extends Component {

    constructor(props) {
        super(props);
        this.state = {
            device: {
                isDesktopDevice: null,
                isMobileDevice: null,
                screenHeight: null,
                screenWidth: null,
            },
            subOpen: false,
            indexArrayImagenes: 0,
            arrayImagenes: {
                0: imgUnach,
                1: susshiItto,
                2: arbys_1,
                3: arbys_2,
                4: empresario_2
            },
            indexArrayHistoria: 0,
            arrayHIstoria: [
                {
                    ano: 1992,
                    arrayText: [
                        {
                            mes: "Febrero 8",
                            text: "Se abre la primera unidad del grupo en Tuxtla Gutierrez, Domino's BOULEVARD, contaba con mas de 40 teams y fue la primer tienda Fast Food en la ciudad"
                        },
                    ]
                },
                {
                    ano: 1993,
                    arrayText: [
                        {
                            mes: "Mayo 17",
                            text: "Domino's Pizza México consiguió lo que a estados unidos lo llevo 15 años: abrir mas de 100 unidades, Domino's Mexico abrió su unidad 100, inaugurada simbólicamente por Tom Monaghan",
                        },
                    ]
                },
                {
                    ano: 1994,
                    arrayText: [
                        {
                            mes: "Marzo 28",
                            text: "Fue la apertura del primer Pizzazz! en la ciudad de Tuxtla Gutierrez Domino's Plaza Crystal. Este modelo de unidad ofrece el producto para su consumo en el punto de venta, años mas tarde (1997) se conoce como el concepto EXPRESS",
                        },
                    ]
                },
                {
                    ano: 1995,
                    arrayText: [
                        {
                            mes: "Mayo 1",
                            text: "Se lanzo por primera vez la original promoción nacional martes 2x1. El origen de esta se hace constituido en una verdadera leyenda entre la gente de domino's. Posicionando al martes como el dia de la Domino's, aportación de México al sistema de Domino's Internacional",
                        },
                    ]
                },
                {
                    ano: 1999,
                    arrayText: [
                        {
                            mes: "Octubre 8",
                            text: "Se llevo la inauguración de la unidad Cinépolis Villahermosa, entrando al siglo XX operando con 12 unidades ubicadas en los cuatro estados del sureste de la republica (Chiapas, tabasco, Oaxaca y Sur de Veracruz)",
                        },
                    ]
                },
                {
                    ano: 2004,
                    arrayText: [
                        {
                            mes: " ",
                            text: "Primeros 15 años de operaciones en México los cuales se coronaron con la apertura de la unidad numero 500 del sistema en el parque de diversiones Six Flags México, con lo que el país se confirmo como el segundo mercado mas grande del mundo para la empresa líder en entrega de pizza a domicilio",
                        },
                    ]
                },
                {
                    ano: 2005,
                    arrayText: [
                        {
                            mes: " ",
                            text: "Se crea la promoción de jueves grandes posicionando y teniendo el miso éxito del ya tradicional martes 2x1",
                        },
                    ]
                },
                {
                    ano: 2006,
                    arrayText: [
                        {
                            mes: "Diciembre 14",
                            text: "Se llevo a cabo la apertura de la UDN centro de Tuxtla, siendo esta la primer unidad Dinning, reinventando el modelo express teniendo bajo este concepto, area de comensales Domino's",
                        },
                    ]
                },
                {
                    ano: 2007,
                    arrayText: [
                        {
                            mes: "Febrero 11",
                            text: "Se inaugura la primera unidad 20/ 20 que cuenta con area de juegos",
                        },
                    ]
                },
                {
                    ano: 2008,
                    arrayText: [
                        {
                            mes: " ",
                            text: `Se crea el programa "La hora del niño" que consiste en regalar Domino's  de 6" y refrescos para consentir a nuestros clientes mas pequeños, durante una hora en el mes de abril logrando mas de 15,000 sonrisas año con año`,
                        },
                    ]
                },
                {
                    ano: 2009,
                    arrayText: [
                        {
                            mes: " ",
                            text: `Tras dieciséis años de trabajo y dedicación se logro el premio de mayor prestigio en todos los sistemas de Domino's: Chairman Circle Hall Of Fame, solo doce lideres han logrado este reconocimiento durante todas las historias de la marca y ahora forma parte de nuestra vitrina de trofeos`,
                        },
                    ]
                },
                {
                    ano: 2016,
                    arrayText: [
                        {
                            mes: " ",
                            text: `Visita de J. Patrick Doyle CEO de Domino's Pizza a nivel internacional un logro WOW para grupo GPM`,
                        },
                    ]
                },
                {
                    ano: 2018,
                    arrayText: [
                        {
                            mes: " ",
                            text: `Se logra uno de los objetivos operacionales del 9 Gol Franny ademas de ser distinguido como la primera organización del estado de Chiapas con el certificado de Great Place To Work`,
                        },
                    ]
                },
                {
                    ano: 2020,
                    arrayText: [
                        {
                            mes: " ",
                            text: `Domino's Pizza tiene 60 años y es reconocido como el líder mundial de entregas de pizza a domicilio,
                                  estando presente en 89 países del mundo contando con mas de 14,500 unidades. Domino's ademas de desarrollo 
                                  diversas plataformas online que generan el pedido mas de $4.7 Billones de ventas digitales anualmente`,
                        },
                    ]
                }, {
                    ano: 2023,
                    arrayText: [
                        {
                            mes: " ",
                            text: `Actualmente tenemos operando 40 unidades de negocio (Domino's, Arby's, sushiItto) distribuidas en el 
                                   sureste del país, los distintos reconocimientos del grupo por sus operaciones en el ámbito nacional 
                                   e internacional se reflejan en los 9 premios Gold Franny como la mejor franquicia en el sistema de 
                                   Domino's en el mundo con numerosos premios en ventas, desarrollo de marca, calidad de operaciones,
                                   entre otros`,
                        },
                    ]
                },

            ],
            open: '',
        }
    }

    componentDidMount() {
        this.cargarDatosPantalla();
    }

    cargarDatosPantalla = () => {

        let { device } = this.props

        this.setState({
            device
        });

    }

    toggle = (id) => {



        if (this.state.open === id) {
            this.setState({
                open: ''
            });
        } else {
            this.setState({
                open: id
            });
        }
        console.log(this.state.open);
    };


    render() {
        return (
            <>
                {this.state.device.isDesktopDevice ? <SNavBarComputer clickOnHome={null} isMain={false} /> : <SNavBarMovil/>}
                
                <section
                    style={{
                        width: "100%",
                        height: this.state.device.isDesktopDevice ? "50vh" : "25vh",
                        transition: "background-image 1s ease-in-out",
                        fontFamily: "'Roboto Slab', serif",
                        backgroundColor: colors.gpm.azul,
                        backgroundImage: `url(${empresario_2})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                    }}
                >                    
                    <div style={{ height: this.state.device.isDesktopDevice ? "25vh" : "80px" }} ></div>
                    <Row
                        style={{
                            marginRight: this.state.device.isDesktopDevice ? "15%" : "10px",
                            marginLeft: this.state.device.isDesktopDevice ? "15%" : "10px",
                            backgroundColor: 'rgba(255, 255, 255, 0.15)',
                            borderRadius: '10px',
                            backdropFilter: 'blur(5px)',
                            padding: '20px',
                        }}
                    >
                        <center>
                            <Label
                                style={{
                                    fontFamily: "'Roboto Slab', serif",
                                    color: colors.gpm.blanco,
                                    fontWeight: "bold",
                                    fontSize: "30px"
                                }}
                            >
                                Nuestra Historia
                            </Label>
                        </center>
                    </Row>
                </section>
                <section
                    style={{
                        width: "100%",
                        height: this.state.open === '' ? "150vh" : "auto",
                        // height: "auto",
                        transition: "background-image 1s ease-in-out",
                        fontFamily: "'Roboto Slab', serif",
                        backgroundColor: colors.gpm.azul,
                        display: "inline-block",
                    }}
                >
                    <div style={{ height: "10px" }} ></div>
                    <Row
                        style={{
                            marginRight: this.state.device.isDesktopDevice ? "15%" : "10px",
                            marginLeft: this.state.device.isDesktopDevice ? "15%" : "10px",
                        }}

                    >
                        <Accordion
                            toggle={this.toggle}
                            open={this.state.open}
                        >
                            {
                                this.state.arrayHIstoria.map((data, index) => {
                                    let IdValue = "AccordionItem_";
                                    return (
                                        <>
                                            <div style={{ height: "10px", backgroundColor: "transparent" }} ></div>
                                            <AccordionItem
                                                id={IdValue.concat(index)}
                                                style={{
                                                    // borderRadius:"10px"
                                                }}
                                            >
                                                <AccordionHeader
                                                    targetId={index}
                                                    style={{
                                                        fontWeight: "bold"
                                                    }}
                                                >
                                                    <strong>Año {data.ano}</strong>
                                                </AccordionHeader>
                                                <AccordionBody
                                                    accordionId={index}
                                                >
                                                    {
                                                        data.arrayText.map((_data) => {
                                                            return (
                                                                <>
                                                                    <Row>
                                                                        <Label>
                                                                            {_data.mes}
                                                                        </Label>
                                                                    </Row>
                                                                    <Row>
                                                                        <p
                                                                            style={{
                                                                                textAlign: "justify"
                                                                            }}
                                                                        >
                                                                            {_data.text}
                                                                        </p>
                                                                    </Row>
                                                                    <DropdownItem divider />
                                                                </>
                                                            );

                                                        })
                                                    }

                                                </AccordionBody>
                                            </AccordionItem>
                                        </>
                                    )
                                })
                            }

                        </Accordion>
                    </Row>
                    <div style={{ height: "60px" }} ></div>
                </section>
                <section
                    style={{
                        width: "100%",
                        height: "50vh",
                        transition: "background-image 1s ease-in-out",
                        fontFamily: "'Roboto Slab', serif",
                        backgroundColor: colors.gpm.blanco,
                    }}
                >

                </section>


            </>
        );
    }

}

export default NuestraHistoria;