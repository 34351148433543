import NavBarComputer from "../NavBar/dominos/NavBarComputer";
import NavBarMovil from "../NavBar/dominos/NavBarMovil";
import colors from "../../config/colors.config";
import Screen from "../../config/screen.config";
import { useState, useEffect, useRef } from "react";
import { Button, Col, Label, Row } from "reactstrap";
import Block from "../../tools/Block";
import Confetti from 'react-confetti'
import components from "../../tools/components.tool";

/**Importación de las imágenes */
import img from "../../assets/image/dominos_4.jpg";
import dominosMania from "../../assets/image/dominos/dominosMania.jpg";
import dominosMania2 from "../../assets/image/dominos/dominosMania2.jpg";
import viva_la_mexicana from "../../assets/image/dominos/viva_la_mexicana.png"
// import SConfetti from "../components/SConfetti";
import dominosmania_1 from "../../assets/image/imgUnach.png";

/**Importación de las videos */
import video_2 from "../../assets/image/dominos/video_2.mp4";
import video_1 from "../../assets/image/dominos/video_1.mp4";

const SDominos = () => {

    const [screen, setScreen] = useState({
        isDesktopDevice: null,
        isMobileDevice: null,
        screenHeight: 0,
        screenWidth: 0,
    });
    const [showConfetti, setShowConfetti] = useState(true);
    // const [showEvent, setShowEvent] = useState(true);
    const [hoveredMouseButton, setHoveredMouseButton] = useState(false);
    const [showText, setShowText] = useState(true);
    const videoRef = useRef(null);

    useEffect(() => {

        const screen = Screen.getDeviceSettings();
        setScreen(screen);
        components.sleep(3).then(() => {
            setShowConfetti(false);
            // setShowEvent(false);
        });

        components.sleep(6).then(() => {
            setShowText(false);
        });
        const options = {
            root: null, // El viewport del navegador es el área de observación.
            rootMargin: '0px', // No hay margen alrededor del área de observación.
            threshold: 0.5, // Cuando el 50% o más del elemento es visible, se activará la reproducción.
        };

        const callback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // El elemento de video está visible en la pantalla, inicia la reproducción.
                    videoRef.current.play();
                } else {
                    // El elemento de video ya no está visible, pausa la reproducción.
                    videoRef.current.pause();
                }
            });
        };

        const observer = new IntersectionObserver(callback, options);

        // Observa el elemento de video.
        if (videoRef.current) {
            observer.observe(videoRef.current);
        }

        // Limpia el observador cuando el componente se desmonte.
        return () => {
            if (videoRef.current) {
                observer.unobserve(videoRef.current);
            }
        };
    }, []);



    return (
        <>
            {screen.isDesktopDevice ? <NavBarComputer /> : <NavBarMovil />}

            <section
                style={{
                    width: "100%",
                    height: "100vh",
                    backgroundSize: "100% 100%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    //backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)), url(${img})`,
                    backgroundImage: screen.isDesktopDevice ? `url(${img})` : "linear-gradient(to bottom, #006491, #036a98, #066f9f, #0975a6, #0c7bad, #0881b2, #0486b7, #008cbc, #0092bf, #0099c2, #009fc5, #00a5c7)",
                    fontFamily: "'Roboto Slab', serif",
                    lineHeight: 1,
                }}
            >
                {/* <Row
                    style={{
                        marginRight: "0px",
                        marginLeft: "0px"
                    }}
                >
                    <SConfetti />
                </Row> */}
                <Block height={screen.isDesktopDevice ? "20vh" : "70px"} />
                <Row
                    style={{
                        marginRight: "0px",
                        marginLeft: "0px",

                    }}
                >
                    <center>
                        <Label
                            style={{
                                borderRadius: '10px',
                                //backdropFilter: 'blur(5px)',
                                padding: "5px",
                                fontWeight: "bold",
                                fontSize: "48px",
                                color: colors.Dominos.blanco01,
                                textShadow: "-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black",
                            }}
                        >
                            El mejor invento del hombre después <br /> de la rueda es la Domino's
                        </Label>
                    </center>
                </Row>
                <Row
                    style={{
                        marginRight: "0px",
                        marginLeft: "0px",
                        marginTop: "20px"
                    }}
                >
                    <center>
                        <Label
                            style={{
                                borderRadius: '10px',
                                //backdropFilter: 'blur(5px)',
                                padding: "5px",
                                fontWeight: "bold",
                                fontSize: "48px",
                                color: "#006490",
                                textShadow: "-0.7px -0.7px 0 #FFF, 0.7px -0.7px 0 #FFF, -0.7px 0.7px 0 #FFF, 0.7px 0.7px 0 #FFF",
                            }}
                        >
                            Domino's GMP
                        </Label>
                    </center>
                </Row>

                {/* <Row
                    style={{
                        marginRight: "0px",
                        marginLeft: "0px",
                        marginTop: "5px"
                    }}
                >
                    <center>
                        {
                            showText ?
                                <>
                                    <label
                                        style={{
                                            fontSize: "72px",
                                            color: colors.gpm.blanco,
                                            fontFamily: "'Roboto Slab', serif",
                                            color: "#086D4D",
                                            textShadow: "-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black",
                                            marginLeft: "15px",
                                            transition: "1s",
                                        }}
                                    >
                                        !Viva
                                    </label>
                                    <label
                                        style={{
                                            fontSize: "72px",
                                            fontFamily: "'Roboto Slab', serif",
                                            color: "#FFF",
                                            textShadow: "-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black",
                                            marginLeft: "15px",
                                            transition: "1s",
                                        }}
                                    >
                                        Mexico
                                    </label>
                                    <label
                                        style={{
                                            fontSize: "72px",
                                            fontFamily: "'Roboto Slab', serif",
                                            color: "#D0182D",
                                            textShadow: "-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black",
                                            marginLeft: "15px",
                                            transition: "1s",
                                        }}
                                    >
                                        Señores¡
                                    </label>
                                </>
                                :
                                <Label
                                    style={{
                                        fontSize: "48px",
                                        fontWeight: "bold",
                                        color: "#006490",
                                        fontFamily: "'Roboto Slab', serif",
                                        textShadow: "-0.7px -0.7px 0 #FFF, 0.7px -0.7px 0 #FFF, -0.7px 0.7px 0 #FFF, 0.7px 0.7px 0 #FFF",
                                    }}
                                >
                                    Grupo Penagos Meneses
                                </Label>
                        }
                    </center>
                </Row> */}
                <Row
                    style={{
                        marginRight: "0px",
                        marginLeft: "0px",
                        marginTop: "30px"
                    }}
                >
                    <center>
                        <Button
                            style={{
                                backgroundColor: colors.Dominos.rojo,
                                color: colors.Dominos.blanco01,
                                fontWeight: "bold",
                                fontSize: "24px",
                                borderRadius: "30px",
                                borderColor: colors.Dominos.blanco01,
                                boxShadow: "none",
                                transform: hoveredMouseButton ? 'scale(1.1)' : 'scale(1)',
                                transition: 'transform 0.3s ease-in-out',
                            }}
                            onClick={() => {
                                window.location.href = "/gpm/dominos/unidades";
                            }}
                            onMouseEnter={() => {
                                setHoveredMouseButton(true);
                            }}
                            onMouseLeave={() => {
                                setHoveredMouseButton(false);
                            }}
                        >
                            Conoce nuestras unidades
                        </Button>
                    </center>
                </Row>
            </section>

            <section
                style={{
                    width: "100%",
                    height: "auto",
                    fontFamily: "'Roboto Slab', serif",
                }}
            >
                {
                    screen.isDesktopDevice ?
                        <Row
                            style={{
                                position: "relative",
                                width: "100%",
                                height: "100vh",
                                overflow: "hidden",
                                marginRight: "0px",
                                marginLeft: "0px"
                            }}
                        >
                            <Col
                                style={{
                                    maxWidth: "50%"
                                }}
                            >
                                <video
                                    ref={videoRef}
                                    autoPlay
                                    muted
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        marginRight: "0px",
                                        marginLeft: "0px",
                                        width: "50%",
                                        height: "auto",
                                        objectFit: "cover",
                                    }}
                                    onMouseEnter={() => {
                                        videoRef.current.play();
                                    }}
                                >
                                    <source src={video_2} type="video/mp4" />
                                    Tu navegador no admite la reproducción de video.
                                </video>
                            </Col>
                            <Col
                                style={{
                                    maxWidth: "50%",
                                    textAlign: "center",
                                    backgroundImage: `url(${dominosmania_1})`,
                                    //backgroundAttachment: "fixed",
                                    backgroundSize: "cover",
                                    textShadow: "-0.7px -0.7px 0 #FFF, 0.7px -0.7px 0 #FFF, -0.7px 0.7px 0 #FFF, 0.7px 0.7px 0 #FFF",
                                }}
                            >
                                <Row
                                    style={{
                                        marginTop: "150px",

                                    }}
                                >
                                    <Label
                                        style={{
                                            color: colors.Dominos.azulFuerte,
                                            fontWeight: "bold",
                                            fontSize: "46px"
                                        }}
                                    >
                                        Nuestros Horarios
                                    </Label>
                                </Row>
                                <Row
                                    style={{
                                        color: colors.Dominos.azulFuerte,
                                        fontWeight: "bold",
                                        fontSize: "22px"
                                    }}
                                >
                                    <Label>
                                        Lunes 09:00 am. - 11:00 pm
                                    </Label>
                                    <br />
                                    <Label>
                                        Martes 09:00 am. - 11:00 pm
                                    </Label>
                                    <br />
                                    <Label>
                                        Miércoles 09:00 am. - 11:00 pm
                                    </Label>
                                    <br />
                                    <Label>
                                        Jueves 09:00 am. - 11:00 pm
                                    </Label>
                                    <br />
                                    <Label>
                                        Viernes 09:00 am. - 11:00 pm
                                    </Label>
                                    <br />
                                    <Label>
                                        Sábado 09:00 am. - 11:00 pm
                                    </Label>
                                    <br />
                                    <Label>
                                        Domingo 09:00 am. - 11:00 pm
                                    </Label>
                                    <br />
                                </Row>
                                <Row>
                                    <Label
                                        style={{
                                            color: colors.Dominos.azulFuerte,
                                            fontWeight: "bold",
                                            fontSize: "18px",
                                            lineHeight: 1,
                                            marginTop: "10px"
                                        }}
                                    >
                                        Los horarios de plazas son diferentes, <br /> conoce nuestras unidades y encuentra la mas cercana.
                                    </Label>
                                </Row>
                                <Row>
                                    <center>
                                        <Button
                                            style={{
                                                backgroundColor: colors.Dominos.rojo,
                                                color: colors.Dominos.blanco01,
                                                fontWeight: "bold",
                                                fontSize: "24px",
                                                borderRadius: "30px",
                                                borderColor: colors.Dominos.blanco01,
                                                boxShadow: "none",
                                                transform: hoveredMouseButton ? 'scale(1.1)' : 'scale(1)',
                                                transition: 'transform 0.3s ease-in-out',
                                                width: "auto",
                                                marginTop: "10px"
                                            }}
                                            onClick={() => {
                                                window.location.href = "/gpm/dominos/unidades";
                                            }}
                                            onMouseEnter={() => {
                                                setHoveredMouseButton(true);
                                            }}
                                            onMouseLeave={() => {
                                                setHoveredMouseButton(false);
                                            }}
                                        >
                                            {/* {hoveredMouseButton ? "Haz Click aqui" : "Conoce nuestras unidades"} */}
                                            Conoce nuestras unidades
                                        </Button>
                                    </center>
                                </Row>
                            </Col>
                        </Row>
                        :
                        <>
                            <Row
                                style={{
                                    position: "relative",
                                    width: "100%",
                                    height: "auto",
                                    overflow: "hidden",
                                    marginRight: 0,
                                    marginLeft: 0,
                                }}
                            >
                                {/* <Col
                                    style={{
                                        maxWidth: "100%"
                                    }}
                                > */}
                                <video
                                    ref={videoRef}
                                    autoPlay
                                    muted
                                    style={{
                                        // position: "absolute",
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        marginRight: "0px",
                                        marginLeft: "0px",
                                        width: "100%",
                                        height: "auto",
                                        objectFit: "cover",
                                    }}
                                    onMouseEnter={() => {
                                        videoRef.current.play();
                                    }}
                                >
                                    <source src={video_2} type="video/mp4" />
                                    Tu navegador no admite la reproducción de video.
                                </video>
                                {/* </Col> */}
                            </Row>
                            <Row
                                style={{
                                    marginRight: 0,
                                    marginLeft: 0,
                                }}
                            >
                                <Col
                                    style={{
                                        maxWidth: "100%",
                                        textAlign: "center",
                                        backgroundImage: `url(${dominosmania_1})`,
                                        //backgroundAttachment: "fixed",
                                        backgroundSize: "cover",
                                        textShadow: "-0.7px -0.7px 0 #FFF, 0.7px -0.7px 0 #FFF, -0.7px 0.7px 0 #FFF, 0.7px 0.7px 0 #FFF",
                                    }}
                                >
                                    <Row
                                        style={{
                                            marginTop: "150px",

                                        }}
                                    >
                                        <Label
                                            style={{
                                                color: colors.Dominos.azulFuerte,
                                                fontWeight: "bold",
                                                fontSize: "46px"
                                            }}
                                        >
                                            Nuestros Horarios
                                        </Label>
                                    </Row>
                                    <Row
                                        style={{
                                            color: colors.Dominos.azulFuerte,
                                            fontWeight: "bold",
                                            fontSize: "22px"
                                        }}
                                    >
                                        <Label>
                                            Lunes 09:00 am. - 11:00 pm
                                        </Label>
                                        <br />
                                        <Label>
                                            Martes 09:00 am. - 11:00 pm
                                        </Label>
                                        <br />
                                        <Label>
                                            Miércoles 09:00 am. - 11:00 pm
                                        </Label>
                                        <br />
                                        <Label>
                                            Jueves 09:00 am. - 11:00 pm
                                        </Label>
                                        <br />
                                        <Label>
                                            Viernes 09:00 am. - 11:00 pm
                                        </Label>
                                        <br />
                                        <Label>
                                            Sábado 09:00 am. - 11:00 pm
                                        </Label>
                                        <br />
                                        <Label>
                                            Domingo 09:00 am. - 11:00 pm
                                        </Label>
                                        <br />
                                    </Row>
                                    <Row>
                                        <Label
                                            style={{
                                                color: colors.Dominos.azulFuerte,
                                                fontWeight: "bold",
                                                fontSize: "18px",
                                                lineHeight: 1,
                                                marginTop: "10px"
                                            }}
                                        >
                                            Los horarios de plazas son diferentes, <br /> conoce nuestras unidades y encuentra la mas cercana.
                                        </Label>
                                    </Row>
                                    <Row>
                                        <center>
                                            <Button
                                                style={{
                                                    backgroundColor: colors.Dominos.rojo,
                                                    color: colors.Dominos.blanco01,
                                                    fontWeight: "bold",
                                                    fontSize: "24px",
                                                    borderRadius: "30px",
                                                    borderColor: colors.Dominos.blanco01,
                                                    boxShadow: "none",
                                                    transform: hoveredMouseButton ? 'scale(1.1)' : 'scale(1)',
                                                    transition: 'transform 0.3s ease-in-out',
                                                    width: "auto",
                                                    marginTop: "10px",
                                                    marginBottom: 20
                                                }}
                                                onClick={() => {
                                                    window.location.href = "/gpm/dominos/unidades";
                                                }}
                                                onMouseEnter={() => {
                                                    setHoveredMouseButton(true);
                                                }}
                                                onMouseLeave={() => {
                                                    setHoveredMouseButton(false);
                                                }}
                                            >
                                                {/* {hoveredMouseButton ? "Haz Click aqui" : "Conoce nuestras unidades"} */}
                                                Conoce nuestras unidades
                                            </Button>
                                        </center>
                                    </Row>
                                </Col>
                            </Row>
                        </>
                }
            </section>
            <section
                style={{
                    width: "100%",
                    height: "100vh"
                }}
            >
                <img
                    src={screen.isDesktopDevice ? dominosMania : dominosMania2}
                    alt="No se pudo cargar la imagen"
                    style={{
                        width: "100%",
                        height: "100vh"
                    }}
                />
            </section>
            {
                screen.isDesktopDevice ?
                    <section
                        style={{
                            width: "100%",
                            //height: "auto",
                        }}
                    >
                        <Row
                            style={{
                                position: "relative",
                                width: "100%",
                                height: "100vh",
                                overflow: "hidden",
                                marginRight: "0px",
                                marginLeft: "0px"
                            }}
                        >
                            <Col
                                style={{
                                    // maxWidth: "50%",
                                    textAlign: "center",
                                    backgroundImage: `url(${viva_la_mexicana})`,
                                    backgroundSize: "cover",
                                    textShadow: "-0.7px -0.7px 0 #FFF, 0.7px -0.7px 0 #FFF, -0.7px 0.7px 0 #FFF, 0.7px 0.7px 0 #FFF",
                                }}
                            >

                            </Col>
                            <Col
                            // style={{
                            //     maxWidth: "50%"
                            // }}
                            >

                                <video
                                    ref={videoRef}
                                    autoPlay
                                    muted
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        // left: 0,
                                        right: 0,
                                        marginRight: "0px",
                                        marginLeft: "0px",
                                        width: "50%",
                                        height: "auto",
                                        objectFit: "cover",
                                    }}
                                    onMouseEnter={() => {
                                        videoRef.current.play();
                                    }}
                                >
                                    <source src={video_1} type="video/mp4" />
                                    Tu navegador no admite la reproducción de video.
                                </video>
                            </Col>
                        </Row>
                    </section>
                    :
                    <>
                        <section
                            style={{
                                height: "auto",
                                width: "100%",
                            }}
                        >
                            <video
                                ref={videoRef}
                                autoPlay
                                muted
                                style={{
                                    // position: "absolute",
                                    top: 0,
                                    // left: 0,
                                    right: 0,
                                    marginRight: "0px",
                                    marginLeft: "0px",
                                    width: "100%",
                                    height: "auto",
                                    objectFit: "cover",
                                }}
                                onMouseEnter={() => {
                                    videoRef.current.play();
                                }}
                            >
                                <source src={video_1} type="video/mp4" />
                                Tu navegador no admite la reproducción de video.
                            </video>
                        </section>
                        <section
                            style={{
                                height: "100vh",
                                width: "100%",
                            }}
                        >
                            <img
                                src={viva_la_mexicana}
                                alt="No se pudo cargar la imagen"
                                style={{
                                    height: "100vh",
                                    width: "100%",
                                }}
                            />
                        </section>
                    </>
            }
        </>
    );
}

export default SDominos;