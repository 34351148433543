import React, {
    useEffect,
    useState,
} from 'react';
import {
    Navbar,
    Nav,
    Button,
    Row,
    Col
} from 'reactstrap';
import colors from "../../../config/colors.config.js";
import arbysLogo from "../../../assets/image/logo/arbys/arbysLogo.png";
import gpmLogoNegro from "../../../assets/image/logo/gpmLogoNegro.png";




const NavBarComputer = (props) => {


    const [isButtonHover, setButtonHover] = useState({
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
    });



    useEffect(() => {

    }, []);

    return (
        <>
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    width: "100%",
                    zIndex: "100",
                    fontFamily: "monospace",
                    boxShadow: `1px 1px 1px 1px ${colors.arbys.rojo}`,
                }}
            >
                <Navbar
                    expand="md"
                    style={{

                        backgroundColor: colors.arbys.blanco,
                        height: "62px"
                    }}>
                    <Nav className="ml-auto" expand="md" navbar style={{ marginTop: "-5px" }}>

                        <img
                            src={arbysLogo}
                            alt="No imagen"
                            height={40}
                            style={{
                                // marginRight:"5px",
                                cursor: "pointer"
                            }}
                        />

                        <Button
                            style={{
                                marginLeft: "5px",
                                backgroundColor: colors.arbys.blanco,
                                color: colors.arbys.rojo,
                                boxShadow: "none",
                                fontWeight: "bold",
                                borderRadius: "0px 0px 0px 0px",
                                borderColor: isButtonHover[1] ? `transparent transparent ${colors.arbys.rojo} transparent` : "transparent",
                                fontSize: "18px"
                            }}
                            onMouseEnter={() => {
                                setButtonHover({
                                    0: false,
                                    1: true,
                                    2: false,
                                    3: false,
                                    4: false,
                                    5: false,
                                });
                            }}
                            onMouseLeave={() => {
                                setButtonHover({
                                    0: false,
                                    1: false,
                                    2: false,
                                    3: false,
                                    4: false,
                                    5: false,
                                });
                            }}
                            onClick={() => {
                                window.location.href = "/gpm/arbys";
                            }}
                        >

                            Inicio
                        </Button>

                        <Button
                            style={{
                                marginLeft: "5px",
                                backgroundColor: colors.arbys.blanco,
                                color: colors.arbys.rojo,
                                boxShadow: "none",
                                fontWeight: "bold",
                                borderRadius: "0px 0px 0px 0px",
                                borderColor: isButtonHover[2] ? `transparent transparent ${colors.arbys.rojo} transparent` : "transparent",
                                fontSize: "18px"
                            }}
                            onMouseEnter={() => {
                                setButtonHover({
                                    0: false,
                                    1: false,
                                    2: true,
                                    3: false,
                                    4: false,
                                    5: false,
                                });
                            }}
                            onMouseLeave={() => {
                                setButtonHover({
                                    0: false,
                                    1: false,
                                    2: false,
                                    3: false,
                                    4: false,
                                    5: false,
                                });
                            }}
                            onClick={() => {
                                window.location.href = "/gpm/arbys/unidades";
                            }}
                        >
                            Nuestras unidades
                        </Button>




                    </Nav>
                    <Row>
                        <Col>
                            <Button
                                style={{
                                    marginLeft: "5px",
                                    backgroundColor: colors.arbys.blanco,
                                    color: colors.arbys.rojo,
                                    boxShadow: "none",
                                    fontWeight: "bold",
                                    borderRadius: "0px 0px 0px 0px",
                                    borderColor: isButtonHover[0] ? `transparent transparent ${colors.arbys.rojo} transparent` : "transparent",
                                    fontSize: "18px"
                                }}
                                onMouseEnter={() => {
                                    setButtonHover({
                                        0: true,
                                        1: false,
                                        2: false,
                                        3: false,
                                        4: false,
                                        5: false,
                                    });
                                }}
                                onMouseLeave={() => {
                                    setButtonHover({
                                        0: false,
                                        1: false,
                                        2: false,
                                        3: false,
                                        4: false,
                                        5: false,
                                    });
                                }}
                                onClick={() => {
                                    window.location.href = "/";
                                }}
                            >

                                <img 
                                src={gpmLogoNegro}
                                height="45px"
                                title="Volver a la pagina de GPM"
                                alt="No sed pudo cargar la imagen"
                                />
                            </Button>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col
                            style={{
                                marginRight: "50px"
                            }}
                        >
                            <IconButton
                                onClick={() => {
                                    window.open("https://www.facebook.com/profile.php?id=100087639947858", '_blank');
                                }}
                                title="Facebook"
                            >
                                <Facebook
                                    style={{
                                        color: "#097DEB",
                                    }}
                                />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    window.open("https://www.instagram.com/", '_blank');
                                }}
                                title="Instagram"
                            >
                                <Instagram
                                    style={{
                                        color: colors.arbys.rojo,
                                    }}
                                />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    window.open("https://www.youtube.com/", '_blank');
                                }}
                                title="YouTube"
                            >
                                <YouTube
                                    style={{
                                        color: colors.arbys.rojo,
                                    }}
                                />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    window.open("https://www.twitter.com/", '_blank');
                                }}
                                title="Twitter"
                            >
                                <Twitter
                                    style={{
                                        color: colors.arbys.rojo,
                                    }}
                                />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    openModalMap(true)
                                }}
                                title="Ver mapa"
                            >
                                <Room
                                    style={{
                                        color: colors.gpm.blanco,
                                    }}
                                />
                            </IconButton>
                        </Col>
                    </Row> */}
                </Navbar>
            </div>
        </>
    );
}

export default NavBarComputer;