import { Component } from "react";
import ScreenTools from "../config/screen.config";
import SNavBarComputer from "./NavBar/GPM/SNavBarComputer";
import { Col, Row, Label, DropdownItem, ModalHeader, Modal, ModalFooter, ModalBody } from "reactstrap";
import colors from "../config/colors.config";
import imgUnach from "../assets/image/imgUnach.png"
import imgFil from "../assets/image/imgFil.png"
import { Player } from '@lottiefiles/react-lottie-player';
import dominosUncah from "../assets/image/dominosUncah.jpg";
import { Fade, Zoom } from "react-reveal";
import dominosLogo from "../assets/image/logo/dominos/dominosLogo.png";
import arbysLogo from "../assets/image/logo/arbys/arbysLogo.png";
import sushiittoLogo from "../assets/image/logo/sushiItto/sushiittoLogo.png";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import logoArbys from "../assets/image/logo/arbys/arbysLogo.png";
import logoDominos from "../assets/image/logo/dominos/Recurso_1.png";
import logoSushiitto from "../assets/image/logo/sushiItto/sushiittoLogo.jpg";
import Estrategia from "../assets/image/pilares/Estrategia.png";
import Mision from "../assets/image/pilares/Mision.png";
import Vision from "../assets/image/pilares/Vision.png";
import Principios from "../assets/image/pilares/Principios.png";
import SNavBarMovil from "./NavBar/GPM/SNavBarMovil";
import Block from "../tools/Block";

class FilosofiaGPM extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fontWeight: "",
            estaAbiertoModalMarcas: false,
            isHoverLogoArbys: false,
            isHoverLogoDominos: false,
            isHoverLogoSushiitto: false,
            device: {
                isDesktopDevice: null,
                isMobileDevice: null,
                screenHeight: null,
                screenWidth: null,
            },
        }
    }

    componentDidMount() {
        this.cargarDatosPantalla();
    }

    cargarDatosPantalla = () => {

        let device = ScreenTools.getDeviceSettings()

        console.log(device);
        this.setState({
            device
        });
    }

    render() {
        return (
            <>
                {this.state.device.isDesktopDevice ? <SNavBarComputer clickOnHome={null} isMain={false} /> : <SNavBarMovil />}

                {
                    this.state.device.isDesktopDevice ?

                        <>

                            <section
                                id="section"
                                style={{
                                    width: "100%",
                                    height: "90vh",
                                    backgroundColor: colors.gpm.azul,
                                    color: colors.gpm.cafe,
                                    fontFamily: "'Roboto Slab', serif",
                                }}
                            >
                                <div style={{ height: "90px" }} ></div>
                                <Zoom>
                                    <Row
                                        style={{
                                            marginRight: "50px",
                                            marginLeft: "50px",
                                        }}
                                    >
                                        <Col

                                            style={{
                                                maxWidth: "22%",
                                                backgroundColor: "#40B4E5",
                                                borderRadius: "10px",
                                                borderWidth: "6px",
                                                borderStyle: "solid",
                                                borderColor: "white",
                                                marginRight: "4%"
                                            }}
                                        >
                                            <Row>
                                                <center>
                                                    <img
                                                        src={Vision}
                                                        style={{
                                                            width: "180px",
                                                            height: "220px",
                                                            marginTop: "20px",
                                                            borderRadius: "10px"
                                                        }}
                                                    />
                                                </center>
                                            </Row>
                                            <Row>
                                                <center>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                            fontSize: "26px",
                                                            color: colors.gpm.blanco,
                                                            marginTop: "5px"
                                                        }}
                                                    >
                                                        Vision
                                                    </Label>
                                                </center>
                                            </Row>
                                            <div style={{ height: "2px", backgroundColor: colors.gpm.blanco, }} ></div>
                                            <div style={{ height: "10px", }} ></div>
                                            <Row>
                                                <center>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                            fontSize: "22px",
                                                            color: colors.gpm.blanco,

                                                        }}
                                                    >
                                                        Gente WOW en la misión de ser la franquicia Dominante #1 de Domino's, Arby's y Sushi Itto en todo el mundo
                                                    </Label>
                                                </center>
                                            </Row>

                                        </Col>

                                        <Col

                                            style={{
                                                maxWidth: "22%",
                                                backgroundColor: "#FCE444",
                                                borderRadius: "10px",
                                                borderWidth: "6px",
                                                borderStyle: "solid",
                                                borderColor: "white",
                                                marginRight: "4%"
                                            }}
                                        >
                                            <Row>
                                                <center>
                                                    <img
                                                        src={Mision}
                                                        style={{
                                                            width: "180px",
                                                            height: "220px",
                                                            marginTop: "20px",
                                                            borderRadius: "10px"
                                                        }}
                                                    />
                                                </center>
                                            </Row>
                                            <Row>
                                                <center>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                            fontSize: "26px",
                                                            color: colors.gpm.azul,
                                                            marginTop: "5px"
                                                        }}
                                                    >
                                                        Misión
                                                    </Label>
                                                </center>
                                            </Row>
                                            <div style={{ height: "2px", backgroundColor: colors.gpm.azul, }} ></div>
                                            <div style={{ height: "10px", }} ></div>
                                            <Row>
                                                <center>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                            fontSize: "22px",
                                                            color: colors.gpm.azul,

                                                        }}
                                                    >
                                                        OPERACIONES WOW
                                                    </Label>
                                                </center>
                                            </Row>
                                            <Row>
                                                <center>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                            fontSize: "22px",
                                                            color: colors.gpm.azul,

                                                        }}
                                                    >

                                                    </Label>
                                                </center>
                                            </Row>
                                            <Row>
                                                <center>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                            fontSize: "22px",
                                                            color: colors.gpm.azul,

                                                        }}
                                                    >

                                                    </Label>
                                                </center>
                                            </Row>
                                            <Row>
                                                <center>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                            fontSize: "22px",
                                                            color: colors.gpm.azul,

                                                        }}
                                                    >

                                                    </Label>
                                                </center>
                                            </Row>
                                        </Col>

                                        <Col

                                            style={{
                                                maxWidth: "22%",
                                                backgroundColor: "#FF6C36",
                                                borderRadius: "10px",
                                                borderWidth: "6px",
                                                borderStyle: "solid",
                                                borderColor: "white",
                                                marginRight: "4%"
                                            }}
                                        >
                                            <Row>
                                                <center>
                                                    <img
                                                        src={Estrategia}
                                                        style={{
                                                            width: "180px",
                                                            height: "220px",
                                                            marginTop: "20px",
                                                            borderRadius: "10px"
                                                        }}
                                                    />
                                                </center>
                                            </Row>
                                            <Row>
                                                <center>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                            fontSize: "26px",
                                                            color: colors.gpm.blanco,
                                                            marginTop: "5px"
                                                        }}
                                                    >
                                                        Estrategia
                                                    </Label>
                                                </center>
                                            </Row>
                                            <div style={{ height: "2px", backgroundColor: colors.gpm.blanco, }} ></div>
                                            <div style={{ height: "10px", }} ></div>
                                            <Row>
                                                <center>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                            fontSize: "22px",
                                                            color: colors.gpm.blanco,

                                                        }}
                                                    >
                                                        Gente WOW
                                                    </Label>
                                                </center>
                                            </Row>
                                            <Row>
                                                <center>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                            fontSize: "22px",
                                                            color: colors.gpm.blanco,

                                                        }}
                                                    >
                                                        Estándares WOW
                                                    </Label>
                                                </center>
                                            </Row>
                                            <Row>
                                                <center>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                            fontSize: "22px",
                                                            color: colors.gpm.blanco,

                                                        }}
                                                    >
                                                        Clientes WOW
                                                    </Label>
                                                </center>
                                            </Row>
                                            <Row>
                                                <center>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                            fontSize: "22px",
                                                            color: colors.gpm.blanco,

                                                        }}
                                                    >
                                                        Rentabilidad WOW
                                                    </Label>
                                                </center>
                                            </Row>
                                        </Col>

                                        <Col

                                            style={{
                                                maxWidth: "22%",
                                                backgroundColor: "#9794D2",
                                                borderRadius: "10px",
                                                borderWidth: "6px",
                                                borderStyle: "solid",
                                                borderColor: "white",
                                                // marginRight: "4%"
                                            }}
                                        >
                                            <Row>
                                                <center>
                                                    <img
                                                        src={Principios}
                                                        style={{
                                                            width: "180px",
                                                            height: "220px",
                                                            marginTop: "20px",
                                                            borderRadius: "10px"
                                                        }}
                                                    />
                                                </center>
                                            </Row>
                                            <Row>
                                                <center>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                            fontSize: "26px",
                                                            color: colors.gpm.blanco,
                                                            marginTop: "5px"
                                                        }}
                                                    >
                                                        Principios
                                                    </Label>
                                                </center>
                                            </Row>
                                            <div style={{ height: "2px", backgroundColor: colors.gpm.blanco, }} ></div>
                                            <div style={{ height: "10px", }} ></div>
                                            <Row>
                                                <center>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                            fontSize: "22px",
                                                            color: colors.gpm.blanco,

                                                        }}
                                                    >
                                                        Respeto
                                                    </Label>
                                                </center>
                                            </Row>
                                            <Row>
                                                <center>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                            fontSize: "22px",
                                                            color: colors.gpm.blanco,

                                                        }}
                                                    >
                                                        Honestidad
                                                    </Label>
                                                </center>
                                            </Row>
                                            <Row>
                                                <center>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                            fontSize: "22px",
                                                            color: colors.gpm.blanco,
                                                        }}
                                                    >
                                                        Confianza
                                                    </Label>
                                                </center>
                                            </Row>
                                            <Row>
                                                <center>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                            fontSize: "22px",
                                                            color: colors.gpm.blanco,

                                                        }}
                                                    >
                                                        Actitud Positiva
                                                    </Label>
                                                </center>
                                            </Row>
                                            <Row>
                                                <center>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                            fontSize: "22px",
                                                            color: colors.gpm.blanco,

                                                        }}
                                                    >
                                                        Puntualidad
                                                    </Label>
                                                </center>
                                            </Row>
                                        </Col>

                                    </Row>
                                </Zoom>
                                <div style={{ height: "40px" }} ></div>

                            </section >

                            <section
                                style={{
                                    width: "100%",
                                    height: "50vh",
                                    backgroundColor: colors.gpm.azul,
                                    fontFamily: "'Roboto Slab', Bold",
                                }}
                            >
                                <div style={{ height: "10px" }} ></div>
                                <Row
                                    style={{
                                        marginRight: "17%",
                                        marginLeft: "17%",
                                        color: colors.gpm.blanco,
                                        fontWeight: "bold",
                                        fontSize: "30px",
                                        textAlign: "justify",
                                    }}
                                >
                                    <Fade top >
                                        <Col>
                                            <p
                                                style={{
                                                    lineHeight: 1,
                                                }}
                                            >
                                                En Grupo Penagos Meneses (GPM) nos destacamos como un líder en la rama de alimentos,
                                                con un destacado portafolio de franquicias reconocidas como Domino's, Arby's y SushiItto.
                                                Nuestra experiencia sólida en esta área nos ha establecido como una opción confiable y
                                                preferida para nuestros clientes.
                                            </p>
                                        </Col>
                                    </Fade>
                                </Row>
                                <Row
                                    style={{
                                        marginRight: "17%",
                                        marginLeft: "17%",
                                        color: colors.gpm.blanco,
                                        // fontWeight: "bold",
                                        fontSize: "18px",
                                        textAlign: "justify",
                                    }}
                                >
                                    <Fade top >
                                        <p
                                            style={{
                                                lineHeight: 1,
                                            }}
                                        >
                                            Manteniendo la mirada en el futuro, nos enfocamos en operaciones wow y en la innovación
                                            continua para superar las expectativas de nuestros clientes wow. Más allá de ser un grupo
                                            empresarial, GPM es una familia unida con una visión compartida de éxito y desarrollo,
                                            extendiendo la invitación a otros a unirse a nuestra historia y esencia como GPM.
                                        </p>
                                    </Fade>
                                </Row>
                                <Row
                                    style={{
                                        marginRight: "17%",
                                        marginLeft: "17%",
                                        color: colors.gpm.blanco,
                                        // fontWeight: "bold",
                                        fontSize: "18px",
                                        textAlign: "justify",
                                    }}
                                >
                                    <Fade top >
                                        <p
                                            style={{
                                                lineHeight: 1,
                                            }}
                                        >
                                            En resumen, en GPM, compartimos una visión de triunfo y expansión. Te extendemos la invitación a
                                            unirte a nuestro camino de crecimiento y a formar parte importante de nosotros, somos GPM.
                                        </p>
                                    </Fade>
                                </Row>
                                <Row
                                    style={{
                                        marginRight: "17%",
                                        marginLeft: "17%",
                                        color: colors.gpm.blanco,
                                        // fontWeight: "bold",
                                        fontSize: "18px",
                                        textAlign: "justify",
                                    }}
                                >
                                    <Fade top >
                                        <Label
                                            style={{
                                                // textDecorationLine:"underline",
                                                fontWeight: this.state.fontWeight,
                                                cursor: "pointer"
                                            }}
                                            onMouseEnter={() => this.setState({ fontWeight: "bold" })}
                                            onMouseLeave={() => this.setState({ fontWeight: "" })}
                                            onClick={() => this.setState({ estaAbiertoModalMarcas: true })}
                                        >
                                            ¿Quieres ser parte de nosotros?, haz click aquí para solicitar empleo en una de nuestras unidades
                                        </Label>
                                    </Fade>
                                </Row>
                            </section>

                            <section
                                id="section"
                                style={{
                                    width: "100%",
                                    height: "100vh",
                                    color: colors.gpm.cafe,
                                    background: `url(${dominosUncah}) no-repeat center center/cover fixed`,
                                }}
                            >
                                <Row
                                    style={{
                                        marginRight: "50px",
                                        marginLeft: "50px",
                                    }}
                                >
                                    <Col
                                        style={{
                                            textAlign: "right"
                                        }}
                                    >
                                        <Fade right>
                                            <img
                                                src={imgFil}
                                                alt="No imagen"
                                                height={300}
                                                style={{
                                                    marginTop: "50px",
                                                    marginLeft: "20px"
                                                }}
                                            />
                                        </Fade>
                                    </Col>
                                </Row>

                                <div style={{ height: "200px" }} ></div>
                                <Fade left>
                                    <Row
                                        style={{
                                            marginRight: "50px",
                                            marginLeft: "50px",
                                            backgroundColor: 'rgba(255, 255, 255, 0.15)',
                                            width: '500px',
                                            borderRadius: '10px',
                                            backdropFilter: 'blur(5px)',
                                            padding: '20px',
                                        }}
                                    >

                                        <Label
                                            style={{
                                                fontFamily: "'Roboto Slab', serif",
                                                color: colors.gpm.blanco,
                                                fontWeight: "bold",
                                                fontSize: "30px"
                                            }}
                                        >
                                            Entrega felicidad y sabor
                                        </Label>
                                        <br />
                                        <Label
                                            style={{
                                                fontFamily: "'Roboto Slab', serif",
                                                color: colors.gpm.blanco,
                                                // fontWeight:"bold",
                                                fontSize: "24px"
                                            }}
                                        >
                                            a través de una experiencia única.
                                        </Label>

                                    </Row>
                                </Fade>
                            </section>

                            <section
                                id="sectionUnidades"
                                style={{
                                    width: "100%",
                                    height: "100vh",
                                    backgroundColor: colors.gpm.azul,
                                    fontFamily: "'Roboto Slab', serif",
                                }}
                            >
                                <div style={{ height: "100px" }} ></div>
                                <Fade bottom>
                                    <Row
                                        style={{
                                            marginRight: "50px",
                                            marginLeft: "50px",
                                            padding: "10px",
                                            backgroundColor: "#71C4E7",
                                            borderRadius: "10px",
                                            borderWidth: "6px",
                                            borderStyle: "solid",
                                            borderColor: "#045071",
                                            color: "#045071"
                                        }}
                                    >
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "30px",
                                            }}
                                        >
                                            Unidades de GMP
                                        </Label>

                                    </Row>
                                </Fade >
                                <div style={{ height: "20px" }} ></div>
                                <Row
                                    style={{
                                        marginRight: "50px",
                                        marginLeft: "50px",
                                    }}
                                >

                                    <Col
                                        style={{
                                            maxWidth: "30%",
                                            textAlign: "center",
                                        }}
                                    >
                                        <Fade left>
                                            <img
                                                src={dominosLogo}
                                                height="150px"
                                                alt=""
                                            />
                                        </Fade>
                                    </Col>
                                    <Col
                                        style={{
                                            maxWidth: "70%",
                                            fontWeight: "bold",
                                            color: colors.gpm.blanco,
                                            fontSize: "42px",
                                            textAlign: "right",
                                        }}
                                    >
                                        <Fade right>
                                            <Label>36 Unidades</Label>
                                            <br />
                                            <Label></Label>
                                        </Fade>
                                    </Col>
                                </Row>
                                <div style={{ height: "10px" }} ></div>
                                <div style={{ height: "5px", backgroundColor: colors.gpm.blanco, marginRight: "50px", marginLeft: "50px" }} ></div>
                                <div style={{ height: "10px" }} ></div>
                                <Row
                                    style={{
                                        marginRight: "50px",
                                        marginLeft: "50px",
                                    }}
                                >
                                    <Col
                                        style={{
                                            maxWidth: "30%",
                                            textAlign: "center",
                                        }}
                                    >
                                        <Fade left>
                                            <img
                                                src={arbysLogo}
                                                alt=""
                                                height="100px"
                                            />
                                        </Fade>
                                    </Col>
                                    <Col
                                        style={{
                                            maxWidth: "70%",
                                            fontWeight: "bold",
                                            color: colors.gpm.blanco,
                                            fontSize: "42px",
                                            textAlign: "right",
                                        }}
                                    >
                                        <Fade right>
                                            <Label>2 Unidades</Label>
                                        </Fade>
                                    </Col>
                                </Row>
                                <div style={{ height: "10px" }} ></div>
                                <div style={{ height: "5px", backgroundColor: colors.gpm.blanco, marginRight: "50px", marginLeft: "50px" }} ></div>
                                <div style={{ height: "10px" }} ></div>
                                <Row
                                    style={{
                                        marginRight: "50px",
                                        marginLeft: "50px",
                                    }}
                                >
                                    <Col
                                        style={{
                                            maxWidth: "30%",
                                            textAlign: "center",
                                        }}
                                    >
                                        <Fade left>
                                            <img
                                                src={sushiittoLogo}
                                                alt=""
                                                height="100px"
                                            />
                                        </Fade>
                                    </Col>
                                    <Col
                                        style={{
                                            maxWidth: "70%",
                                            fontWeight: "bold",
                                            color: colors.gpm.blanco,
                                            fontSize: "42px",
                                            textAlign: "right",
                                        }}
                                    >
                                        <Fade right>
                                            <Label>2 Unidades</Label>
                                        </Fade>
                                    </Col>
                                </Row>


                            </section>

                            <section
                                id="section"
                                style={{
                                    width: "100%",
                                    height: "50vh",
                                    // backgroundColor: "blue",
                                    color: colors.gpm.blanco,
                                }}
                            >

                            </section>

                        </>
                        :
                        <>
                            <section
                                style={{
                                    width: "100%",
                                    height: "auto",
                                    backgroundColor: colors.gpm.azul,
                                    color: colors.gpm.cafe,
                                    fontFamily: "'Roboto Slab', serif",
                                }}
                            >
                                <Block height={"80px"} />
                                <Zoom>
                                    <Row
                                        style={{
                                            marginRight: "1px",
                                            marginLeft: "1px"
                                        }}
                                    >
                                        <center>
                                            <Col

                                                style={{
                                                    maxWidth: "90%",
                                                    backgroundColor: "#40B4E5",
                                                    borderRadius: "10px",
                                                    borderWidth: "6px",
                                                    borderStyle: "solid",
                                                    borderColor: "white",
                                                    padding: 10
                                                }}
                                            >
                                                <Row>
                                                    <center>
                                                        <img
                                                            src={Vision}
                                                            style={{
                                                                width: "180px",
                                                                height: "220px",
                                                                marginTop: "20px",
                                                                borderRadius: "10px"
                                                            }}
                                                        />
                                                    </center>
                                                </Row>
                                                <Row>
                                                    <center>
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                fontSize: "26px",
                                                                color: colors.gpm.blanco,
                                                                marginTop: "5px"
                                                            }}
                                                        >
                                                            Vision
                                                        </Label>
                                                    </center>
                                                </Row>
                                                <div style={{ height: "2px", backgroundColor: colors.gpm.blanco, }} ></div>
                                                <div style={{ height: "10px", }} ></div>
                                                <Row>
                                                    <center>
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                fontSize: "22px",
                                                                color: colors.gpm.blanco,

                                                            }}
                                                        >
                                                            Gente WOW en la misión de ser la franquicia Dominante #1 de Domino's, Arby's y Sushi Itto en todo el mundo
                                                        </Label>
                                                    </center>
                                                </Row>

                                            </Col>
                                        </center>
                                    </Row>
                                    <Block height={"20px"} />
                                    <Row
                                        style={{
                                            marginRight: "1px",
                                            marginLeft: "1px"
                                        }}
                                    >
                                        <center>
                                            <Col

                                                style={{
                                                    maxWidth: "90%",
                                                    backgroundColor: "#FCE444",
                                                    borderRadius: "10px",
                                                    borderWidth: "6px",
                                                    borderStyle: "solid",
                                                    borderColor: "white",
                                                    padding: 10
                                                }}
                                            >
                                                <Row>
                                                    <center>
                                                        <img
                                                            src={Mision}
                                                            style={{
                                                                width: "180px",
                                                                height: "220px",
                                                                marginTop: "20px",
                                                                borderRadius: "10px"
                                                            }}
                                                        />
                                                    </center>
                                                </Row>
                                                <Row>
                                                    <center>
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                fontSize: "26px",
                                                                color: colors.gpm.azul,
                                                                marginTop: "5px"
                                                            }}
                                                        >
                                                            Misión
                                                        </Label>
                                                    </center>
                                                </Row>
                                                <div style={{ height: "2px", backgroundColor: colors.gpm.azul, }} ></div>
                                                <div style={{ height: "10px", }} ></div>
                                                <Row>
                                                    <center>
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                fontSize: "22px",
                                                                color: colors.gpm.azul,

                                                            }}
                                                        >
                                                            OPERACIONES WOW
                                                        </Label>
                                                    </center>
                                                </Row>
                                                <Row>
                                                    <center>
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                fontSize: "22px",
                                                                color: colors.gpm.azul,

                                                            }}
                                                        >

                                                        </Label>
                                                    </center>
                                                </Row>
                                                <Row>
                                                    <center>
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                fontSize: "22px",
                                                                color: colors.gpm.azul,

                                                            }}
                                                        >

                                                        </Label>
                                                    </center>
                                                </Row>
                                                <Row>
                                                    <center>
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                fontSize: "22px",
                                                                color: colors.gpm.azul,

                                                            }}
                                                        >

                                                        </Label>
                                                    </center>
                                                </Row>
                                            </Col>
                                        </center>
                                    </Row>
                                    <Block height={"20px"} />
                                    <Row
                                        style={{
                                            marginRight: "1px",
                                            marginLeft: "1px"
                                        }}
                                    >
                                        <center>
                                            <Col
                                                style={{
                                                    maxWidth: "90%",
                                                    backgroundColor: "#FF6C36",
                                                    borderRadius: "10px",
                                                    borderWidth: "6px",
                                                    borderStyle: "solid",
                                                    borderColor: "white",
                                                    padding: 10
                                                }}
                                            >
                                                <Row>
                                                    <center>
                                                        <img
                                                            src={Estrategia}
                                                            style={{
                                                                width: "180px",
                                                                height: "220px",
                                                                marginTop: "20px",
                                                                borderRadius: "10px"
                                                            }}
                                                        />
                                                    </center>
                                                </Row>
                                                <Row>
                                                    <center>
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                fontSize: "26px",
                                                                color: colors.gpm.blanco,
                                                                marginTop: "5px"
                                                            }}
                                                        >
                                                            Estrategia
                                                        </Label>
                                                    </center>
                                                </Row>
                                                <div style={{ height: "2px", backgroundColor: colors.gpm.blanco, }} ></div>
                                                <div style={{ height: "10px", }} ></div>
                                                <Row>
                                                    <center>
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                fontSize: "22px",
                                                                color: colors.gpm.blanco,

                                                            }}
                                                        >
                                                            Gente WOW
                                                        </Label>
                                                    </center>
                                                </Row>
                                                <Row>
                                                    <center>
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                fontSize: "22px",
                                                                color: colors.gpm.blanco,

                                                            }}
                                                        >
                                                            Estándares WOW
                                                        </Label>
                                                    </center>
                                                </Row>
                                                <Row>
                                                    <center>
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                fontSize: "22px",
                                                                color: colors.gpm.blanco,

                                                            }}
                                                        >
                                                            Clientes WOW
                                                        </Label>
                                                    </center>
                                                </Row>
                                                <Row>
                                                    <center>
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                fontSize: "22px",
                                                                color: colors.gpm.blanco,

                                                            }}
                                                        >
                                                            Rentabilidad WOW
                                                        </Label>
                                                    </center>
                                                </Row>
                                            </Col>
                                        </center>
                                    </Row>
                                    <Block height={"20px"} />
                                    <Row
                                        style={{
                                            marginRight: "1px",
                                            marginLeft: "1px"
                                        }}
                                    >
                                        <center>
                                            <Col

                                                style={{
                                                    maxWidth: "90%",
                                                    backgroundColor: "#9794D2",
                                                    borderRadius: "10px",
                                                    borderWidth: "6px",
                                                    borderStyle: "solid",
                                                    borderColor: "white",
                                                    padding: 10
                                                }}
                                            >
                                                <Row>
                                                    <center>
                                                        <img
                                                            src={Principios}
                                                            style={{
                                                                width: "180px",
                                                                height: "220px",
                                                                marginTop: "20px",
                                                                borderRadius: "10px"
                                                            }}
                                                        />
                                                    </center>
                                                </Row>
                                                <Row>
                                                    <center>
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                fontSize: "26px",
                                                                color: colors.gpm.blanco,
                                                                marginTop: "5px"
                                                            }}
                                                        >
                                                            Principios
                                                        </Label>
                                                    </center>
                                                </Row>
                                                <div style={{ height: "2px", backgroundColor: colors.gpm.blanco, }} ></div>
                                                <div style={{ height: "10px", }} ></div>
                                                <Row>
                                                    <center>
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                fontSize: "22px",
                                                                color: colors.gpm.blanco,

                                                            }}
                                                        >
                                                            Respeto
                                                        </Label>
                                                    </center>
                                                </Row>
                                                <Row>
                                                    <center>
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                fontSize: "22px",
                                                                color: colors.gpm.blanco,

                                                            }}
                                                        >
                                                            Honestidad
                                                        </Label>
                                                    </center>
                                                </Row>
                                                <Row>
                                                    <center>
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                fontSize: "22px",
                                                                color: colors.gpm.blanco,
                                                            }}
                                                        >
                                                            Confianza
                                                        </Label>
                                                    </center>
                                                </Row>
                                                <Row>
                                                    <center>
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                fontSize: "22px",
                                                                color: colors.gpm.blanco,

                                                            }}
                                                        >
                                                            Actitud Positiva
                                                        </Label>
                                                    </center>
                                                </Row>
                                                <Row>
                                                    <center>
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                fontSize: "22px",
                                                                color: colors.gpm.blanco,

                                                            }}
                                                        >
                                                            Puntualidad
                                                        </Label>
                                                    </center>
                                                </Row>
                                            </Col>
                                        </center>
                                    </Row>
                                    <Block height={"20px"} />
                                    <Row
                                        style={{
                                            marginRight: "1px",
                                            marginLeft: "1px",
                                            color: colors.gpm.blanco,
                                            fontWeight: "bold",
                                            fontSize: "30px",
                                        }}
                                    >
                                        <Fade top >
                                            <center>
                                                <Col
                                                    style={{
                                                        maxWidth: "90%"
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            lineHeight: 1,
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        En Grupo Penagos Meneses (GPM) nos destacamos como un líder en la rama de alimentos,
                                                        con un destacado portafolio de franquicias reconocidas como Domino's, Arby's y SushiItto.
                                                        Nuestra experiencia sólida en esta área nos ha establecido como una opción confiable y
                                                        preferida para nuestros clientes.
                                                    </p>
                                                </Col>
                                            </center>
                                        </Fade>
                                    </Row>
                                    <Block height={"20px"} />
                                    <Row
                                        style={{
                                            marginRight: "1px",
                                            marginLeft: "1px",
                                            color: colors.gpm.blanco,
                                            fontSize: "18px",
                                        }}
                                    >
                                        <Fade top >
                                            <center>
                                                <Col
                                                    style={{
                                                        maxWidth: "90%"
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            lineHeight: 1,
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        Manteniendo la mirada en el futuro, nos enfocamos en operaciones wow y en la innovación
                                                        continua para superar las expectativas de nuestros clientes wow. Más allá de ser un grupo
                                                        empresarial, GPM es una familia unida con una visión compartida de éxito y desarrollo,
                                                        extendiendo la invitación a otros a unirse a nuestra historia y esencia como GPM.
                                                    </p>
                                                </Col>
                                            </center>
                                        </Fade>
                                    </Row>
                                    <Block height={"20px"} />
                                    <Row
                                        style={{
                                            marginRight: "1px",
                                            marginLeft: "1px",
                                            color: colors.gpm.blanco,
                                            fontSize: "18px",
                                        }}
                                    >
                                        <Fade top >
                                            <center>
                                                <Col
                                                    style={{
                                                        maxWidth: "90%"
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            lineHeight: 1,
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        En resumen, en GPM, compartimos una visión de triunfo y expansión. Te extendemos la invitación a
                                                        unirte a nuestro camino de crecimiento y a formar parte importante de nosotros, somos GPM.
                                                    </p>
                                                </Col>
                                            </center>
                                        </Fade>
                                    </Row>
                                    <Block height={"20px"} />
                                    <Row
                                        style={{
                                            marginRight: "1px",
                                            marginLeft: "1px",
                                            color: colors.gpm.blanco,                                            
                                            fontSize: "18px",

                                        }}
                                    >
                                        <Fade top >
                                            <center>
                                                <Col
                                                    style={{
                                                        textAlign: "justify",
                                                        maxWidth:"90%"
                                                    }}
                                                >
                                                    <Label
                                                        style={{
                                                            textAlign: "justify",
                                                            fontWeight: this.state.fontWeight,
                                                            cursor: "pointer",
                                                            lineHeight: 1,
                                                        }}
                                                        onMouseEnter={() => this.setState({ fontWeight: "bold" })}
                                                        onMouseLeave={() => this.setState({ fontWeight: "" })}
                                                        onClick={() => this.setState({ estaAbiertoModalMarcas: true })}
                                                    >
                                                        ¿Quieres ser parte de nosotros?, haz click aquí para solicitar empleo en una de nuestras unidades
                                                    </Label>
                                                </Col>
                                            </center>
                                        </Fade>
                                    </Row>
                                    <Block height={"20px"} />
                                </Zoom>
                            </section>
                            <section
                                id="sectionUnidades"
                                style={{
                                    width: "100%",
                                    height: "100vh",
                                    backgroundColor: colors.gpm.azul,
                                    fontFamily: "'Roboto Slab', serif",
                                }}
                            >
                                <div style={{ height: "10px" }} ></div>
                                <Fade bottom>
                                    <Row
                                        style={{
                                            marginRight: "1px",
                                            marginLeft: "1px",
                                            padding: "10px",
                                            backgroundColor: "#71C4E7",
                                            borderRadius: "10px",
                                            borderWidth: "6px",
                                            borderStyle: "solid",
                                            borderColor: "#045071",
                                            color: "#045071"
                                        }}
                                    >
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "30px",
                                            }}
                                        >
                                            Unidades de GMP
                                        </Label>

                                    </Row>
                                </Fade >
                                <div style={{ height: "20px" }} ></div>
                                <Row
                                    style={{
                                        marginRight: "1px",
                                        marginLeft: "1px",
                                    }}
                                >

                                    <Col
                                        style={{
                                            maxWidth: "30%",
                                            textAlign: "center",
                                        }}
                                    >
                                        <Fade left>
                                            <img
                                                src={dominosLogo}
                                                height="130px"
                                                alt=""
                                            />
                                        </Fade>
                                    </Col>
                                    <Col
                                        style={{
                                            maxWidth: "70%",
                                            fontWeight: "bold",
                                            color: colors.gpm.blanco,
                                            fontSize: "30px",
                                            textAlign: "right",
                                        }}
                                    >
                                        <Fade right>
                                            <Label>40 Unidades</Label>
                                        </Fade>
                                    </Col>
                                </Row>
                                <div style={{ height: "10px" }} ></div>
                                <div style={{ height: "5px", backgroundColor: colors.gpm.blanco, marginRight: "1px", marginLeft: "1px" }} ></div>
                                <div style={{ height: "10px" }} ></div>
                                <Row
                                    style={{
                                        marginRight: "1px",
                                        marginLeft: "1px",
                                    }}
                                >
                                    <Col
                                        style={{
                                            maxWidth: "30%",
                                            textAlign: "center",
                                        }}
                                    >
                                        <Fade left>
                                            <img
                                                src={arbysLogo}
                                                alt=""
                                                height="100px"
                                            />
                                        </Fade>
                                    </Col>
                                    <Col
                                        style={{
                                            maxWidth: "70%",
                                            fontWeight: "bold",
                                            color: colors.gpm.blanco,
                                            fontSize: "30px",
                                            textAlign: "right",
                                        }}
                                    >
                                        <Fade right>
                                            <Label>2 Unidades</Label>
                                        </Fade>
                                    </Col>
                                </Row>
                                <div style={{ height: "10px" }} ></div>
                                <div style={{ height: "5px", backgroundColor: colors.gpm.blanco, marginRight: "1px", marginLeft: "1px" }} ></div>
                                <div style={{ height: "10px" }} ></div>
                                <Row
                                    style={{
                                        marginRight: "1px",
                                        marginLeft: "1px",
                                    }}
                                >
                                    <Col
                                        style={{
                                            maxWidth: "30%",
                                            textAlign: "center",
                                        }}
                                    >
                                        <Fade left>
                                            <img
                                                src={sushiittoLogo}
                                                alt=""
                                                height="50px"
                                            />
                                        </Fade>
                                    </Col>
                                    <Col
                                        style={{
                                            maxWidth: "70%",
                                            fontWeight: "bold",
                                            color: colors.gpm.blanco,
                                            fontSize: "30px",
                                            textAlign: "right",
                                        }}
                                    >
                                        <Fade right>
                                            <Label>2 Unidades</Label>
                                        </Fade>
                                    </Col>
                                </Row>


                            </section>
                        </>
                }

                <Modal
                    isOpen={this.state.estaAbiertoModalMarcas}
                    size="lg"
                    style={{
                        maxWidth: "80%",
                    }}
                >
                    <ModalHeader
                        style={{
                            backgroundColor: colors.gpm.azul
                        }}
                        close={
                            <IconButton
                                onClick={() => this.setState({ estaAbiertoModalMarcas: false })}
                                style={{
                                    color: colors.gpm.blanco
                                }}
                            >
                                <Close />
                            </IconButton>
                        }
                    >
                        <Label
                            style={{
                                fontWeight: "bold",
                                fontSize: "30px",
                                color: colors.gpm.blanco
                            }}
                        >
                            Nuestras Marcas GPM
                        </Label>
                    </ModalHeader>
                    <ModalBody>
                        <div style={{ height: "50px" }} ></div>
                        <Row>
                            <Col
                                style={{
                                    maxWidth: "33%"
                                }}
                            >
                                <center>
                                    <img
                                        src={logoArbys}
                                        alt="No se pudo cargar el logo de arbys"
                                        height="150px"
                                        width="250px"
                                        title="Postulate en Arby's GPM"
                                        style={{
                                            cursor: "pointer",
                                            marginTop: "10px",
                                            transform: this.state.isHoverLogoArbys ? 'scale(1.2)' : 'scale(1)',
                                            transition: 'transform 0.3s ease',
                                        }}
                                        onClick={() => {
                                            // window.location.href = "/gpm/arbys";
                                        }}
                                        onMouseEnter={() => {
                                            this.setState({ isHoverLogoArbys: true });
                                        }}
                                        onMouseLeave={() => {
                                            this.setState({ isHoverLogoArbys: false });
                                        }}
                                    />
                                </center>
                            </Col>
                            <Col
                                style={{
                                    maxWidth: "34%"
                                }}
                            >
                                <center>
                                    <img
                                        src={logoDominos}
                                        alt="No se pudo cargar el logo de dominos"
                                        height="175px"
                                        width="200px"
                                        title="Postulate en Domino's GPM"
                                        style={{
                                            cursor: "pointer",
                                            marginTop: "10px",
                                            transform: this.state.isHoverLogoDominos ? 'scale(1.2)' : 'scale(1)',
                                            transition: 'transform 0.3s ease',
                                        }}
                                        onClick={() => {
                                            window.location.href = "https://postulate.dominosgpm.com.mx";
                                        }}
                                        onMouseEnter={() => {
                                            this.setState({ isHoverLogoDominos: true });
                                        }}
                                        onMouseLeave={() => {
                                            this.setState({ isHoverLogoDominos: false });
                                        }}
                                    />
                                </center>
                            </Col>
                            <Col
                                style={{
                                    maxWidth: "33%"
                                }}
                            >
                                <center>
                                    <img
                                        src={logoSushiitto}
                                        alt="No se pudo cargar el logo de sushiitto"
                                        height="175px"
                                        width="300px"
                                        title="Postulate en SushiItto GPM"
                                        style={{
                                            cursor: "pointer",
                                            marginTop: "10px",
                                            transform: this.state.isHoverLogoSushiitto ? 'scale(1.2)' : 'scale(1)',
                                            transition: 'transform 0.3s ease',
                                        }}
                                        onClick={() => {
                                            // window.location.href = "/gpm/sushiitto";
                                        }}
                                        onMouseEnter={() => {
                                            this.setState({ isHoverLogoSushiitto: true });
                                        }}
                                        onMouseLeave={() => {
                                            this.setState({ isHoverLogoSushiitto: false });
                                        }}
                                    />
                                </center>
                            </Col>
                        </Row>
                        <div style={{ height: "50px" }} ></div>
                    </ModalBody>
                </Modal>

            </>
        );
    }
}

export default FilosofiaGPM;