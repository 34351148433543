import { useEffect, useState } from "react";
import SNavBarComputer from "./SNavBarComputer";
import NavBarMovil from "./NavBarMovil";
// import userModel from "../models/user.model";

const NavBar = (props) => {

    const [device, setDevice] = useState({});

    const [deviceMovil] = useState({
        isMovilDevice: true,
        isDesktopDevice: false,
    });

    const [deviceDesktop] = useState({
        isMovilDevice: false,
        isDesktopDevice: false,
    });

    return (
        <>
            {props.device.isDesktopDevice ?
                <SNavBarComputer isMain={{isMain: true}} />
                :
                <NavBarMovil device={deviceMovil} />
            }
        </>
    );

}
export default NavBar;