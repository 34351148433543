import React, { Component } from "react";
import { Col, Row, Label, Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import colors from "../config/colors.config";
import SNavBarComputer from "./NavBar/GPM/SNavBarComputer";
import Block from "../tools/Block";
import { Fade, Flip } from 'react-reveal';
import Jump from 'react-reveal/Jump';
import imgPrincipal_1M from "../assets/image/1.png";
import imgPrincipal_2M from "../assets/image/2.png";
import imgPrincipal_3M from "../assets/image/3.png";
import imgPrincipal_4M from "../assets/image/4.png";
import imgPrincipal_1 from "../assets/image/imgPrincipal_1.png";
import imgPrincipal_2 from "../assets/image/imgPrincipal_2.png";
import imgPrincipal_3 from "../assets/image/imgPrincipal_3.png";
import imgPrincipal_4 from "../assets/image/imgPrincipal_4.png";
import fondoIndexHall from "../assets/image/fondoIndexHall.jpg";
import CountUp from 'react-countup';
import imgLogoGPMBlanco from "../assets/image/logo/gpmLogoBlanco.png";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import components from "../tools/components.tool";
import ScreenTools from "../config/screen.config";
import { SyncLoader } from "react-spinners";
import SNavBarMovil from "./NavBar/GPM/SNavBarMovil";
// import SConfetti from "./components/SConfetti";

class PaginaPrincipal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // mostrarLeyenda: true,
            iniciarUnidadesTotal: false,
            iniciarUnidadesDominos: false,
            iniciarUnidadesArbys: false,
            iniciarUnidadesSushiItto: false,
            estaCargando: true,
            handle: "",
            isHoverimgLogoGPMBlanco: false,
            isHoverButton: false,
            isOpenModalDescriptionMarcaGPM: false,
            device: {
                isDesktopDevice: true,
                isMobileDevice: false,
                screenHeight: 0,
                screenWidth: 0,
            },
        }
    }

    async componentDidMount() {
        let device = await ScreenTools.getDeviceSettings();

        await this.setState({ device });
        components.sleep(2).then(async () => {
            await this.setState({ estaCargando: false });
            components.sleep(0.1).then(async () => {
                await this.cargarDatosPantalla();
            });
        });
        // components.sleep(8).then(async () => {
        //     this.setState({
        //         mostrarLeyenda: false,
        //     });
        // });

    }

    cargarDatosPantalla = async () => {


        const iniciarUnidadesTotal = await document.getElementById('iniciarUnidadesTotal');
        const iniciarUnidadesDominos = await document.getElementById('iniciarUnidadesDominos');
        const iniciarUnidadesArbys = await document.getElementById('iniciarUnidadesArbys');
        const iniciarUnidadesSushiItto = await document.getElementById('iniciarUnidadesSushiItto');

        await window.addEventListener('scroll', () => {

            if (this.isElementInViewport(iniciarUnidadesTotal)) {
                this.setState({
                    iniciarUnidadesTotal: true,
                });
            }

            if (this.isElementInViewport(iniciarUnidadesDominos)) {
                this.setState({
                    iniciarUnidadesDominos: true,
                });
            }

            if (this.isElementInViewport(iniciarUnidadesArbys)) {
                this.setState({
                    iniciarUnidadesArbys: true,
                });
            }

            if (this.isElementInViewport(iniciarUnidadesSushiItto)) {
                this.setState({
                    iniciarUnidadesSushiItto: true,
                });
            }

        });



    }

    clickOnHome = () => {
        window.location.href = "#section"
    }

    isElementInViewport = (el) => {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    render() {
        const { clickOnHome } = this;
        return (
            <>

                {
                    this.state.estaCargando ?
                        <section
                            // id="section"
                            style={{
                                width: "100%",
                                height: "100vh",
                                backgroundColor: "#FFFFFF",
                                color: colors.gpm.naranja,
                            }}
                        >
                            <div
                                style={{
                                    marginTop: "200px",
                                    marginRight: "50px",
                                    marginLeft: "50px"
                                }}
                            >
                                <center>

                                    <img
                                        id="imgLogoGPMBlanco"
                                        src={imgLogoGPMBlanco}
                                        alt="No imagen"
                                        height={150}
                                        style={{
                                            transform: this.state.isHoverimgLogoGPMBlanco ? 'scale(1.2)' : 'scale(1)',
                                            transition: 'transform 0.3s ease',
                                            cursor: "pointer"
                                        }}
                                        onClick={() => {
                                            this.setState({ isOpenModalDescriptionMarcaGPM: true });
                                        }}

                                    />

                                </center>
                            </div>
                            <div>
                                <center>
                                    <Label
                                        style={{
                                            color: colors.gpm.azul,
                                            fontWeight: "bold",
                                            fontSize: "40px",
                                            marginLeft: "10px",
                                            marginTop: "10px",
                                            fontFamily: "'Oswald', sans-serif, 'Roboto Slab', serif",
                                        }}
                                    >
                                        GPM
                                    </Label>
                                </center>
                            </div>
                            <div
                                style={{
                                    marginTop: "10px",
                                    marginRight: "50px",
                                    marginLeft: "50px"
                                }}
                            >
                                <center>
                                    <SyncLoader
                                        color={colors.gpm.azul}
                                        loading
                                        speedMultiplier={0.5}
                                    />
                                </center>
                            </div>
                        </section>
                        :
                        <>

                            {this.state.device.isDesktopDevice ? <SNavBarComputer clickOnHome={clickOnHome} isMain={true} /> : <SNavBarMovil />}

                            {
                                this.state.device.isDesktopDevice ?
                                    <>
                                        {/* <Row
                                            style={{
                                                marginRight: "0px",
                                                marginLeft: "0px"
                                            }}
                                        >
                                            <SConfetti />
                                        </Row> */}
                                        <section
                                            id="section"
                                            style={{
                                                width: '100%',
                                                height: '50vh',
                                                minHeight:"50vh",
                                                backgroundColor: '#FFFFFF',
                                                color: colors.gpm.naranja,
                                                fontFamily: "'Roboto Slab', serif",
                                                // backgroundImage: `url(${fondoIndexHall})`, // Reemplaza con la ruta de tu imagen
                                                backgroundSize: 'contain',
                                                // backgroundRepeat: 'no-repeat',
                                                // backgroundPosition: 'center',
                                            }}
                                        >
                                            <img
                                                src={fondoIndexHall}
                                                alt="No se pudo cargar la imagen"
                                                style={{
                                                    width:"100%",
                                                    height:"50vh",
                                                    position:"relative",
                                                    zIndex: 0
                                                }}
                                            />
                                            <Fade top>
                                                <div
                                                    style={{
                                                        marginTop: "-40vh",
                                                    }}
                                                >
                                                    <center>
                                                        <Label
                                                            style={{
                                                                fontSize: "36px",
                                                                fontWeight: "bold"
                                                            }}
                                                        >
                                                            Ven con nosotros
                                                        </Label>
                                                    </center>
                                                </div>
                                                <div
                                                    style={{
                                                        // marginTop: "10px",
                                                        marginRight: "50px",
                                                        marginLeft: "50px"
                                                    }}
                                                >
                                                    <center>

                                                        <Label
                                                            style={{
                                                                fontSize: "36px",
                                                                fontWeight: "bold"
                                                            }}
                                                        >
                                                            y descubre que comer es un placer
                                                        </Label>

                                                    </center>
                                                </div>
                                                <div
                                                    style={{
                                                        // marginTop: "10px",
                                                        marginRight: "50px",
                                                        marginLeft: "50px"
                                                    }}
                                                >
                                                    <center>

                                                        <Label
                                                            style={{
                                                                fontSize: "28px",
                                                                fontWeight: "bold",
                                                                color: "#000",
                                                                fontFamily: "plutosansw01-heavyregular (woff2)"
                                                            }}
                                                        >
                                                            ¿Ya conoces nuestras unidades?
                                                        </Label>

                                                    </center>
                                                </div>
                                                <div
                                                    style={{
                                                        // marginTop: "10px",
                                                        marginRight: "50px",
                                                        marginLeft: "50px"
                                                    }}
                                                >
                                                    <center>

                                                        <Button
                                                            style={{
                                                                fontSize: "24px",
                                                                borderRadius: "20px",
                                                                color: this.state.isHoverButton ? colors.gpm.blanco : colors.gpm.blanco,
                                                                backgroundColor: this.state.isHoverButton ? "#000" : colors.gpm.naranja,
                                                                boxShadow: "none",
                                                                fontFamily: "'Oswald', sans-serif, 'Roboto Slab', serif",
                                                                borderColor: this.state.isHoverButton ? "#000" : colors.gpm.naranja,
                                                            }}
                                                            onMouseEnter={() => {
                                                                this.setState({ isHoverButton: true });
                                                            }}
                                                            onMouseLeave={() => {
                                                                this.setState({ isHoverButton: false });
                                                            }}
                                                            onClick={() => {
                                                                window.location.href = "/nuestras-marcas"
                                                            }}
                                                        >
                                                            Has click aquí para conocerlas
                                                        </Button>

                                                    </center>
                                                </div>
                                            </Fade>
                                        </section>

                                        <section
                                            style={{
                                                width: "100%",
                                                height: "100vh",
                                                backgroundColor: colors.gpm.celeste,
                                                marginTop: "-110px",
                                                lineHeight: 1,
                                            }}
                                        >
                                            <center>
                                                <Fade bottom>
                                                    <label
                                                        style={{
                                                            marginTop: this.state.mostrarLeyenda ? "110px" : "160px",
                                                            fontSize: "83px",
                                                            // fontWeight: "bold",
                                                            color: colors.gpm.blanco,
                                                            fontFamily: "'Roboto Slab', serif",
                                                            transition: "1s",
                                                        }}
                                                    >
                                                        Nuestros Segmentos
                                                    </label>
                                                    {/* {
                                                        this.state.mostrarLeyenda ?
                                                            <>
                                                                <br />
                                                                <label
                                                                    style={{
                                                                        fontSize: "83px",
                                                                        fontFamily: "'Roboto Slab', serif",
                                                                        color: "#086D4D",
                                                                        textShadow: "-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black",
                                                                        marginLeft: "15px",
                                                                        transition: "1s",
                                                                    }}
                                                                >
                                                                    !Viva
                                                                </label>
                                                                <label
                                                                    style={{
                                                                        fontSize: "83px",
                                                                        fontFamily: "'Roboto Slab', serif",
                                                                        color: "#FFF",
                                                                        textShadow: "-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black",
                                                                        marginLeft: "15px",
                                                                        transition: "1s",
                                                                    }}
                                                                >
                                                                    Mexico
                                                                </label>
                                                                <label
                                                                    style={{
                                                                        fontSize: "83px",
                                                                        fontFamily: "'Roboto Slab', serif",
                                                                        color: "#D0182D",
                                                                        textShadow: "-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black",
                                                                        marginLeft: "15px",
                                                                        transition: "1s",
                                                                    }}
                                                                >
                                                                    Señores¡
                                                                </label>
                                                            </>
                                                            :
                                                            <></>
                                                    } */}
                                                </Fade>
                                            </center>

                                        </section>

                                        <section
                                            style={{
                                                width: "100%",
                                                height: "100vh",
                                                background: `url(${imgPrincipal_1}) no-repeat center center/cover fixed`,
                                            }}
                                        >
                                            <Row
                                                style={{
                                                    marginRight: "50px",
                                                }}
                                            >
                                                <Col
                                                    style={{
                                                        maxWidth: "50%",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    {/* <Flip top> */}
                                                    <Label
                                                        style={{
                                                            // fontWeight: "bold",
                                                            color: colors.gpm.blanco,
                                                            marginTop: "60px",
                                                            marginLeft: "100px",
                                                            fontSize: "50px",
                                                            fontFamily: "'Roboto Slab', serif",
                                                        }}
                                                    >
                                                        Restaurantes de <br /> Servicio Rápido
                                                    </Label>
                                                    {/* </Flip> */}
                                                </Col>
                                                <Col
                                                    style={{
                                                        maxWidth: "50%",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    <center>
                                                        {/* <Flip top> */}
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                color: colors.gpm.blanco,
                                                                marginTop: "60px",
                                                                marginLeft: "300px",
                                                                fontSize: "80px",
                                                                fontFamily: "'Roboto Slab', serif",
                                                            }}
                                                            id="iniciarUnidadesTotal"
                                                        >
                                                            {
                                                                this.state.iniciarUnidadesTotal ?
                                                                    <CountUp start={0} end={44} duration={3} />
                                                                    :
                                                                    "0"
                                                            }

                                                        </Label>
                                                        <br />
                                                        <Label
                                                            style={{
                                                                marginTop: "-30px",
                                                                color: colors.gpm.blanco,
                                                                fontFamily: "'Roboto Slab', serif",
                                                                marginLeft: "300px",
                                                                fontSize: "50px"
                                                            }}
                                                        >
                                                            Unidades
                                                        </Label>
                                                        {/* </Flip> */}
                                                    </center>
                                                </Col>
                                            </Row>
                                        </section >

                                        <section
                                            style={{
                                                width: "100%",
                                                height: "100vh",
                                                backgroundColor: "green",
                                                background: `url(${imgPrincipal_2}) no-repeat center center/cover fixed`,
                                            }}
                                        >
                                            <Row
                                                style={{
                                                    marginRight: "50px",
                                                }}
                                            >
                                                <Col
                                                    style={{
                                                        maxWidth: "50%",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    {/* <Flip top> */}
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                            color: colors.gpm.blanco,
                                                            marginTop: "60px",
                                                            marginLeft: "100px",
                                                            fontSize: "40px",
                                                            textAlign: "center",
                                                            fontFamily: "'Roboto Slab', serif",
                                                        }}
                                                    >
                                                        El mejor invento <br />  del hombre <br /> después de la rueda <br />  es la Domino's
                                                    </Label>
                                                    {/* </Flip> */}
                                                </Col>
                                                <Col
                                                    style={{
                                                        maxWidth: "50%",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    <center>
                                                        {/* <Flip top> */}
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                color: colors.gpm.blanco,
                                                                marginTop: "60px",
                                                                marginLeft: "340px",
                                                                fontSize: "80px",
                                                                fontFamily: "'Roboto Slab', serif",
                                                            }}
                                                            id="iniciarUnidadesDominos"
                                                        >
                                                            {
                                                                this.state.iniciarUnidadesDominos ?
                                                                    <CountUp start={0} end={40} duration={3} />
                                                                    :
                                                                    "0"
                                                            }
                                                        </Label>

                                                        <Label
                                                            style={{
                                                                marginTop: "-30px",
                                                                color: colors.gpm.blanco,
                                                                fontFamily: "'Roboto Slab', serif",
                                                                marginLeft: "340px",
                                                                fontSize: "50px"
                                                            }}
                                                        >
                                                            Unidades
                                                        </Label>
                                                        {/* </Flip> */}
                                                    </center>
                                                </Col>
                                                <div style={{ height: "100px" }} ></div>
                                            </Row>

                                        </section >

                                        <section
                                            style={{
                                                width: "100%",
                                                height: "100vh",
                                                backgroundColor: "green",
                                                background: `url(${imgPrincipal_3}) no-repeat center center/cover fixed`,
                                            }}
                                        >
                                            <Row
                                                style={{
                                                    marginRight: "50px",
                                                }}
                                            >
                                                <Col
                                                    style={{
                                                        maxWidth: "50%",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    <Flip top>
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                color: colors.gpm.blanco,
                                                                marginTop: "60px",
                                                                marginLeft: "150px",
                                                                fontSize: "50px",
                                                                textAlign: "center",
                                                                fontFamily: "'Roboto Slab', serif",
                                                            }}
                                                        >
                                                            Comer es <br /> un placer
                                                        </Label>
                                                    </Flip>
                                                </Col>
                                                <Col
                                                    style={{
                                                        maxWidth: "50%",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    <center>
                                                        <Flip top>
                                                            <Label
                                                                style={{
                                                                    fontWeight: "bold",
                                                                    color: colors.gpm.blanco,
                                                                    marginTop: "60px",
                                                                    marginLeft: "300px",
                                                                    fontSize: "80px",
                                                                    fontFamily: "'Roboto Slab', serif",
                                                                }}
                                                                id="iniciarUnidadesArbys"
                                                            >
                                                                {
                                                                    this.state.iniciarUnidadesArbys ?
                                                                        <CountUp start={0} end={2} duration={3} />
                                                                        :
                                                                        "0"
                                                                }
                                                            </Label>

                                                            <Label
                                                                style={{
                                                                    marginTop: "-30px",
                                                                    color: colors.gpm.blanco,
                                                                    fontFamily: "'Roboto Slab', serif",
                                                                    marginLeft: "300px",
                                                                    fontSize: "50px"
                                                                }}
                                                            >
                                                                Unidades
                                                            </Label>
                                                        </Flip>
                                                    </center>
                                                </Col>
                                                <div style={{ height: "100px" }} ></div>
                                            </Row>

                                        </section >

                                        <section
                                            style={{
                                                width: "100%",
                                                height: "100vh",
                                                backgroundColor: "green",
                                                background: `url(${imgPrincipal_4}) no-repeat center center/cover fixed`,
                                            }}
                                        >
                                            <Row
                                                style={{
                                                    marginRight: "50px",
                                                }}
                                            >
                                                <Col
                                                    style={{
                                                        maxWidth: "50%",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    <Flip top>
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                color: colors.sushiitto.morado,
                                                                marginTop: "60px",
                                                                marginLeft: "10px",
                                                                fontSize: "50px",
                                                                textAlign: "center",
                                                                fontFamily: "'Roboto Slab', serif",
                                                            }}
                                                        >
                                                            Por muy rico que se vea <br /> recuerda que se vale <br /> compartir
                                                        </Label>
                                                    </Flip>
                                                </Col>
                                                <Col
                                                    style={{
                                                        maxWidth: "50%",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    <center>
                                                        <Flip top>
                                                            <Label
                                                                style={{
                                                                    fontWeight: "bold",
                                                                    color: colors.sushiitto.morado,
                                                                    marginTop: "60px",
                                                                    marginLeft: "300px",
                                                                    fontSize: "80px",
                                                                    fontFamily: "'Roboto Slab', serif",
                                                                }}
                                                                id="iniciarUnidadesSushiItto"
                                                            >
                                                                {
                                                                    this.state.iniciarUnidadesSushiItto ?
                                                                        <CountUp start={0} end={2} duration={3} />
                                                                        :
                                                                        "0"
                                                                }
                                                            </Label>

                                                            <Label
                                                                style={{
                                                                    marginTop: "-30px",
                                                                    color: colors.sushiitto.morado,
                                                                    fontFamily: "'Roboto Slab', serif",
                                                                    marginLeft: "300px",
                                                                    fontSize: "50px"
                                                                }}
                                                            >
                                                                Unidades
                                                            </Label>
                                                        </Flip>
                                                    </center>
                                                </Col>
                                                <div style={{ height: "100px" }} ></div>
                                            </Row>

                                        </section >

                                    </>
                                    :
                                    <>
                                        {/* <Row
                                            style={{
                                                marginRight: "0px",
                                                marginLeft: "0px"
                                            }}
                                        >
                                            <SConfetti />
                                        </Row> */}
                                        <section
                                            style={{
                                                width: "100%",
                                                height: "50vh",
                                                backgroundColor: colors.gpm.blanco,
                                                fontFamily: "'Roboto Slab', serif",
                                            }}
                                        >
                                            <Block height={"100px"} />
                                            <Fade top>
                                                <div>
                                                    <center>
                                                        <Label
                                                            style={{
                                                                fontSize: "24px",
                                                                fontWeight: "bold",
                                                                color: colors.gpm.naranja
                                                            }}
                                                        >
                                                            Ven con nosotros
                                                        </Label>
                                                    </center>
                                                </div>
                                                <div
                                                    style={{
                                                        // marginTop: "10px",
                                                        marginRight: "10px",
                                                        marginLeft: "10px"
                                                    }}
                                                >
                                                    <center>

                                                        <Label
                                                            style={{
                                                                fontSize: "24px",
                                                                fontWeight: "bold",
                                                                color: colors.gpm.naranja
                                                            }}
                                                        >
                                                            y descubre que comer es un placer
                                                        </Label>

                                                    </center>
                                                </div>
                                                <div
                                                    style={{
                                                        // marginTop: "10px",
                                                        marginRight: "10px",
                                                        marginLeft: "10px"
                                                    }}
                                                >
                                                    <center>

                                                        <Label
                                                            style={{
                                                                fontSize: "22px",
                                                                fontWeight: "bold",
                                                                color: "#000",
                                                                fontFamily: "plutosansw01-heavyregular (woff2)"
                                                            }}
                                                        >
                                                            ¿Ya conoces nuestras unidades?
                                                        </Label>

                                                    </center>
                                                </div>
                                                <div
                                                    style={{
                                                        marginRight: "10px",
                                                        marginLeft: "10px"
                                                    }}
                                                >
                                                    <center>

                                                        <Button
                                                            style={{
                                                                marginTop: "10px",
                                                                fontSize: "24px",
                                                                borderRadius: "20px",
                                                                color: this.state.isHoverButton ? colors.gpm.blanco : colors.gpm.blanco,
                                                                backgroundColor: this.state.isHoverButton ? "#000" : colors.gpm.naranja,
                                                                boxShadow: "none",
                                                                fontFamily: "'Oswald', sans-serif, 'Roboto Slab', serif",
                                                                borderColor: this.state.isHoverButton ? "#000" : colors.gpm.naranja,
                                                            }}
                                                            onMouseEnter={() => {
                                                                this.setState({ isHoverButton: true });
                                                            }}
                                                            onMouseLeave={() => {
                                                                this.setState({ isHoverButton: false });
                                                            }}
                                                            onClick={() => {
                                                                window.location.href = "/nuestras-marcas"
                                                            }}
                                                        >
                                                            Has click aquí para conocerlas
                                                        </Button>

                                                    </center>
                                                </div>
                                            </Fade>
                                        </section>
                                        <section
                                            style={{
                                                width: "100%",
                                                height: "50vh",
                                                backgroundColor: colors.gpm.celeste,
                                            }}
                                        >
                                            <center>
                                                <Fade bottom>
                                                    <label
                                                        style={{
                                                            marginTop: "60px",
                                                            fontSize: "48px",
                                                            // fontWeight: "bold",
                                                            color: colors.gpm.blanco,
                                                            fontFamily: "'Roboto Slab', serif",
                                                        }}
                                                    >
                                                        Nuestros Segmentos
                                                    </label>
                                                </Fade>
                                            </center>
                                        </section>
                                        <section
                                            style={{
                                                width: "100%",
                                                height: "100vh",
                                                backgroundColor: colors.gpm.celeste,
                                                background: `url(${imgPrincipal_1M}) no-repeat center center/cover`,
                                                fontFamily: "'Roboto Slab', serif",
                                            }}
                                        >
                                            <Block height={"100px"} />
                                            <Row
                                                style={{
                                                    marginRight: "10px",
                                                    marginLeft: "10px"
                                                }}
                                            >
                                                <center>
                                                    <Label
                                                        style={{
                                                            color: colors.gpm.blanco,
                                                            fontSize: "40px",
                                                        }}
                                                    >
                                                        Restaurantes de <br />
                                                        Servicio Rápido
                                                    </Label>
                                                </center>
                                            </Row>
                                            <Row
                                                style={{
                                                    marginRight: "10px",
                                                    marginLeft: "10px"
                                                }}
                                            >
                                                <center>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                            color: colors.gpm.blanco,
                                                            marginTop: "60px",
                                                            fontSize: "40px",
                                                        }}
                                                        id="iniciarUnidadesTotal"
                                                    >
                                                        {
                                                            this.state.iniciarUnidadesTotal ?
                                                                <CountUp start={0} end={40} duration={6} />
                                                                :
                                                                "0"
                                                        }

                                                    </Label>
                                                    <br />
                                                    <Label
                                                        style={{
                                                            marginTop: "-30px",
                                                            color: colors.gpm.blanco,
                                                            fontSize: "40px"
                                                        }}
                                                    >
                                                        Unidades
                                                    </Label>
                                                </center>
                                            </Row>
                                        </section>
                                        <section
                                            style={{
                                                width: "100%",
                                                height: "100vh",
                                                backgroundColor: colors.gpm.azul,
                                                background: `url(${imgPrincipal_2M}) no-repeat center center/cover`,
                                                fontFamily: "'Roboto Slab', serif",
                                            }}
                                        >
                                            <Block height={"100px"} />
                                            <Row
                                                style={{
                                                    marginRight: "1px",
                                                    marginLeft: "1px"
                                                }}
                                            >
                                                <center>
                                                    <Label
                                                        style={{
                                                            color: colors.gpm.blanco,
                                                            fontSize: "35px",
                                                        }}
                                                    >
                                                        El mejor invento del<br />
                                                        hombre después de la <br />
                                                        es la Domino's
                                                    </Label>
                                                </center>
                                            </Row>
                                            <Row
                                                style={{
                                                    marginRight: "1px",
                                                    marginLeft: "1px"
                                                }}
                                            >
                                                <center>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                            color: colors.gpm.blanco,
                                                            marginTop: "60px",
                                                            fontSize: "40px",
                                                        }}
                                                        id="iniciarUnidadesDominos"
                                                    >
                                                        {
                                                            this.state.iniciarUnidadesDominos ?
                                                                <CountUp start={0} end={36} duration={6} />
                                                                :
                                                                "0"
                                                        }

                                                    </Label>
                                                    <br />
                                                    <Label
                                                        style={{
                                                            marginTop: "-30px",
                                                            color: colors.gpm.blanco,
                                                            fontSize: "40px"
                                                        }}
                                                    >
                                                        Unidades
                                                    </Label>
                                                </center>
                                            </Row>
                                        </section>
                                        <section
                                            style={{
                                                width: "100%",
                                                height: "100vh",
                                                backgroundColor: colors.gpm.celeste,
                                                background: `url(${imgPrincipal_3M}) no-repeat center center/cover`,
                                                fontFamily: "'Roboto Slab', serif",
                                            }}
                                        >
                                            <Block height={"80px"} />
                                            <Row
                                                style={{
                                                    marginRight: "10px",
                                                    marginLeft: "10px"
                                                }}
                                            >
                                                <center>
                                                    <Label
                                                        style={{
                                                            color: colors.gpm.blanco,
                                                            fontSize: "40px",
                                                        }}
                                                    >
                                                        Comer es <br />
                                                        un placer
                                                    </Label>
                                                </center>
                                            </Row>
                                            <Row
                                                style={{
                                                    marginRight: "10px",
                                                    marginLeft: "10px"
                                                }}
                                            >
                                                <center>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                            color: colors.gpm.blanco,
                                                            marginTop: "60px",
                                                            fontSize: "40px",
                                                        }}
                                                        id="iniciarUnidadesArbys"
                                                    >
                                                        {
                                                            this.state.iniciarUnidadesArbys ?
                                                                <CountUp start={0} end={2} duration={6} />
                                                                :
                                                                "0"
                                                        }

                                                    </Label>
                                                    <br />
                                                    <Label
                                                        style={{
                                                            marginTop: "-30px",
                                                            color: colors.gpm.blanco,
                                                            fontSize: "40px"
                                                        }}
                                                    >
                                                        Unidades
                                                    </Label>
                                                </center>
                                            </Row>
                                        </section>
                                        <section
                                            style={{
                                                width: "100%",
                                                height: "100vh",
                                                backgroundColor: colors.gpm.celeste,
                                                background: `url(${imgPrincipal_4M}) no-repeat center center/cover`,
                                                fontFamily: "'Roboto Slab', serif",
                                            }}
                                        >
                                            <Block height={"80px"} />
                                            <Row
                                                style={{
                                                    marginRight: "10px",
                                                    marginLeft: "10px"
                                                }}
                                            >
                                                <center>
                                                    <Label
                                                        style={{
                                                            color: colors.sushiitto.morado,
                                                            fontSize: "30px",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Por muy rico que se vea <br />
                                                        recuerda que se vale <br />
                                                        compartir
                                                    </Label>
                                                </center>
                                            </Row>
                                            <Row
                                                style={{
                                                    marginRight: "10px",
                                                    marginLeft: "10px"
                                                }}
                                            >
                                                <center>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                            color: colors.sushiitto.morado,
                                                            marginTop: "60px",
                                                            fontSize: "40px",
                                                        }}
                                                        id="iniciarUnidadesSushiItto"
                                                    >
                                                        {
                                                            this.state.iniciarUnidadesSushiItto ?
                                                                <CountUp start={0} end={2} duration={6} />
                                                                :
                                                                "0"
                                                        }

                                                    </Label>
                                                    <br />
                                                    <Label
                                                        style={{
                                                            marginTop: "-30px",
                                                            color: colors.sushiitto.morado,
                                                            fontSize: "40px",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Unidades
                                                    </Label>
                                                </center>
                                            </Row>
                                        </section>
                                    </>
                            }


                        </>
                }

                <Modal
                    isOpen={this.state.isOpenModalDescriptionMarcaGPM}
                    size="lg"
                    style={{
                        maxWidth: "70%"
                    }}
                    onOpened={() => {
                        components.sleep(0).then(() => {
                            this.setState({
                                handle: new Date()
                            });
                        });
                    }}
                >
                    <ModalHeader
                        close={
                            <IconButton
                                onClick={() => {
                                    this.setState({
                                        isOpenModalDescriptionMarcaGPM: false,
                                    });
                                }}
                            >
                                <Close />
                            </IconButton>
                        }
                    >
                        <Row>
                            <Col>
                                <Label
                                    style={{
                                        color: colors.gpm.cafe,
                                        fontSize: "26px",
                                        fontWeight: "bold"
                                    }}
                                >
                                    Grupo Penagos Meneses (GPM)
                                </Label>
                            </Col>
                        </Row>
                    </ModalHeader>
                    <ModalBody>
                        <div style={{ height: "50px" }} ></div>
                        <Row
                            style={{
                                marginTop: "20px"
                            }}
                        >
                            <Col
                                style={{
                                    maxWidth: "25%"
                                }}
                            >
                                <center>
                                    <Jump spy={this.state.handle}>
                                        <img
                                            id="imgLogoGPMBlanco"
                                            src={imgLogoGPMBlanco}
                                            alt="No imagen"
                                            height={150}
                                            style={{
                                                transform: this.state.isHoverimgLogoGPMBlanco ? 'scale(1.2)' : 'scale(1)',
                                                transition: 'transform 0.3s ease',
                                                cursor: "pointer"
                                            }}
                                        />
                                    </Jump>
                                </center>
                            </Col>
                            <Col
                                style={{
                                    maxWidth: "75%",
                                    textAlign: "justify",
                                    marginRight: "50px"
                                }}
                            >
                                <Jump spy={this.state.handle} >
                                    <p
                                        style={{
                                            color: colors.gpm.cafe,
                                            fontSize: "22px",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        Somos un grupo empresarial con un portafolios de franquicias (Domino's GPM, Arby's GPM y Shushiitto GPM), nuestro equipo de trabajo se basa en la cultura WOW, con la mentalidad de sumar y de crecer con nosotros, somos Grupo Penagos Meneses somos GPM
                                    </p>
                                </Jump>
                            </Col>
                        </Row>
                        <div style={{ height: "50px" }} ></div>
                    </ModalBody>
                </Modal>
            </>
        );
    }

}

export default PaginaPrincipal;