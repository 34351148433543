import { Component } from "react";
import SNavBarComputer from "./NavBar/GPM/SNavBarComputer";
import ScreenTools from "../config/screen.config";
import components from "../tools/components.tool";
import { Col, Label, Row } from "reactstrap";


import colors from "../config/colors.config";
import { Fade } from "react-reveal";
import { Looks3, Looks4, Looks5, Looks6, LooksOne, LooksTwo } from "@material-ui/icons";
import SNavBarMovil from "./NavBar/GPM/SNavBarMovil";

class EnfoqueAlCliente extends Component {
    constructor(props) {
        super(props);
        this.state = {
            device: {
                isDesktopDevice: null,
                isMobileDevice: null,
                screenHeight: null,
                screenWidth: null,
            },
        }
    }
    componentDidMount() {
        this.cargarDatosPantalla();
    }

    cargarDatosPantalla = () => {

        let device = ScreenTools.getDeviceSettings()

        // console.log(device);
        this.setState({
            device
        });

        components.sleep(1.5).then(() => {
            this.setState({ estaCargando: false });
        });
    }

    render() {
        return (
            <>
                {this.state.device.isDesktopDevice ? <SNavBarComputer clickOnHome={null} isMain={false} /> : <SNavBarMovil />}
                <section
                    style={{
                        width: "100%",
                        height: this.state.device.isDesktopDevice ? "100vh" : "auto",
                        backgroundColor: colors.gpm.azul
                    }}
                >
                    <div style={{ height: "100px" }} ></div>
                    <Fade>
                        <Row
                            style={{
                                marginRight: this.state.device.isDesktopDevice ? "50px" : "10px",
                                marginLeft: this.state.device.isDesktopDevice ? "50px" : "10px",
                                padding: "10px",
                                backgroundColor: "#FFFFFF",
                                borderRadius: "10px",
                                borderWidth: "6px",
                                borderStyle: "solid",
                                borderColor: "#045071",
                                color: "#045071"
                            }}

                        >
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: this.state.device.isDesktopDevice ? "30px" : "26px",
                                }}
                            >
                                Nuestro Enfoque al Cliente
                            </Label>
                        </Row>
                        <div style={{ height: "10px" }} ></div>
                        <Row
                            style={{
                                marginRight: this.state.device.isDesktopDevice ? "50px" : "10px",
                                marginLeft: this.state.device.isDesktopDevice ? "50px" : "10px",
                                backgroundColor: colors.gpm.blanco_1,
                                borderRadius: "10px",
                                height: "auto"
                            }}
                        >

                            <Col
                                style={{
                                    maxWidth: this.state.device.isDesktopDevice ? "50%" : "",
                                    color: colors.gpm.azul,
                                    fontWeight: "bold",
                                    fontSize: "18px",
                                    fontFamily: "'Roboto Slab', serif",
                                }}
                                md={this.state.device.isMobileDevice ? "3" : ""}
                            >
                                <Row
                                    style={{
                                        marginLeft: this.state.device.isDesktopDevice ? "10px" : "1px",
                                        marginTop: "20px"
                                    }}
                                >
                                    <Label>
                                        <LooksOne /> Ofrecer experiencias WOW a nuestros clientes.
                                    </Label>
                                </Row>
                                <Row
                                    style={{
                                        marginLeft: this.state.device.isDesktopDevice ? "10px" : "1px",
                                        marginTop: "20px"
                                    }}
                                >
                                    <Label>
                                        <LooksTwo /> Cuidamos el negocio con un compromiso total.
                                    </Label>
                                </Row>
                                <Row
                                    style={{
                                        marginLeft: this.state.device.isDesktopDevice ? "10px" : "1px",
                                        marginTop: "20px"
                                    }}
                                >
                                    <Label>
                                        <Looks3 /> Potenciamos el liderazgo de nuestros teams.
                                    </Label>
                                </Row>
                                <Row
                                    style={{
                                        marginLeft: this.state.device.isDesktopDevice ? "10px" : "1px",
                                        marginTop: "20px"
                                    }}
                                >
                                    <Label>
                                        <Looks4 /> Nos destacamos por nuestra agilidad y simplicidad, logrando más con menos.
                                    </Label>
                                </Row>
                                <Row
                                    style={{
                                        marginLeft: this.state.device.isDesktopDevice ? "10px" : "1px",
                                        marginTop: "20px"
                                    }}
                                >
                                    <Label>
                                        <Looks5 /> Potenciamos el liderazgo de nuestros teams.
                                    </Label>
                                </Row>
                                <Row
                                    style={{
                                        marginLeft: this.state.device.isDesktopDevice ? "10px" : "1px",
                                        marginTop: "20px"
                                    }}
                                >
                                    <Label>
                                        <Looks6 /> En nuestro día a día, el aprendizaje se combina con el disfrute y la diversión, creando una experiencia wow para todos.
                                    </Label>
                                </Row>
                            </Col>
                            {
                                this.state.device.isDesktopDevice ?

                                    <Col
                                        style={{
                                            maxWidth: this.state.device.isDesktopDevice ? "50%" : "",
                                        }}
                                        md={this.state.device.isMobileDevice ? "3" : ""}
                                    >
                                        <div
                                            style={{
                                                backgroundColor: colors.gpm.blanco,
                                                marginTop: "10px",
                                                height: this.state.device.isDesktopDevice ? "93%" : "",
                                                borderRadius: "10px"
                                            }}
                                        >
                                            <center>
                                                <lottie-player
                                                    autoplay
                                                    // loop                                    
                                                    mode="normal"
                                                    src={"https://assets1.lottiefiles.com/packages/lf20_pqnfmone.json"}
                                                    speed={0.75}
                                                    style={{
                                                        width: this.state.device.isDesktopDevice ? "300px" : "300px",
                                                        height: this.state.device.isDesktopDevice ? "300px" : "300px",
                                                    }}
                                                />
                                            </center>
                                        </div>

                                    </Col>
                                    :
                                    null
                            }
                        </Row>
                        <div style={{ height: "30px" }} ></div>
                    </Fade>
                </section>
            </>
        );
    }

}

export default EnfoqueAlCliente;