import { Component } from "react";
import SNavBarComputer from "./NavBar/GPM/SNavBarComputer";
import ScreenTools from "../config/screen.config";
import components from "../tools/components.tool";
import { Col, Label, Row } from "reactstrap";
import { Player } from '@lottiefiles/react-lottie-player';
import WowSticker from "../assets/looties-files/WowSticker.json";
import logowow from "../assets/image/logowow.png";
import colors from "../config/colors.config";
import { Fade } from "react-reveal";
import { Looks3, Looks4, Looks5, Looks6, LooksOne, LooksTwo } from "@material-ui/icons";
import SNavBarMovil from "./NavBar/GPM/SNavBarMovil";

class CulturaWow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            device: {
                isDesktopDevice: null,
                isMobileDevice: null,
                screenHeight: null,
                screenWidth: null,
            },
        }
    }
    componentDidMount() {
        this.cargarDatosPantalla();
    }

    cargarDatosPantalla = () => {

        let device = ScreenTools.getDeviceSettings()

        // console.log(device);
        this.setState({
            device
        });

        components.sleep(1.5).then(() => {
            this.setState({ estaCargando: false });
        });
    }

    render() {
        return (
            <>
                {this.state.device.isDesktopDevice ? <SNavBarComputer clickOnHome={null} isMain={false} /> : <SNavBarMovil />} 
                <section
                    style={{
                        width: "100%",
                        height: "auto",
                        backgroundColor: colors.gpm.azul
                    }}
                >
                    <div style={{ height: "75px" }} ></div>
                    <Fade>
                        <Row
                            style={{
                                marginRight: this.state.device.isDesktopDevice ? "50px" : "10px",
                                marginLeft: this.state.device.isDesktopDevice ? "50px" : "10px",
                                padding: "10px",
                                backgroundColor: "#FFFFFF",
                                borderRadius: "10px",
                                borderWidth: "6px",
                                borderStyle: "solid",
                                borderColor: "#045071",
                                color: "#045071"
                            }}
                        >
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "30px",
                                }}
                            >
                                Cultura WOW
                            </Label>
                        </Row>
                        <div style={{ height: "10px" }} ></div>
                        <Row
                            style={{
                                marginRight: this.state.device.isDesktopDevice ? "50px" : "10px",
                                marginLeft: this.state.device.isDesktopDevice ? "50px" : "10px",
                                backgroundColor: colors.gpm.blanco_1,
                                borderRadius: "10px",
                                padding: "10px"
                            }}
                        >
                            <p
                                style={{
                                    color: colors.gpm.azul,
                                    fontWeight: "bold",
                                    fontSize: "18px",
                                    fontFamily: "'Roboto Slab', serif",
                                    textAlign: "justify",
                                }}
                            >
                                La "Cultura WOW" de GPM se refiere a la filosofía y los valores que la compañía promueve para crear experiencias
                                excepcionales tanto para sus empleados como para sus clientes. GPM ha destacado por su enfoque en la calidad
                                de los productos, la innovación y la satisfacción del cliente. A continuación, se presentan algunos aspectos
                                clave de la "Cultura WOW" de GPM:
                            </p>
                        </Row>
                        <div style={{ height: "10px" }} ></div>
                        <Row
                            style={{
                                marginRight:  this.state.device.isDesktopDevice ? "50px" : "10px",
                                marginLeft:  this.state.device.isDesktopDevice ? "50px" : "10px",
                                backgroundColor: colors.gpm.blanco_1,
                                borderRadius: "10px"
                            }}
                        >
                            {this.state.device.isDesktopDevice ?
                                <Col
                                    style={{
                                        maxWidth: "30%"
                                    }}
                                >

                                    <div
                                        style={{
                                            backgroundColor: colors.gpm.blanco,
                                            marginTop: "05px",
                                            height: "93%",
                                            borderRadius: "10px"
                                        }}
                                    >
                                        <center>
                                            <img
                                                src={logowow}
                                                style={{
                                                    width: "300px",
                                                    height: "300px",
                                                }}
                                            />
                                        </center>
                                    </div>

                                </Col>
                                :
                                null
                            }
                            <Col
                                style={{
                                    maxWidth: this.state.device.isDesktopDevice ? "70%" : "100%",
                                    color: colors.gpm.azul,
                                    fontWeight: "bold",
                                    fontSize: "18px",
                                    fontFamily: "'Roboto Slab', serif",
                                }}
                            >
                                <Row
                                    style={{
                                        ////marginLeft: "10px",
                                        marginTop: "20px",
                                        textAlign: "justify",
                                    }}
                                >
                                    <Label>
                                        <LooksOne /> Enfoque en la Calidad y la Innovación: GPM se esfuerza por ofrecer productos de alta calidad, desde
                                        ingredientes frescos hasta tecnología innovadora. La compañía se ha destacado por sus esfuerzos en mejorar
                                        constantemente sus recetas y procesos de producción para brindar una experiencia deliciosa y consistente.
                                    </Label>
                                </Row>
                                <Row
                                    style={{
                                        //marginLeft: "10px",
                                        marginTop: "20px",
                                        textAlign: "justify",
                                    }}
                                >
                                    <Label>
                                        <LooksTwo /> Compromiso con el Cliente WOW: La "Cultura WOW" de GPM se centra en superar las expectativas de los
                                        clientes. La compañía busca no solo satisfacer, sino sorprender a sus clientes con un servicio excepcional y
                                        productos sobresalientes.
                                    </Label>
                                </Row>
                                <Row
                                    style={{
                                        //marginLeft: "10px",
                                        marginTop: "20px",
                                        textAlign: "justify",
                                    }}
                                >
                                    <Label>
                                        <Looks3 /> Eficiencia y Entrega Rápida: GPM valora la eficiencia en la entrega de sus productos. La compañía
                                        ha invertido en logística y procesos optimizados para garantizar que las entregas se realicen de manera eficiente
                                        y puntual.
                                    </Label>
                                </Row>
                                <Row
                                    style={{
                                        //marginLeft: "10px",
                                        marginTop: "20px",
                                        textAlign: "justify",
                                    }}
                                >
                                    <Label>
                                        <Looks4 /> Innovación Tecnológica: GPM ha abrazado la tecnología para mejorar la experiencia del cliente. La
                                        compañía ha implementado soluciones tecnológicas como aplicaciones móviles y sistemas de seguimiento de pedidos
                                        para facilitar la interacción con los clientes.
                                    </Label>
                                </Row>
                                <Row
                                    style={{
                                        //marginLeft: "10px",
                                        marginTop: "20px",
                                        textAlign: "justify",
                                    }}
                                >
                                    <Label>
                                        <Looks5 /> Cuidado de los Empleados: La cultura de GPM valora a sus empleados y busca brindar un entorno laboral
                                        positivo y de apoyo. La compañía ofrece oportunidades de desarrollo profesional y capacitación para que sus
                                        empleados crezcan en sus roles.
                                    </Label>
                                </Row>
                                <Row
                                    style={{
                                        //marginLeft: "10px",
                                        marginTop: "20px",
                                        textAlign: "justify",
                                    }}
                                >
                                    <Label>
                                        <Looks6 /> Compromiso Social: GPM se involucra en diversas iniciativas sociales y comunitarias. La compañía se
                                        preocupa por causas como la responsabilidad ambiental y el bienestar de la comunidad, contribuyendo positivamente
                                        a las áreas en las que opera.
                                    </Label>
                                </Row>
                            </Col>
                        </Row>
                        <div style={{ height: "10px" }} ></div>
                        <Row
                            style={{
                                marginRight:  this.state.device.isDesktopDevice ? "50px" : "10px",
                                marginLeft:  this.state.device.isDesktopDevice ? "50px" : "10px",
                                backgroundColor: colors.gpm.blanco_1,
                                borderRadius: "10px",
                                padding: "10px"
                            }}
                        >
                            <p
                                style={{
                                    color: colors.gpm.azul,
                                    fontWeight: "bold",
                                    fontSize: "18px",
                                    fontFamily: "'Roboto Slab', serif",
                                    textAlign: "justify",
                                }}
                            >
                                En resumen, la "Cultura WOW" de GPM se basa en la búsqueda constante de la excelencia en todas las áreas de su negocio,
                                desde la calidad de los productos hasta la satisfacción del cliente y el bienestar de sus empleados. Es una cultura que
                                busca sorprender y deleitar, creando experiencias memorables para todos aquellos que interactúan con la marca.
                            </p>
                        </Row>
                        <div style={{ height: "30px" }} ></div>
                    </Fade>
                </section>
            </>
        );
    }

}

export default CulturaWow;