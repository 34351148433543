import { Component } from "react";
import { Col, Row } from "reactstrap";
import colors from "../../config/colors.config";
import NavBar from "../NavBar/GPM/NavBar";

class PaginaNoEncontrada extends Component {

    constructor(props) {
        super(props);
        this.state = {
            device: {
                isDesktopDevice: null,
                isMobileDevice: null,
                screenHeight: null,
                screenWidth: null,
            },
        }
    }

    componentDidMount() {
        this.cargarDatosPantalla();
    }

    cargarDatosPantalla = () => {
        let { device } = this.props;
        // console.log(device);
        this.setState({
            device
        });
    }


    render() {
        return (
            <>
                <NavBar device={this.state.device} />
                <div
                    style={{
                        marginTop: "80px",
                        marginRight: "20%",
                        marginLeft: "20%",
                        borderRadius: "8px",
                        boxShadow: `1px 1px 2px 2px ${colors.gpm.azul}`,
                    }}
                >
                    <Row>
                        <Col>
                            <center>
                                <lottie-player
                                    autoplay
                                    // loop
                                    mode="normal"
                                    src="https://assets5.lottiefiles.com/packages/lf20_jimgbh4o.json"
                                    speed={0.75}
                                    style={{
                                        width: "350px",
                                        height: "350px",
                                        marginTop: "40px"
                                    }}
                                />
                            </center>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <center>
                                <h1
                                    style={{
                                        color: colors.gpm.azul
                                    }}
                                >
                                    Pagina no encontrada
                                </h1>
                            </center>
                        </Col>
                    </Row>
                    <div style={{ height: "10px" }} > </div>
                    <Row>
                        <Col>
                            <center>
                                <h2
                                    style={{
                                        color: colors.gpm.azul
                                    }}
                                >
                                    No se pudo encontrar la página que está buscando.
                                </h2>
                            </center>
                        </Col>
                    </Row>
                    <div style={{ height: "10px" }} > </div>
                    <Row >
                        <Col>
                            <center>
                                <h3
                                    style={{
                                        color: colors.gpm.azul,
                                        cursor: "pointer"
                                    }}
                                    onClick={() => {
                                        window.location.href = "/"
                                    }}
                                >
                                    Click aquí para ir a la pagina principal
                                </h3>
                            </center>
                        </Col>
                    </Row>
                    <div style={{ height: "20px" }} > </div>
                </div>
            </>
        );
    }

}

export default PaginaNoEncontrada;