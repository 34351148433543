import { Component } from "react";
import SNavBarComputer from "./NavBar/GPM/SNavBarComputer";
import EnContruccion from "./template/EnContruccion";
import ScreenTools from "../config/screen.config";
import colors from "../config/colors.config";
import { Label, Row } from "reactstrap";
import etica from "../assets/image/etica.jpg";
import empleados from "../assets/image/empleados.jpg";
import { Fade } from "react-reveal";
import SNavBarMovil from "./NavBar/GPM/SNavBarMovil";

class NuestraEtica extends Component {

    constructor(props) {
        super(props);
        this.state = {
            device: {
                isDesktopDevice: null,
                isMobileDevice: null,
                screenHeight: null,
                screenWidth: null,
            },
        }
    }
    componentDidMount() {
        this.cargarDatosPantalla();
    }

    cargarDatosPantalla = () => {

        let device = ScreenTools.getDeviceSettings()

        // console.log(device);
        this.setState({
            device
        });

    }

    render() {
        return (
            <>
                {this.state.device.isDesktopDevice ? <SNavBarComputer clickOnHome={null} isMain={false} /> : <SNavBarMovil />}
                <section
                    style={{
                        width: "100%",
                        // height: this.state.device.isDesktopDevice ? "100vh" : "auto",
                        backgroundColor: colors.gpm.azul,
                    }}
                >
                    <div style={{ height: this.state.device.isDesktopDevice ? "70px" : "64px" }} ></div>
                    <Fade>
                        <div
                            style={{
                                // width:"70%",
                                height: "35vh",
                                // marginRight: "50px",
                                // marginLeft: "50px",
                                background: `url(${etica}) no-repeat center center/cover`,
                                // borderRadius: "10px"
                            }}
                        >
                            <div style={{ height: this.state.device.isDesktopDevice ? "100px" : "50px" }} ></div>
                            <Row
                                style={{
                                    marginRight: this.state.device.isDesktopDevice ? "15%" : "10px",
                                    marginLeft: this.state.device.isDesktopDevice ? "15%" : "10px",
                                    backgroundColor: 'rgba(255, 255, 255, 0.15)',
                                    borderRadius: '10px',
                                    backdropFilter: 'blur(5px)',
                                    padding: '20px',
                                }}
                            >
                                <center>
                                    <Label
                                        style={{
                                            fontFamily: "'Roboto Slab', serif",
                                            color: this.state.device.isDesktopDevice ? colors.gpm.blanco : "#000",
                                            fontWeight: "bold",
                                            fontSize: "30px"
                                        }}
                                    >
                                        Nuestra Ética
                                    </Label>
                                </center>
                            </Row>

                        </div>
                    </Fade>
                    <div
                        style={{
                            height: "65vh",
                            marginRight: this.state.device.isDesktopDevice ? "15%" : "10px",
                            marginLeft: this.state.device.isDesktopDevice ? "15%" : "10px",
                            fontFamily: "'Roboto Slab', serif",
                        }}
                    >
                        <div style={{ height: "50px" }} ></div>
                        <Row
                            style={{
                                marginRight: "1px",
                                marginLeft: "1px",
                            }}
                        >
                            <Fade top>
                                <p
                                    style={{
                                        fontSize: this.state.device.isDesktopDevice ? "24px" : "18px",
                                        textAlign: "justify",
                                        color: colors.gpm.blanco,
                                        fontWeight: "bold"
                                    }}
                                >
                                    En GPM, la satisfacción de nuestros clientes es nuestra máxima prioridad. Por lo tanto,
                                    buscamos eficiencia en nuestros procesos wow, gente wow y estándares wow, asegurando así
                                    la calidad de nuestro servicio al cliente wow. Estos principios son fundamentales para
                                    todos los temas en nuestra organización.
                                </p>
                            </Fade>
                        </Row>
                        <Row
                            style={{
                                marginRight: "1px",
                                marginLeft: "1px",
                            }}
                        >
                            <Fade top>
                                <p
                                    style={{
                                        fontSize: this.state.device.isDesktopDevice ? "24px" : "18px",
                                        textAlign: "justify",
                                        color: colors.gpm.blanco,
                                        fontWeight: "bold"
                                    }}
                                >
                                    Cumpliendo con los más altos estándares de calidad, nuestros productos son creados y presentados
                                    en estricta observancia de las regulaciones de protección al consumidor, salud y seguridad
                                    alimentaria. Nuestra dedicación se centra en garantizar que todas las evaluaciones, inspecciones y
                                    aspectos relacionados sean precisos, exhaustivos y puntuales. Nuestro personal se compromete a
                                    manejar los alimentos de acuerdo con nuestras políticas internas, asegurando una
                                    confiable y segura para todos nuestros clientes wow.
                                </p>
                            </Fade>
                        </Row>
                    </div>
                </section>
                <section
                    style={{
                        width: "100%",
                        height: this.state.device.isDesktopDevice ? "150vh" : "auto",// height: "150vh",
                        backgroundColor: colors.gpm.azul,
                    }}
                >
                    <div style={{ height: this.state.device.isDesktopDevice ? "100px" : "250px" }} ></div>
                    <Fade>
                        
                        <div
                            style={{
                                height: this.state.device.isDesktopDevice ? "35vh" : "300px",
                                background: `url(${empleados}) no-repeat center center/cover`,
                            }}
                        >
                            <div style={{ height: "100px" }} ></div>

                            <Row
                                style={{

                                    marginRight: this.state.device.isDesktopDevice ? "15%" : "10px",
                                    marginLeft: this.state.device.isDesktopDevice ? "15%" : "10px",
                                    backgroundColor: 'rgba(255, 255, 255, 0.15)',
                                    borderRadius: '10px',
                                    backdropFilter: 'blur(5px)',
                                    padding: '20px',
                                }}
                            >
                                <center>
                                    <Label
                                        style={{
                                            fontFamily: "'Roboto Slab', serif",
                                            color: colors.gpm.blanco,
                                            fontWeight: "bold",
                                            fontSize: "30px"
                                        }}
                                    >
                                        NUESTRO COMPROMISO CON NUESTROS TEAMS
                                    </Label>
                                </center>
                            </Row>

                        </div>
                    </Fade>
                    <div
                        style={{
                            height: this.state.device.isDesktopDevice ? "115vh" : "auto",
                            marginRight: this.state.device.isDesktopDevice ? "15%" : "10px",
                            marginLeft: this.state.device.isDesktopDevice ? "15%" : "10px",
                            fontFamily: "'Roboto Slab', serif",
                        }}
                    >
                        <div style={{ height: "50px" }} ></div>
                        <Row
                            style={{
                                marginRight: "1px",
                                marginLeft: "1px",
                            }}
                        >
                            <Fade top>
                                <p
                                    style={{
                                        fontSize: this.state.device.isDesktopDevice ? "24px" : "18px",
                                        textAlign: "justify",
                                        color: colors.gpm.blanco,
                                        fontWeight: "bold"
                                    }}
                                >
                                    Nuestros teams que conforman nuestra organización es el activo más valioso de
                                    Grupo Penagos Meneses, ya que su contribución es esencial para lograr nuestras
                                    operaciones wow. Nuestro compromiso radica en proporcionar formación que promueva
                                    tanto el crecimiento profesional como personal de nuestros teams, incorporando los
                                    principios éticos y morales que sustentan nuestro Código de Conducta.
                                </p>
                            </Fade>
                        </Row>
                        <Row
                            style={{
                                marginRight: "1px",
                                marginLeft: "1px",
                            }}
                        >
                            <Fade top>
                                <p
                                    style={{
                                        fontSize: this.state.device.isDesktopDevice ? "24px" : "18px",
                                        textAlign: "justify",
                                        color: colors.gpm.blanco,
                                        fontWeight: "bold"
                                    }}
                                >
                                    Nuestros procesos de gestión de recursos humanos se fundamentan en el respeto a la dignidad y los derechos
                                    de cada individuo. Mantenemos un firme compromiso con la creación y el mantenimiento de un entorno laboral
                                    positivo, exento de discriminación, acoso y represalias.
                                </p>
                            </Fade>
                        </Row>
                        <Row
                            style={{
                                marginRight: "1px",
                                marginLeft: "1px",
                            }}
                        >
                            <Fade top>
                                <p
                                    style={{
                                        fontSize: this.state.device.isDesktopDevice ? "24px" : "18px",
                                        textAlign: "justify",
                                        color: colors.gpm.blanco,
                                        fontWeight: "bold"
                                    }}
                                >
                                    En este sentido, rechazamos enfáticamente cualquier forma de discriminación basada en raza, etnia, género,
                                    religión, orientación sexual, afiliación sindical, ideología política, situación familiar o discapacidad.
                                    Asimismo, estamos firmemente en contra de cualquier tipo de acoso verbal o físico relacionado con los
                                    aspectos mencionados anteriormente o con cualquier otro motivo. Este compromiso se aplica tanto en las
                                    interacciones internas como en las decisiones organizativas, así como en nuestras relaciones con clientes wow,
                                    franquiciados, proveedores y terceros.
                                </p>
                            </Fade>
                        </Row>
                        <Row
                            style={{
                                marginRight: "1px",
                                marginLeft: "1px",
                            }}
                        >
                            <Fade top>
                                <p
                                    style={{
                                        fontSize: this.state.device.isDesktopDevice ? "24px" : "18px",
                                        textAlign: "justify",
                                        color: colors.gpm.blanco,
                                        fontWeight: "bold"
                                    }}
                                >
                                    En Grupo Penagos Meneses, nuestra cultura wow se basa en el respeto mutuo y la igualdad de oportunidades,
                                    asegurando un entorno laboral en el que cada individuo pueda florecer y contribuir al éxito colectivo.
                                </p>
                                <div style={{ height: "50px" }} ></div>
                            </Fade>
                        </Row>
                       
                    </div>
                   
                </section>
            </>
        );
    }
}

export default NuestraEtica;