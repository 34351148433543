import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import "@lottiefiles/lottie-player";
/* Views de GPM */
import PaginaPrincipal from "./pages/PaginaPrincipal";
import FilosofiaGPM from "./pages/FilosofiaGPM";
import NuestraHistoria from "./pages/NuestraHistoria";
import EnfoqueAlCliente from "./pages/EnfoqueAlCliente";
import PaginaNoEncontrada from "./pages/template/PaginaNoEncontrada";
import NuestrosLogros from "./pages/NuestrosLogros";
import NuestrasMarcas from "./pages/NuestrasMarcas";
import CulturaWow from "./pages/CulturaWow";
import NuestroAporte from "./pages/NuestroAporte";
import NuestraEtica from "./pages/NuestraEtica";
import BienesRaices from "./pages/BienesRaices";

/* Vistas de la marcas Arby's de GPM */
import Arbys from "./pages/marcas/Arbys";
import ArbysUnidades from "./pages/marcas/ArbysUnidades";

/* Vistas de la Domino's GPM */
import Dominos from "./pages/marcas/Dominos";
import DominosUnidades from "./pages/marcas/DominosUnidades";
import SDominos from "./pages/marcas/SDominos";
/* Vistas de la Sushiitto GPM */
import Sushiitto from "./pages/marcas/Sushiitto";
import SushiittoUnidades from "./pages/marcas/SushiittoUnidades";

/* Vistas de las plantillas */
import EnContruccion from "./pages/template/EnContruccion";


function App() {

  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;
  let isDesktopDevice = !regexp.test(details); 
  let isMobileDevice = !isDesktopDevice;  
  let screenHeight = window.innerHeight;
  let screenWidth = window.innerWidth;

  let device = {
    isDesktopDevice,
    isMobileDevice,
    screenHeight,
    screenWidth
  }

  return (
    <Router>
      <Routes>          

        {/* Rutas de GPM */}
        <Route exact path="/" element={<PaginaPrincipal  device={device} />}/>       
        <Route exact path="/filosofia" element={<FilosofiaGPM />}/>    
        <Route exact path="/nuestras-marcas" element={<NuestrasMarcas />}/>    
        <Route exact path="/nuestra-historia" element={<NuestraHistoria  device={device} />}/>  
        <Route exact path="/enfoque-al-cliente" element={<EnfoqueAlCliente />}/>    
        <Route exact path="/culturawow" element={<CulturaWow />}/>  
        <Route exact path="/nuestro-aporte" element={<NuestroAporte />}/>     
        <Route exact path="/nuestra-etica" element={<NuestraEtica />}/>  
        <Route exact path="/bienes-raices" element={<BienesRaices />}/>  
        
        
        <Route exact path="/nuestros-logros" element={<NuestrosLogros  device={device} />}/>    

        {/* Rutas de las margas de Arby's GPM */}
        <Route exact path="/gpm/arbys" element={<Arbys  device={device} />}/>
        <Route exact path="/gpm/arbys/unidades" element={<ArbysUnidades  device={device} />}/>        

        
        <Route exact path="/gpm/dominos" element={<SDominos device={device} />}/>{/* Rutas de las margas de Domino's GPM */}
        <Route exact path="/gpm/sdominos" element={<Dominos/>}/>
        <Route exact path="/gpm/dominos/unidades" element={<DominosUnidades  device={device} />}/>      

        {/* Rutas de las margas de sushiitto GPM */}
        <Route exact path="/gpm/sushiitto" element={<Sushiitto  device={device} />}/>
        <Route exact path="/gpm/sushiitto/unidades" element={<SushiittoUnidades  device={device} />}/>  

        <Route exact path="/test" element={<vPaginaPrincipal  device={device} />}/>  
            
        {/* Direccionamiento a rutas no encontradas */}
        <Route path='*'  element={<PaginaNoEncontrada device={device} />}/> 
        
      </Routes>
  </Router>
  );
}

export default App;
