import { Component } from "react";
import SNavBarComputer from "./NavBar/GPM/SNavBarComputer";
import ScreenTools from "../config/screen.config";
import { Col, Label, Row } from "reactstrap";
import colors from "../config/colors.config";
import imgHome from "../assets/image/home.svg";
import line_1 from "../assets/image/888.png";
import line_2 from "../assets/image/999.png";
import SNavBarMovil from "./NavBar/GPM/SNavBarMovil";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Block from "../tools/Block";

class BienesRaices extends Component {

    constructor(props) {
        super(props);
        this.state = {
            device: {
                isDesktopDevice: null,
                isMobileDevice: null,
                screenHeight: null,
                screenWidth: null,
            },
        }
    }

    componentDidMount() {
        this.cargarDatosPantalla();
    }

    cargarDatosPantalla = () => {

        let device = ScreenTools.getDeviceSettings();

        this.setState({
            device,
        });

    }

    render() {
        return (
            <>
                {this.state.device.isDesktopDevice ? <SNavBarComputer clickOnHome={null} isMain={false} /> : <SNavBarMovil />}

                {
                    this.state.device.isDesktopDevice ?
                        <>
                            <section
                                style={{
                                    backgroundColor: "#1179AC",
                                    width: "100%",
                                    height: "100vh",
                                    background: `url(${line_1}) no-repeat center center/cover`,
                                }}
                            >
                                <div style={{ height: "100px" }} ></div>
                                <Row
                                    style={{
                                        marginRight: "17%",
                                        marginLeft: "17%",
                                    }}
                                >
                                    <center>
                                        <Label
                                            style={{
                                                fontSize: "30px",
                                                // fontFamily: "'Roboto Slab', serif",
                                                fontFamily: "'Roboto Slab', serif",
                                                textAlign: "left",
                                                color: colors.gpm.blanco
                                            }}
                                        >
                                            ¿Estás interesado en brindar un espacio en renta o venta para nosotros? Toma en cuenta estas características:
                                        </Label>

                                    </center>
                                </Row>
                                <div style={{ height: "20px" }} ></div>
                                <Row
                                    style={{
                                        marginRight: "17%",
                                        marginLeft: "17%",
                                        fontFamily: "'Roboto Slab', serif",
                                    }}
                                >
                                    <Col
                                        style={{
                                            maxWidth: "50%"
                                        }}
                                    >

                                        <div
                                            style={{
                                                height: "180px",
                                                width: "350px",
                                                backgroundColor: colors.gpm.blanco,
                                                borderWidth: "6px",
                                                borderStyle: "solid",
                                                borderColor: "#00587C",
                                                borderRadius: "10px",
                                            }}
                                        >
                                            <Row>
                                                <Col>
                                                    <Label
                                                        style={{
                                                            fontSize: "60px",
                                                            fontWeight: "bold",
                                                            color: "#00577B",
                                                            marginTop: "-15px"
                                                        }}
                                                    >
                                                        Ubicación:
                                                    </Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col
                                                    style={{
                                                        textAlign: "right",
                                                        lineHeight: 1,
                                                    }}
                                                >
                                                    <Label
                                                        style={{
                                                            fontSize: "25px",
                                                            color: "#00577B",
                                                        }}
                                                    >
                                                        Avenidas principales y en esquina con alta concentración
                                                    </Label>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    {/* <img src={line_1} alt="line_1" height={100} width={100} /> */}
                                    <Col
                                        style={{
                                            maxWidth: "50%",
                                        }}
                                    >
                                        <div
                                            style={{
                                                height: "250px",
                                                width: "500px",
                                                backgroundColor: colors.gpm.blanco,
                                                float: "right",
                                                borderWidth: "6px",
                                                borderStyle: "solid",
                                                borderColor: "#00587C",
                                                borderRadius: "10px",
                                            }}
                                        >
                                            <img src="" alt="" />
                                        </div>


                                    </Col>
                                </Row>
                                <Row
                                    style={{
                                        marginRight: "17%",
                                        marginLeft: "17%",
                                        fontFamily: "'Roboto Slab', serif",
                                    }}
                                >
                                    <Col>
                                        <div >
                                            <img
                                                src={imgHome}
                                                alt="imgHome"
                                                width={300}
                                                style={{
                                                    marginTop: "-75px",
                                                    marginLeft: "168px",
                                                    zIndex: 1
                                                }}
                                            />
                                        </div>

                                    </Col>
                                </Row>
                            </section>

                            <section
                                style={{
                                    background: `url(${line_2}) no-repeat center center/cover`,
                                    width: "100%",
                                    height: "100vh",
                                }}
                            >
                                <Row
                                    style={{
                                        marginRight: "17%",
                                        marginLeft: "17%",
                                        marginTop: "-50px",
                                    }}
                                >
                                    <Col>
                                        <div
                                            style={{
                                                height: "110px",
                                                width: "350px",
                                                backgroundColor: "#1179AC",
                                                borderWidth: "6px",
                                                borderStyle: "solid",
                                                borderColor: "#00587C",
                                                borderRadius: "10px",
                                                textAlign: "right",
                                                lineHeight: 1,
                                            }}
                                        >
                                            <Label
                                                style={{
                                                    color: "#6DC4E9",
                                                    fontSize: "55px",
                                                    fontFamily: "'Roboto Slab', serif",
                                                    marginRight: "10px",
                                                }}
                                            >
                                                excelente
                                            </Label>
                                            <br />
                                            <Label
                                                style={{
                                                    color: "#6DC4E9",
                                                    fontSize: "35px",
                                                    fontFamily: "'Roboto Slab', serif",
                                                    marginTop: "-10px",
                                                    marginRight: "10px",
                                                }}
                                            >
                                                visibilidad
                                            </Label>
                                        </div>
                                    </Col>
                                </Row>
                                <div style={{ height: "100px" }} ></div>
                                <Row
                                    style={{
                                        marginRight: "17%",
                                        marginLeft: "17%",
                                        borderWidth: "6px",
                                        borderStyle: "solid",
                                        borderColor: "#00587C",
                                        color: "#00577B",
                                        borderRadius: "10px",
                                        backgroundColor: colors.gpm.blanco,
                                        fontFamily: "'Roboto Slab', serif",
                                    }}

                                >
                                    <Row
                                        style={{
                                            marginRight: "1px",
                                            marginLeft: "1px",
                                        }}
                                    >
                                        <Label
                                            style={{
                                                fontSize: "24px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Envíanos un PDF con estos datos.
                                        </Label>
                                    </Row>
                                    <Row
                                        style={{
                                            marginRight: "1px",
                                            marginLeft: "1px",
                                        }}
                                    >
                                        <Label style={{ fontSize: "18px" }} > <FiberManualRecordIcon style={{ height: "20px", width: "20px" }} /> <strong>Ubicación</strong> del local/terreno en Google Maps. </Label>
                                    </Row>
                                    <Row
                                        style={{
                                            marginRight: "1px",
                                            marginLeft: "1px",
                                        }}
                                    >
                                        <Label style={{ fontSize: "18px" }} > <FiberManualRecordIcon style={{ height: "20px", width: "20px" }} /> <strong>Fotos</strong> interior/exterior. </Label>
                                    </Row>
                                    <Row
                                        style={{
                                            marginRight: "1px",
                                            marginLeft: "1px",
                                        }}
                                    >
                                        <Label style={{ fontSize: "18px" }} > <FiberManualRecordIcon style={{ height: "20px", width: "20px" }} /> <strong>Plano del local</strong>, si está en una plaza o centro comercial incluir planta completa con los arrendatarios actuales. </Label>
                                    </Row>
                                    <Row
                                        style={{
                                            marginRight: "1px",
                                            marginLeft: "1px",
                                        }}
                                    >
                                        <Label style={{ fontSize: "18px" }} > <FiberManualRecordIcon style={{ height: "20px", width: "20px" }} /> <strong>Núm. de cajones</strong> de estacionamiento exclusivos. </Label>
                                    </Row>
                                    <Row
                                        style={{
                                            marginRight: "1px",
                                            marginLeft: "1px",
                                        }}
                                    >
                                        <Label style={{ fontSize: "18px" }} > <FiberManualRecordIcon style={{ height: "20px", width: "20px" }} /> <strong>Servicios del local</strong> (agua,luz,etc) incluir la carga de KVAs actuales y tarifa. </Label>
                                    </Row>
                                    <Row
                                        style={{
                                            marginRight: "1px",
                                            marginLeft: "1px",
                                        }}
                                    >
                                        <Label style={{ fontSize: "18px" }} > <FiberManualRecordIcon style={{ height: "20px", width: "20px" }} /> <strong>Uso de suelo</strong>  (incluir boleta predial) </Label>
                                    </Row>
                                    <Row
                                        style={{
                                            marginRight: "1px",
                                            marginLeft: "1px",
                                        }}
                                    >
                                        <Label style={{ fontSize: "18px" }} > <FiberManualRecordIcon style={{ height: "20px", width: "20px" }} /> <strong>Condiciones</strong> de entrega del local </Label>
                                    </Row>
                                    <Row
                                        style={{
                                            marginRight: "1px",
                                            marginLeft: "1px",
                                        }}
                                    >
                                        <Label style={{ fontSize: "18px" }} > <FiberManualRecordIcon style={{ height: "20px", width: "20px" }} /> <strong>Propuesta </strong> de venta / arrendamiento </Label>
                                    </Row>
                                </Row>
                                <div style={{ height: "20px" }} ></div>
                                <Row
                                    style={{
                                        marginRight: "17%",
                                        marginLeft: "17%",
                                        borderWidth: "6px",
                                        borderStyle: "solid",
                                        borderColor: "#00587C",
                                        color: "#00577B",
                                        borderRadius: "10px",
                                        backgroundColor: colors.gpm.blanco,
                                        fontFamily: "'Roboto Slab', serif",
                                    }}

                                >
                                    <Row
                                        style={{
                                            marginRight: "1px",
                                            marginLeft: "1px",
                                        }}
                                    >
                                        <Label
                                            style={{
                                                fontSize: "24px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Comunícate con nosotros a traves de estos medios
                                        </Label>
                                    </Row>
                                    <Row
                                        style={{
                                            marginRight: "1px",
                                            marginLeft: "1px",
                                        }}
                                    >
                                        <Label
                                            style={{
                                                fontSize: "18px",
                                                textDecoration: "none",
                                                color: colors.gpm.azul,
                                            }}
                                        >
                                            <strong>Persona a contactar: </strong> Mauricio Meneses Nájera
                                        </Label>
                                    </Row>
                                    <Row
                                        style={{
                                            marginRight: "1px",
                                            marginLeft: "1px",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <a
                                            style={{
                                                fontSize: "18px",
                                                textDecoration: "none",
                                                color: colors.gpm.azul,
                                                cursor: "pointer",
                                            }}
                                            href="tel:+529612366389"
                                        >
                                            <strong>Numero de Teléfono: </strong> <label style={{ marginLeft: "10px", cursor: "pointer", }} >+52 961 236 6389</label>
                                        </a>
                                    </Row>
                                    <Row
                                        style={{
                                            marginRight: "1px",
                                            marginLeft: "1px",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <a
                                            style={{
                                                fontSize: "18px",
                                                textDecoration: "none",
                                                color: colors.gpm.azul,
                                                cursor: "pointer",
                                            }}
                                            href="mailto:mmeneses@corporativogpm.com"
                                        >
                                            <strong>Correo electrónico: </strong> <label style={{ marginLeft: "10px", cursor: "pointer", }} > mmeneses@corporativogpm.com </label>
                                        </a>
                                    </Row>
                                </Row>
                            </section >

                            <section
                                style={{
                                    backgroundColor: colors.gpm.blanco,
                                    width: "100%",
                                    height: "50vh",
                                }}
                            >

                            </section>
                        </>
                        :
                        <>
                            <section
                                style={{
                                    backgroundColor: "#1179AC",
                                    width: "100%",
                                    height: "100vh",
                                }}
                            >
                                <div style={{ height: "100px" }} ></div>
                                <Row
                                    style={{
                                        marginRight: "10px",
                                        marginLeft: "10px",
                                    }}
                                >
                                    <center>
                                        <Label
                                            style={{
                                                fontSize: "25px",
                                                // fontFamily: "'Roboto Slab', serif",
                                                fontFamily: "'Roboto Slab', serif",
                                                textAlign: "justify",
                                                color: colors.gpm.blanco
                                            }}
                                        >
                                            ¿Estás interesado en brindar un espacio en renta o venta para nosotros?
                                        </Label>

                                    </center>
                                </Row>
                                <Row
                                    style={{
                                        marginRight: "10px",
                                        marginLeft: "10px",
                                        marginTop: "5px"
                                    }}
                                >
                                    <center>
                                        <Label
                                            style={{
                                                fontSize: "25px",
                                                // fontFamily: "'Roboto Slab', serif",
                                                fontFamily: "'Roboto Slab', serif",
                                                textAlign: "justify",
                                                color: colors.gpm.blanco
                                            }}
                                        >
                                            Toma en cuenta estas características:
                                        </Label>

                                    </center>
                                </Row>
                                <Row
                                    style={{
                                        marginRight: "10px",
                                        marginLeft: "10px",
                                        marginTop: "20px"
                                    }}
                                >
                                    <div
                                        style={{
                                            height: "180px",
                                            width: "100%",
                                            backgroundColor: colors.gpm.blanco,
                                            borderWidth: "6px",
                                            borderStyle: "solid",
                                            borderColor: "#00587C",
                                            borderRadius: "10px",
                                        }}
                                    >
                                        <Row>
                                            <Col>
                                                <Label
                                                    style={{
                                                        fontSize: "60px",
                                                        fontWeight: "bold",
                                                        color: "#00577B",
                                                        marginTop: "-15px"
                                                    }}
                                                >
                                                    Ubicación:
                                                </Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col
                                                style={{
                                                    textAlign: "right",
                                                    lineHeight: 1,
                                                }}
                                            >
                                                <Label
                                                    style={{
                                                        fontSize: "25px",
                                                        color: "#00577B",
                                                    }}
                                                >
                                                    Avenidas principales y en esquina con alta concentración
                                                </Label>
                                            </Col>
                                        </Row>
                                    </div>
                                </Row>
                                <Row
                                    style={{
                                        marginRight: "10px",
                                        marginLeft: "10px",
                                        marginTop: "20px"
                                    }}
                                >
                                    <div
                                        style={{
                                            height: "110px",
                                            width: "100%",
                                            backgroundColor: "#1179AC",
                                            borderWidth: "6px",
                                            borderStyle: "solid",
                                            borderColor: "#00587C",
                                            borderRadius: "10px",
                                            textAlign: "right",
                                            lineHeight: 1,
                                        }}
                                    >
                                        <Label
                                            style={{
                                                color: "#6DC4E9",
                                                fontSize: "55px",
                                                fontFamily: "'Roboto Slab', serif",
                                                marginRight: "10px",
                                            }}
                                        >
                                            excelente
                                        </Label>
                                        <br />
                                        <Label
                                            style={{
                                                color: "#6DC4E9",
                                                fontSize: "35px",
                                                fontFamily: "'Roboto Slab', serif",
                                                marginTop: "-10px",
                                                marginRight: "10px",
                                            }}
                                        >
                                            visibilidad
                                        </Label>
                                    </div>
                                </Row>
                            </section>
                            <section
                                style={{
                                    backgroundColor: "#1179AC",
                                    width: "100%",
                                    height: "auto",
                                }}
                            >
                                <Row
                                    style={{
                                        marginRight: "10px",
                                        marginLeft:  "10px",
                                        borderWidth: "6px",
                                        borderStyle: "solid",
                                        borderColor: "#00587C",
                                        color: "#00577B",
                                        borderRadius: "10px",
                                        backgroundColor: colors.gpm.blanco,
                                        fontFamily: "'Roboto Slab', serif",
                                    }}

                                >
                                    <Row
                                        style={{
                                            marginRight: "1px",
                                            marginLeft: "1px",
                                        }}
                                    >
                                        <Label
                                            style={{
                                                fontSize: "24px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Envíanos un PDF con estos datos.
                                        </Label>
                                    </Row>
                                    <Row
                                        style={{
                                            marginRight: "1px",
                                            marginLeft: "1px",
                                        }}
                                    >
                                        <Label style={{ fontSize: "18px" }} > <FiberManualRecordIcon style={{ height: "20px", width: "20px" }} /> <strong>Ubicación</strong> del local/terreno en Google Maps. </Label>
                                    </Row>
                                    <Row
                                        style={{
                                            marginRight: "1px",
                                            marginLeft: "1px",
                                        }}
                                    >
                                        <Label style={{ fontSize: "18px" }} > <FiberManualRecordIcon style={{ height: "20px", width: "20px" }} /> <strong>Fotos</strong> interior/exterior. </Label>
                                    </Row>
                                    <Row
                                        style={{
                                            marginRight: "1px",
                                            marginLeft: "1px",
                                        }}
                                    >
                                        <Label style={{ fontSize: "18px" }} > <FiberManualRecordIcon style={{ height: "20px", width: "20px" }} /> <strong>Plano del local</strong>, si está en una plaza o centro comercial incluir planta completa con los arrendatarios actuales. </Label>
                                    </Row>
                                    <Row
                                        style={{
                                            marginRight: "1px",
                                            marginLeft: "1px",
                                        }}
                                    >
                                        <Label style={{ fontSize: "18px" }} > <FiberManualRecordIcon style={{ height: "20px", width: "20px" }} /> <strong>Núm. de cajones</strong> de estacionamiento exclusivos. </Label>
                                    </Row>
                                    <Row
                                        style={{
                                            marginRight: "1px",
                                            marginLeft: "1px",
                                        }}
                                    >
                                        <Label style={{ fontSize: "18px" }} > <FiberManualRecordIcon style={{ height: "20px", width: "20px" }} /> <strong>Servicios del local</strong> (agua,luz,etc) incluir la carga de KVAs actuales y tarifa. </Label>
                                    </Row>
                                    <Row
                                        style={{
                                            marginRight: "1px",
                                            marginLeft: "1px",
                                        }}
                                    >
                                        <Label style={{ fontSize: "18px" }} > <FiberManualRecordIcon style={{ height: "20px", width: "20px" }} /> <strong>Uso de suelo</strong>  (incluir boleta predial) </Label>
                                    </Row>
                                    <Row
                                        style={{
                                            marginRight: "1px",
                                            marginLeft: "1px",
                                        }}
                                    >
                                        <Label style={{ fontSize: "18px" }} > <FiberManualRecordIcon style={{ height: "20px", width: "20px" }} /> <strong>Condiciones</strong> de entrega del local </Label>
                                    </Row>
                                    <Row
                                        style={{
                                            marginRight: "1px",
                                            marginLeft: "1px",
                                        }}
                                    >
                                        <Label style={{ fontSize: "18px" }} > <FiberManualRecordIcon style={{ height: "20px", width: "20px" }} /> <strong>Propuesta </strong> de venta / arrendamiento </Label>
                                    </Row>
                                </Row>
                                <div style={{ height: "20px" }} ></div>
                                <Row
                                    style={{
                                        marginRight: "10px",
                                        marginLeft: "10px",
                                        borderWidth: "6px",
                                        borderStyle: "solid",
                                        borderColor: "#00587C",
                                        color: "#00577B",
                                        borderRadius: "10px",
                                        backgroundColor: colors.gpm.blanco,
                                        fontFamily: "'Roboto Slab', serif",
                                    }}

                                >
                                    <Row
                                        style={{
                                            marginRight: "1px",
                                            marginLeft: "1px",
                                        }}
                                    >
                                        <Label
                                            style={{
                                                fontSize: "24px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Comunícate con nosotros a traves de estos medios
                                        </Label>
                                    </Row>
                                    <Row
                                        style={{
                                            marginRight: "1px",
                                            marginLeft: "1px",
                                        }}
                                    >
                                        <Label
                                            style={{
                                                fontSize: "18px",
                                                textDecoration: "none",
                                                color: colors.gpm.azul,
                                            }}
                                        >
                                            <strong>Persona a contactar: </strong> Mauricio Meneses Nájera
                                        </Label>
                                    </Row>
                                    <Row
                                        style={{
                                            marginRight: "1px",
                                            marginLeft: "1px",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <a
                                            style={{
                                                fontSize: "18px",
                                                textDecoration: "none",
                                                color: colors.gpm.azul,
                                                cursor: "pointer",
                                            }}
                                            href="tel:+529612366389"
                                        >
                                            <strong>Numero de Teléfono: </strong> <label style={{ marginLeft: "10px", cursor: "pointer", }} >+52 961 236 6389</label>
                                        </a>
                                    </Row>
                                    <Row
                                        style={{
                                            marginRight: "1px",
                                            marginLeft: "1px",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <a
                                            style={{
                                                fontSize: "18px",
                                                textDecoration: "none",
                                                color: colors.gpm.azul,
                                                cursor: "pointer",
                                            }}
                                            href="mailto:mmeneses@corporativogpm.com"
                                        >
                                            <strong>Correo electrónico: </strong> <label style={{ marginLeft: "10px", cursor: "pointer", }} > mmeneses@corporativogpm.com </label>
                                        </a>
                                    </Row>
                                </Row>
                                <Block height={"50px"} />
                            </section>
                        </>
                }


            </>
        );
    }

}

export default BienesRaices; 