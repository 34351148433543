import { Component } from "react";
import { Col, Row } from "reactstrap";
import logoArbys from "../assets/image/logo/arbys/arbysLogo.png";
import logoDominos from "../assets/image/logo/dominos/Recurso_1.png";
import logoSushiitto from "../assets/image/logo/sushiItto/sushiittoLogo.png";
import colors from "../config/colors.config";
import SNavBarComputer from "./NavBar/GPM/SNavBarComputer";
import SNavBarMovil from "./NavBar/GPM/SNavBarMovil";
import Block from "../tools/Block";


class NuestrasMarcas extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isHoverLogoArbys: false,
            isHoverLogoDominos: false,
            isHoverLogoSushiitto: false,
            device: {
                isDesktopDevice: null,
                isMobileDevice: null,
                screenHeight: null,
                screenWidth: null,
            },
        }
    }

    componentDidMount() {
        this.cargarDatosPantalla();

    }

    cargarDatosPantalla = () => {
        let details = navigator.userAgent;
        let regexp = /android|iphone|kindle|ipad/i;
        let isDesktopDevice = !regexp.test(details);
        let isMobileDevice = !isDesktopDevice;
        let screenHeight = window.innerHeight;
        let screenWidth = window.innerWidth;

        let device = {
            isDesktopDevice,
            isMobileDevice,
            screenHeight,
            screenWidth
        }
                
        this.setState({
            device
        });
    }

    render() {
        return (
            <>
                {this.state.device.isDesktopDevice ? <SNavBarComputer clickOnHome={null} isMain={false} /> : <SNavBarMovil/>}
                <Block height={this.state.device.isDesktopDevice ? "110px" : "80px"}></Block>
                <Row
                    style={{                        
                        marginRight: this.state.device.isDesktopDevice ? "15%" : "10px",
                        marginLeft: this.state.device.isDesktopDevice ? "15%" : "10px",
                    }}
                    xs={this.state.device.isDesktopDevice ? "3" : ""}
                >
                    <Col
                    
                        style={{
                            maxWidth: this.state.device.isDesktopDevice ? "30%" : "",
                            marginBottom: "50px",                            
                            cursor: "pointer",
                        }}
                        md={this.state.device.isDesktopDevice ? "" : "6"}
                        onClick={() => {
                            window.location.href = "/gpm/dominos"
                        }}
                    >
                        <center>
                            <img
                                src={logoDominos}
                                alt="No se pudo cargar el logo de dominos"
                                height="175px"
                                width="200px"
                                title="Conocer Dominos GPM"
                                style={{
                                    cursor: "pointer",
                                    marginTop: "10px",
                                    transform: this.state.isHoverLogoDominos ? 'scale(1.2)' : 'scale(1)',
                                    transition: 'transform 0.3s ease',
                                }}
                                onClick={() => {
                                    window.location.href = "/gpm/arbys";
                                }}
                                onMouseEnter={() => {
                                    this.setState({ isHoverLogoDominos: true });
                                }}
                                onMouseLeave={() => {
                                    this.setState({ isHoverLogoDominos: false });
                                }}
                            />

                        </center>
                        <div style={{ height: "10px" }} ></div>
                    </Col>
                    <Col
                        style={{
                            maxWidth: this.state.device.isDesktopDevice ? "30%" : "",
                            marginBottom: "50px",                            
                            cursor: "pointer",
                        }}
                        md={this.state.device.isDesktopDevice ? "" : "6"}
                        onClick={() => {
                            window.location.href = "/gpm/arbys";
                        }}
                    >
                        <center>
                            <img
                                src={logoArbys}
                                alt="No se pudo cargar el logo de arbys"
                                height={this.state.device.isDesktopDevice ? "175px" : "150px"}
                                width={this.state.device.isDesktopDevice ? "300px" : "250px"}
                                title="Conocer Arbys"
                                style={{
                                    cursor: "pointer",
                                    marginTop: "10px",
                                    transform: this.state.isHoverLogoArbys ? 'scale(1.2)' : 'scale(1)',
                                    transition: 'transform 0.3s ease',
                                }}
                                onClick={() => {
                                    window.location.href = "/gpm/arbys";
                                }}
                                onMouseEnter={() => {
                                    this.setState({ isHoverLogoArbys: true });
                                }}
                                onMouseLeave={() => {
                                    this.setState({ isHoverLogoArbys: false });
                                }}
                            />
                        </center>
                        <div style={{ height: "10px" }} ></div>
                    </Col>                                    
                    <Col
                        style={{
                            maxWidth: this.state.device.isDesktopDevice ? "3%" : "",
                        }}
                    >
                    </Col>
                    <Col
                        style={{
                            maxWidth: this.state.device.isDesktopDevice ? "30%" : "",
                            marginBottom: "50px",                            
                            cursor: "pointer",
                        }}
                        md={this.state.device.isDesktopDevice ? "" : "6"}
                        onClick={() => {
                            window.location.href = "/gpm/sushiitto"
                        }}
                    >
                        <center>
                            <img
                                src={logoSushiitto}
                                alt="No se pudo cargar el logo de sushiitto"
                                height={this.state.device.isDesktopDevice ? "175px" : "150px"}
                                width={this.state.device.isDesktopDevice ? "350px" : "300px"}
                                title="Conocer SushiItto"
                                style={{
                                    cursor: "pointer",
                                    marginTop: "10px",
                                    transform: this.state.isHoverLogoSushiitto ? 'scale(1.2)' : 'scale(1)',
                                    transition: 'transform 0.3s ease',
                                }}
                                onClick={() => {
                                    window.location.href = "/gpm/sushiitto";
                                }}
                                onMouseEnter={() => {
                                    this.setState({ isHoverLogoSushiitto: true });
                                }}
                                onMouseLeave={() => {
                                    this.setState({ isHoverLogoSushiitto: false });
                                }}
                            />
                        </center>
                        <div style={{ height: "10px" }} ></div>
                    </Col>
                </Row>
                <section
                    style={{
                        width:"100%",
                        height:"100vh",
                        backgroundColor:colors.gpm.azul
                    }}
                >

                </section>

            </>
        );
    }

}

export default NuestrasMarcas;