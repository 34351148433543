import { Component } from "react";
import NavBar from "../NavBar/arbys/NavBar";
import { Button, Card, CardBody, CardSubtitle, CardText, CardTitle, Col, DropdownItem, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import arbysLogo from "../../assets/image/logo/arbys/arbysLogo.png";

/** Importación de imágenes */
import imgArbys_1 from "../../assets/image/variedad/arbys/imgArbys_1.jpg";
import arbys from "../../assets/image/variedad/arbys/arbys.jpg";
import colors from "../../config/colors.config";
import { IconButton } from "@material-ui/core";
import { Close, Map, QueryBuilder, Room, Streetview } from "@material-ui/icons";

class ArbysUnidades extends Component {

    constructor(props) {
        super(props);
        this.state = {
            orientation: 0,
            device: {
                isDesktopDevice: false,
                isMobileDevice: true,
                screenHeight: 844,
                screenWidth: 390,
            },
            estaAbiertoModalUnidadArbys: false,
            unidadArbys: {
                nombreUnidad: "",
                direccion: "",
                img: "",
                IdStatus: 0,
                status: "",
                estado: "",
                urlGoogleStreetView: "",
                urlGoogleMaps: "---",
            },
            listaUnidadesArbys: [
                {
                    nombreUnidad: "Arby's GPM Puebla",
                    direccion: "Boulevard Interamericano 125-2, Lomas de Angelópolis, 72830 Tlaxcalancingo, Puebla.",
                    img: imgArbys_1,
                    IdStatus: 2,
                    status: "Abierto al publico",
                    estado: "Puebla",
                    urlGoogleStreetView: "https://www.google.com/local/place/fid/0x85cfb9fcc96047fd:0x718c5bc4c4650901/photosphere?iu=https://lh5.googleusercontent.com/p/AF1QipOcy-vOFWTNjqtBlz2TP5wEs1Yj6OOGClZ27IzG%3Dw160-h106-k-no-pi-0-ya8.248214-ro-0-fo100&ik=CAoSLEFGMVFpcE9jeS12T0ZXVE5qcXRCbHoyVFA1d0VzMVlqNk9PR0NsWjI3SXpH",
                    urlGoogleMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887927.7679905943!2d-97.94746923225267!3d17.894594584152046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cfb9fcc96047fd%3A0x718c5bc4c4650901!2sArby's!5e0!3m2!1ses!2smx!4v1687458711514!5m2!1ses!2smx",
                },
                {
                    nombreUnidad: "Arby's GPM Veracruz",
                    direccion: "Boulevard Adolfo Ruiz Cortinez, Las Americas, 94298 Boca del Rio, Veracruz.",
                    img: arbys,
                    IdStatus: 2,
                    status: "Abierto al publico",
                    estado: "Veracruz",
                    urlGoogleStreetView: "https://www.google.com/local/place/fid/0x85c3411ef86e294b:0x75a5256bf7a71c1a/photosphere?iu=https://lh5.googleusercontent.com/p/AF1QipN3MYILZc_mplslTdBB3OXoUt_l4yUUZQ2HzawC%3Dw160-h106-k-no-pi0-ya244.35419-ro-0-fo100&ik=CAoSLEFGMVFpcE4zTVlJTFpjX21wbHNsVGRCQjNPWG9VdF9sNHlVVVpRMkh6YXdD",
                    urlGoogleMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887036.640215915!2d-97.03912169668803!3d17.935221819518045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85c3411ef86e294b%3A0x75a5256bf7a71c1a!2sArby's%20Adolfo%20Ruiz%20Cortines!5e0!3m2!1ses!2smx!4v1687456332645!5m2!1ses!2smx",

                },
            ]
        }
    }

    componentDidMount() {
        this.cargarDatosPantalla();
    }

    cargarDatosPantalla = () => {
        let { device } = this.props;
        this.setState({
            device
        });
        window.addEventListener("orientationchange", async () => {
            console.log(window.screen.orientation);
            await this.setState({
                orientation: window.screen.orientation.angle
            });
        });
    }

    abrirCerrarModalArbysUnidad = () => {
        this.setState({
            estaAbiertoModalUnidadArbys: !this.state.estaAbiertoModalUnidadArbys
        });
    }

    render() {
        return (
            <>
                <NavBar device={this.props.device} />
                <div>
                    <Row
                        style={{
                            marginTop: "100px",
                            marginRight: this.state.device.isDesktopDevice ? "50px" : "10px",
                            marginLeft: this.state.device.isDesktopDevice ? "50px" : "10px",
                        }}
                    >
                        <Col>
                            <Label
                                style={{
                                    color: colors.arbys.rojo,
                                    fontWeight: "bold",
                                    fontSize: "24px"
                                }}
                            >
                                Lista de Unidades de Arby's de
                                <Label
                                    style={{
                                        color: colors.arbys.negro,
                                        marginLeft: "7px"
                                    }}
                                >
                                    Grupo Penagos Meneses (GPM)
                                </Label>
                            </Label>
                        </Col>
                        <DropdownItem divider />
                    </Row>

                    <Row
                        style={{
                            marginTop: "30px",
                            marginRight: this.state.device.isDesktopDevice ? "50px" : "10px",
                            marginLeft: this.state.device.isDesktopDevice ? "50px" : "10px",
                            backgroundColor: "#E9E9E9",
                            borderRadius: "8px"
                        }}
                        xs={this.state.device.isDesktopDevice ? 4 : 1}
                    >
                        {
                            this.state.listaUnidadesArbys.map((unidad) => {
                                return (
                                    <Col>
                                        <Card
                                            style={{
                                                width: '18rem',
                                                height: "400px",
                                                cursor: "pointer",
                                                marginTop: "20px",
                                                marginBottom: "20px"
                                            }}
                                            onClick={async () => {

                                                await this.setState({
                                                    unidadArbys: unidad
                                                });
                                                await this.abrirCerrarModalArbysUnidad();
                                            }}
                                        >
                                            <img
                                                alt="Sample"
                                                src={unidad.img.length === 0 ? arbysLogo : unidad.img}
                                                height="200px"
                                            />
                                            <CardBody>
                                                <CardTitle tag="h5">
                                                    {unidad.nombreUnidad}
                                                </CardTitle>
                                                <CardSubtitle
                                                    className="mb-2"
                                                    tag="h6"
                                                    style={{
                                                        color: unidad.IdStatus == 1 ? "grey" : unidad.IdStatus == 2 ? "green" : "#000000"
                                                    }}
                                                >
                                                    {unidad.status}
                                                </CardSubtitle>
                                                <CardSubtitle
                                                    className="mb-2"
                                                    tag="h6"
                                                    style={{
                                                    }}
                                                >
                                                    {unidad.estado}
                                                </CardSubtitle>
                                                <CardText>
                                                    {unidad.direccion}
                                                </CardText>
                                            </CardBody>
                                        </Card>


                                    </Col>
                                );
                            })
                        }

                    </Row>
                </div>
                <Modal
                    isOpen={this.state.estaAbiertoModalUnidadArbys}
                    size="lg"
                    style={{
                        maxWidth: "80%"
                    }}
                >
                    <ModalHeader
                        close={
                            <IconButton
                                style={{
                                    color: colors.arbys.rojo
                                }}
                                onClick={() => {
                                    this.abrirCerrarModalArbysUnidad();
                                }}
                            >
                                <Close />
                            </IconButton>
                        }
                        style={{
                            backgroundColor: colors.arbys.blanco,
                            color: colors.arbys.rojo
                        }}
                    >
                        <Label
                            style={{
                                fontWeight: "bold"
                            }}
                        >
                            {this.state.unidadArbys.nombreUnidad}
                        </Label>
                    </ModalHeader>
                    <ModalBody>
                        {
                            this.state.device.isMobileDevice && (this.state.orientation == 0) ?
                                <center>
                                    <Label>
                                        Pon tu teléfono horizontal para poder ver bien el contenido.
                                    </Label>
                                </center>

                                :
                                <Row
                                    style={{
                                        marginRight: this.state.device.isDesktopDevice ? "50px" : "10px",
                                        marginLeft: "50px"
                                    }}
                                >
                                    <Col
                                        style={{
                                            maxWidth: "50%"
                                        }}
                                    >
                                        <Row>
                                            <Col
                                                style={{
                                                    maxWidth: "10%",
                                                    color: colors.arbys.rojo
                                                }}
                                            >
                                                <Room />
                                            </Col>
                                            <Col
                                                style={{
                                                    maxWidth: "90%",
                                                    color: colors.arbys.negro
                                                }}
                                            >
                                                <Row>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold"
                                                        }}
                                                    >
                                                        Dirección
                                                    </Label>
                                                </Row>
                                                <Row>
                                                    <Label>
                                                        {this.state.unidadArbys.direccion}
                                                    </Label>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col
                                                style={{
                                                    maxWidth: "10%",
                                                    color: colors.arbys.rojo
                                                }}
                                            >
                                                <QueryBuilder />
                                            </Col>
                                            <Col
                                                style={{
                                                    maxWidth: "90%",
                                                    color: colors.arbys.negro
                                                }}
                                            >
                                                <Row>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold"
                                                        }}
                                                    >
                                                        Horario de Servicio
                                                    </Label>
                                                </Row>
                                                <Row>
                                                    <Label>
                                                        Lunes
                                                        <label
                                                            style={{
                                                                marginLeft: "35px"
                                                            }}
                                                        >
                                                            11:00 AM - 10:00 PM
                                                        </label>
                                                    </Label>
                                                    <br />
                                                    <Label>
                                                        Martes
                                                        <label
                                                            style={{
                                                                marginLeft: "28px"
                                                            }}
                                                        >
                                                            11:00 AM - 10:00 PM
                                                        </label>
                                                    </Label>
                                                    <br />
                                                    <Label>
                                                        Miércoles
                                                        <label
                                                            style={{
                                                                marginLeft: "8px"
                                                            }}
                                                        >
                                                            11:00 AM - 10:00 PM
                                                        </label>
                                                    </Label>
                                                    <br />
                                                    <Label>
                                                        Jueves
                                                        <label
                                                            style={{
                                                                marginLeft: "30px"
                                                            }}
                                                        >
                                                            11:00 AM - 10:00 PM
                                                        </label>
                                                    </Label>
                                                    <br />
                                                    <Label>
                                                        Viernes
                                                        <label
                                                            style={{
                                                                marginLeft: "24px"
                                                            }}
                                                        >
                                                            11:00 AM - 10:00 PM
                                                        </label>
                                                    </Label>
                                                    <br />
                                                    <Label>
                                                        Sábado
                                                        <label
                                                            style={{
                                                                marginLeft: "23px"
                                                            }}
                                                        >
                                                            11:00 AM - 11:00 PM
                                                        </label>
                                                    </Label>
                                                    <br />
                                                    <Label>
                                                        Domingo
                                                        <label
                                                            style={{
                                                                marginLeft: "10px"
                                                            }}
                                                        >
                                                            11:00 AM - 11:00 PM
                                                        </label>
                                                    </Label>
                                                    <br />
                                                </Row>
                                            </Col>
                                        </Row>
                                        {
                                            this.state.unidadArbys.urlGoogleStreetView.length === 0 ?
                                                null
                                                :
                                                <Row
                                                    style={{
                                                        marginTop: "10px"
                                                    }}
                                                >
                                                    <Col
                                                        style={{
                                                            maxWidth: "10%",
                                                            color: colors.arbys.rojo
                                                        }}
                                                    >
                                                        <Streetview />
                                                    </Col>
                                                    <Col
                                                        style={{
                                                            maxWidth: "90%",
                                                            color: colors.arbys.negro
                                                        }}
                                                    >
                                                        <Row>
                                                            <Label
                                                                style={{
                                                                    fontWeight: "bold",
                                                                    cursor: "pointer"
                                                                }}
                                                                onClick={() => {
                                                                    window.open(this.state.unidadArbys.urlGoogleStreetView);
                                                                }}
                                                            >
                                                                Has click aquí para hacer recorrido con Google Street View
                                                            </Label>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                        }

                                    </Col>
                                    <Col
                                        style={{
                                            maxWidth: "50%"
                                        }}
                                    >
                                        <center>
                                            {
                                                this.state.unidadArbys.urlGoogleMaps.length === 0 ?
                                                    <>
                                                        <lottie-player
                                                            autoplay
                                                            // loop
                                                            mode="normal"
                                                            src="https://assets1.lottiefiles.com/private_files/lf30_D4yZiV.json"
                                                            speed={1}
                                                            style={{
                                                                width: "200px",
                                                                height: "200px",
                                                                // marginTop: "40px"
                                                            }}
                                                        />
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                color: colors.negro,
                                                                fontSize: "20px"
                                                            }}
                                                        >
                                                            No se encontro un mapa de esta unidad
                                                        </Label>
                                                    </>
                                                    :
                                                    <iframe
                                                        src={this.state.unidadArbys.urlGoogleMaps}
                                                        width={this.state.device.isDesktopDevice ? 500 : 250}
                                                        height={this.state.device.isDesktopDevice ? 350 : 175}
                                                        style={{ border: 0 }}
                                                        allowFullScreen
                                                        loading="lazy"
                                                        referrerPolicy="no-referrer-when-downgrade"
                                                    />
                                            }

                                        </center>
                                    </Col>
                                </Row>
                        }

                    </ModalBody>
                    <ModalFooter>
                        {
                            this.state.unidadArbys.urlGoogleStreetView.length === 0 ?
                                null
                                :
                                <Button
                                    onClick={() => {
                                        window.open(this.state.unidadArbys.urlGoogleStreetView);
                                    }}
                                    style={{
                                        backgroundColor: colors.arbys.rojo,
                                        color: colors.arbys.blanco,
                                        fontWeight: "bold",
                                        boxShadow: "none",
                                        borderColor: colors.arbys.rojo,
                                        width: "170px"
                                    }}
                                    title="Has click aqui para ver la unidad con Google Street View"
                                >
                                    Conocer Uniadad
                                </Button>
                        }

                        <Button
                            onClick={() => {
                                this.abrirCerrarModalArbysUnidad();
                            }}
                            style={{
                                backgroundColor: colors.arbys.rojo,
                                color: colors.arbys.blanco,
                                fontWeight: "bold",
                                boxShadow: "none",
                                borderColor: colors.arbys.rojo,
                                width: "170px"
                            }}
                        >
                            Cerrar
                        </Button>
                    </ModalFooter>
                </Modal>


            </>
        );
    }

}

export default ArbysUnidades;