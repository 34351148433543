


const fucusElement = (Id) => {
    document.getElementById(Id).focus();
}

const sleep = (seconds) => new Promise(
    resolve => setTimeout(resolve, (seconds * 1000))
);

const components = {
    fucusElement,
    sleep,    
}

export default components;